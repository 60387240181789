/* eslint-disable */

/* --------------- importing Images here --------------- */
import expert1 from "../assets/images/expert1.png";
import expert2 from "../assets/images/expert2.png";
import expert3 from "../assets/images/expert3.png";
import expert4 from "../assets/images/expert4.png";
import expert5 from "../assets/images/expert5.png";
import expert6 from "../assets/images/expert6.png";
import expert7 from "../assets/images/expert7.png";
import user from "../assets/images/user.png";
import events2 from "../assets/images/events2.jpg";
import events8 from "../assets/images/events8.jpg";
import eventcover2 from "../assets/images/eventcover2.jpg";
import eventCalendar from "../assets/images/evnt-calender.png";
import eventCongrats from "../assets/images/event-congrats.png";
import sponsor1 from "../assets/images/sponsor1.png";
import sponsor2 from "../assets/images/sponsor2.png";
import sponsor3 from "../assets/images/sponsor3.png";
import gallery1 from "../assets/images/gallery1.jpg";
import gallery2 from "../assets/images/gallery2.jpg";
import gallery3 from "../assets/images/gallery3.jpg";
import gallery4 from "../assets/images/gallery4.jpg";
import gallery5 from "../assets/images/gallery5.jpg";
import gallery6 from "../assets/images/gallery6.jpg";
import gallery7 from "../assets/images/gallery7.jpg";
import gallery8 from "../assets/images/gallery8.jpg";
import gallery9 from "../assets/images/gallery9.jpg";
import gallery10 from "../assets/images/gallery10.jpg";
import forumPic1 from "../assets/images/forum-pic1.png";
import forumPic2 from "../assets/images/forum-pic2.png";
import expertLogo from "../assets/images/exprt-logo1.png";
import expertLogo2 from "../assets/images/exprt-logo2.png";
import expertLogo3 from "../assets/images/exprt-logo3.png";
import portfolio1 from "../assets/images/portfolio1.jpg";
import portfolio2 from "../assets/images/portfolio2.jpg";
import portfolio3 from "../assets/images/portfolio3.jpg";
import portfolio4 from "../assets/images/portfolio4.jpg";
import portfolio5 from "../assets/images/portfolio5.jpg";
import profile from "../assets/images/profile.jpg";
import profile2 from "../assets/images/profile.png";
import editor from "../assets/images/editor.png";
import defaultUser from "../assets/images/default-user.png";
import blankChampion from "../assets/images/blank-champion.jpg";
import homeBannerPic from "../assets/images/home-bnr-pic.png";
import systemLogo1 from "../assets/images/system-logo1.png";
import systemLogo2 from "../assets/images/system-logo2.png";
import systemLogo3 from "../assets/images/system-logo3.png";
import systemLogo4 from "../assets/images/system-logo4.png";
import optionPic1 from "../assets/images/option-pic1.png";
import optionPic2 from "../assets/images/option-pic2.png";
import optionPic3 from "../assets/images/option-pic3.png";
import optionPic4 from "../assets/images/option-pic4.png";
import supportPic from "../assets/images/support-pic.png";
import recruitPic from "../assets/images/recruit-pic.png";
import blogPic1 from "../assets/images/blog-pic1.jpg";
import blogPic2 from "../assets/images/blog-pic2.jpg";
import blogPic3 from "../assets/images/blog-pic3.jpg";
import blankBlog from "../assets/images/blank_blog.jpg";
import blogDetails from "../assets/images/blog-details.jpg";
import profileGraphics from "../assets/images/profile-graphics.png";
import welcomeGraphics from "../assets/images/welcome_graphics.png";
import EmptyPic from "../assets/images/empty-pic.png";
import arrowImg from "../assets/images/Arrow.png";
import addFriend from "../assets/images/add-friend-graphics.png";
import blankInvite from "../assets/images/blank_invite.png";

/* --------------- importing SVGs here --------------- */
import chatLogo from "../assets/svgs/cht-logo.svg";
import homeLogo from "../assets/svgs/home-logo.svg";
import logo from "../assets/svgs/logo.svg";
import facebook from "../assets/svgs/facebook.svg";
import fb from "../assets/svgs/fb.svg";
import instagram from "../assets/svgs/instagram.svg";
import twitter from "../assets/svgs/twitter.svg";
import googleLogo from "../assets/svgs/google.svg";
import pdf from "../assets/svgs/pdf.svg";
import gmail from "../assets/svgs/gmail.svg";
import linkedin from "../assets/svgs/linkedin.svg";
import pinterest from "../assets/svgs/pinterest.svg";
import docs from "../assets/svgs/docs.svg";
import xls from "../assets/svgs/xls.svg";
import findIcon1 from "../assets/svgs/find-icon1.svg";
import findIcon2 from "../assets/svgs/find-icon2.svg";
import findIcon3 from "../assets/svgs/find-icon3.svg";
import findIcon4 from "../assets/svgs/find-icon4.svg";
import recruitIcon1 from "../assets/svgs/recruit-icon1.svg";
import recruitIcon2 from "../assets/svgs/recruit-icon2.svg";
import recruitIcon3 from "../assets/svgs/recruit-icon3.svg";
import recruitIcon4 from "../assets/svgs/recruit-icon4.svg";
import loginEmoji1 from "../assets/svgs/login-emoji1.svg";
import loginEmoji2 from "../assets/svgs/login_emoji2.svg";
import loginEmoji3 from "../assets/svgs/login_emoji3.svg";
import rotate1 from "../assets/svgs/roatate-1.svg";
import rotate2 from "../assets/svgs/roatate-2.svg";
import emptyLesson from "../assets/svgs/lesson-empty.svg";
import VIDEO from "../assets/svgs/video.svg";
import ZIP from "../assets/svgs/zip.svg";

export default {
  // ------------------ Exporting Images here ------------------
  blankInvite,
  arrowImg,
  addFriend,
  EmptyPic,
  expert1,
  expert2,
  expert3,
  expert4,
  expert5,
  expert6,
  expert7,
  user,
  events2,
  events8,
  eventcover2,
  eventCalendar,
  eventCongrats,
  sponsor1,
  sponsor2,
  sponsor3,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  forumPic1,
  forumPic2,
  expertLogo,
  expertLogo2,
  expertLogo3,
  portfolio1,
  portfolio2,
  portfolio3,
  portfolio4,
  portfolio5,
  profile,
  profile2,
  editor,
  defaultUser,
  blankChampion,
  homeBannerPic,
  systemLogo1,
  systemLogo2,
  systemLogo3,
  systemLogo4,
  optionPic1,
  optionPic2,
  optionPic3,
  optionPic4,
  supportPic,
  recruitPic,
  blogPic1,
  blogPic2,
  blogPic3,
  blankBlog,
  blogDetails,
  profileGraphics,
  welcomeGraphics,

  // ------------------ Exporting SVGs here ------------------
  chatLogo,
  homeLogo,
  logo,
  facebook,
  fb,
  instagram,
  twitter,
  googleLogo,
  pdf,
  gmail,
  linkedin,
  pinterest,
  docs,
  xls,
  findIcon1,
  findIcon2,
  findIcon3,
  findIcon4,
  recruitIcon1,
  recruitIcon2,
  recruitIcon3,
  recruitIcon4,
  loginEmoji1,
  loginEmoji2,
  loginEmoji3,
  rotate1,
  rotate2,
  emptyLesson,
  VIDEO,
  ZIP,
};
