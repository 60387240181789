import React from "react";

//importing images here
import { assetImages } from "../../../constants";

const EmptyMessage = (props) => {
  return (
    <>
      <div className="chating_mdlPnl">
        <div className="chatting_innr chating_start">
          <div className="nothing_pic">
            <figure>
              <img src={assetImages.EmptyPic} alt="nothing" />
            </figure>
            <div className="nothing_caption">
              <p>Nothing found ..... Refresh or Send your first message</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyMessage;
