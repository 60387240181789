/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalFindelOiProvider } from "../../../context/FindeloiContext";

// -------------------------- required imports for API Call --------------------------
// import Gateway for API fetch call
import { getData, postData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";

// -----------------------------------------------------------------------------------

// importing custom components here
import UploadPopupModal from "../Modals/PopupModals/UploadPopupModal";
import EmailPopupModal from "../Modals/PopupModals/EmailPopupModal";

const ChatBox = (props) => {
  const { setRealoadChatPanel } = useContext(GlobalFindelOiProvider);

  // getting token here
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [pid, setPid] = useState(null);
  const [sender, setSender] = useState("");
  const [parentMessage, setParentMessage] = useState("");

  //warning message
  const [showWarning, setShowWarning] = useState(false);

  let cid = props.contactId;
  let gid = props.groupId;
  let groupMembers = props.groupMembers;

  // initial state for chat message
  const [chatMessage, setChatMessage] = useState("");

  /*------ temprary function for get media chat details from popup modal ------*/

  const mediaMessageDetails = (data) => {
    props.messageDetails(data.message, null, null, data.media);
  };
  /*------ end of temprary function for get chat details from popup modal ------*/

  // function to handle the sending of the chat messages
  const chatMessageHandlerByButton = async (e) => {
    e.preventDefault();

    if (chatMessage == "") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      if (pid == null || pid == undefined) {
        // condition based on group id available or not
        if (gid > 0) {
          props.messageDetails(chatMessage);
          let messageData = {
            gid,
            message: chatMessage,
          };
          let requestURL =
            url.API_BaseUrl +
            url.API_GROUP_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          try {
            const response = await postData(requestURL, messageData);

            if (response.status) {
              // some exuctution needed after customer confirmation
            }
          } catch (error) {}
        } else {
          props.messageDetails(chatMessage);
          props.showChatMessageTab();
          let messageData = {
            cid,
            message: chatMessage,
          };
          let requestURL =
            url.API_BaseUrl +
            url.API_CONTACT_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          try {
            const response = await postData(requestURL, messageData);

            if (response.status) {
              //some action
            }
          } catch (error) {}
        }
      } else {
        //document.getElementById("replypopup").classList.toggle("active");
        props.onConfirmReply();
        if (gid > 0) {
          props.messageDetails(chatMessage, sender, parentMessage);
          let messageData = {
            gid,
            pid,
            message: chatMessage,
          };

          let requestURL =
            url.API_BaseUrl +
            url.API_GROUP_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          setPid(null);

          try {
            const response = await postData(requestURL, messageData); // if neccessary then it will change to getdata

            if (response.status) {
              //some acction
            }
          } catch (error) {}
        } else {
          props.messageDetails(chatMessage, sender, parentMessage);
          props.showChatMessageTab();
          let requestURL =
            url.API_BaseUrl +
            url.API_CONTACT_CONVERSATION_SAVE +
            `?token=${token}`;

          let messageData = {
            cid,
            pid,
            message: chatMessage,
          };

          // reset state after submit message
          setChatMessage("");
          setPid(null);

          try {
            const response = await postData(requestURL, messageData); // if neccessary then it will change to getdata

            if (response.status) {
            }
          } catch (error) {}
        }
      }
      setRealoadChatPanel(true);
    }
  };

  // function for submit message by enter key
  const chatMessageHandlerByEnter = async () => {
    if (chatMessage == "") {
      setShowWarning(true);
    } else {
      setShowWarning(false);
      if (pid == null || pid == undefined) {
        // condition based on group id available or not
        if (gid > 0) {
          props.messageDetails(chatMessage);
          let messageData = {
            gid,
            message: chatMessage,
          };
          let requestURL =
            url.API_BaseUrl +
            url.API_GROUP_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          try {
            const response = await postData(requestURL, messageData);

            if (response.status) {
            }
          } catch (error) {}
        } else {
          props.messageDetails(chatMessage);
          props.showChatMessageTab();
          let messageData = {
            cid,
            message: chatMessage,
          };
          let requestURL =
            url.API_BaseUrl +
            url.API_CONTACT_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          try {
            const response = await postData(requestURL, messageData);

            if (response.status) {
            }
          } catch (error) {}
        }
      } else {
        //document.getElementById("replypopup").classList.toggle("active");
        props.onConfirmReply();
        if (gid > 0) {
          props.messageDetails(chatMessage, sender, parentMessage);
          let messageData = {
            gid,
            pid,
            message: chatMessage,
          };

          let requestURL =
            url.API_BaseUrl +
            url.API_GROUP_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          setPid(null);

          try {
            const response = await postData(requestURL, messageData); // if neccessary then it will change to getdata

            if (response.status) {
            }
          } catch (error) {}
        } else {
          props.messageDetails(chatMessage, sender, parentMessage);
          props.showChatMessageTab();
          let messageData = {
            cid,
            pid,
            message: chatMessage,
          };

          let requestURL =
            url.API_BaseUrl +
            url.API_CONTACT_CONVERSATION_SAVE +
            `?token=${token}`;

          // reset state after submit message
          setChatMessage("");
          setPid(null);

          try {
            const response = await postData(requestURL, messageData); // if neccessary then it will change to getdata

            if (response.status) {
            }
          } catch (error) {}
        }
      }
      setRealoadChatPanel(true);
    }
  };

  // useEffect for Send message using enter key
  useEffect(() => {
    if (chatMessage != "") {
      const listener = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          event.preventDefault();
          // calling the handler function here to send the newly entered tag data to API
          chatMessageHandlerByEnter();
        }
      };

      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [chatMessage]);

  // useeffect for set state in group reply chat
  useEffect(() => {
    if (props.chatParentMessageInfo != undefined) {
      setPid(props.chatParentMessageInfo.id);
      setSender(props.chatParentMessageInfo.sender);
      setParentMessage(props.chatParentMessageInfo.message);
    }
  }, [props.chatParentMessageInfo]);

  // useEffect for set state in contact reply chat
  useEffect(() => {
    if (props.chatParent != undefined) {
      setPid(props.chatParent.id);
      setSender(props.chatParent.sender);
      setParentMessage(props.chatParent.message);
    }
  }, [props.chatParent]);

  return (
    <>
      <div className="chat_sendPnl">
        <div className="emoji_iconArea">
          <ul>
            {/* ---------- Link to activate the file uploader ----------- */}
            <li>
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#upload_modal"
              >
                <span className="material-icons-round">upload_file</span>
              </Link>
            </li>

            {/* ---------- Link to activate the Emoji ----------- */}
            {/* <li>
              <Link to="#">
                <span className="material-icons-round">emoji_emotions</span>
              </Link>
            </li> */}

            {/* ---------- Link to activate the Email popup ----------- */}
            <li>
              <Link
                to="#"
                className="emailmdl_btn"
                onClick={() => {
                  // function for trigger email popup
                  document.body.classList.toggle("emailpopup_open");
                }}
              >
                <span className="material-icons-round">email</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="send_textbx">
          <form
            onSubmit={(e) => {
              chatMessageHandlerByButton(e);
            }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Enter your message"
              value={chatMessage}
              onChange={(e) => setChatMessage(e.target.value)}
              id="chartboxinput"
              autoFocus={true}
            />
            <button type="submit" className="chat_send_btn">
              <span className="material-icons-round">send</span>
            </button>
          </form>
        </div>
      </div>

      {/* -------------- File upload popup here -------------- */}
      <UploadPopupModal
        cid={props.contactId}
        gid={props.groupId}
        mediaMessageDetails={mediaMessageDetails}
      />

      {/* -------------- Email popup here -------------- */}
      <EmailPopupModal
        groupMembers={groupMembers}
        email={props.contactEmail}
        cid={props.contactId}
        gid={props.groupId}
        confirmSend={props.confirmSend}
        mailInfo={props.mailInfo}
      />
    </>
  );
};

export default ChatBox;
