/* eslint-disable */
import React from "react";
import EventDetailsLeftBottomSection from "./bottomsection/EventDetailsLeftBottomSection";
import EventDetailsLeftTopSection from "./topsection/EventDetailsLeftTopSection";

const EventDetailsLeftPanel = () => {
  return (
    <div className="col-lg-8">
      <div className="details_leftPnl">
        <EventDetailsLeftTopSection />
        <EventDetailsLeftBottomSection />
      </div>
    </div>
  );
};

export default EventDetailsLeftPanel;
