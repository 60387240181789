import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

const HomeBanner = () => {
  return (
    <section className="banner_area">
      <div className="container">
        <div className="banner_outer">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner_content">
                <h1>
                  findelOI: <br />
                  Ecosistema de Innovación Abierta
                </h1>
                <p>
                  Contacta con Ecosismtemas, startups, inversores, asesoresy
                  corporaciones a través de la red social, challenges y eventos
                </p>
                <div className="bnr_btns">
                  <Link to="#" className="btn">
                    Learn More
                  </Link>
                  <Link to="#" className="btn outline">
                    <span className="google_icon">
                      <img src={assetImages.googleLogo} alt="" />
                    </span>
                    <span>Sign up with Google</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner_pic">
                <img src={assetImages.homeBannerPic} alt="homeBannerPic" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
