/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

//import images
import { assetImages } from "../../constants";

const BlogDetailsArea = () => {
  const token = localStorage.getItem("token");

  const params = useParams();

  const [blogDetailsData, setBlogDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getBlogDetails = async (blogTitle) => {
    try {
      setIsLoading(true);
      let requestURL;

      if (token) {
        requestURL =
          url.API_BaseUrl +
          url.API_BLOG_DETAILS +
          `?token=${token}&slug=${blogTitle}`;
      } else {
        requestURL =
          url.API_BaseUrl + url.API_BLOG_DETAILS + `?slug=${blogTitle}`;
      }

      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setBlogDetailsData(response.data);
        } else {
          setBlogDetailsData(null);
        }
        setIsLoading(false);
      } else {
        setBlogDetailsData(null);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getBlogDetails(params.postname);
  }, [params.postname]);

  return (
    <>
      {isLoading ? (
        <div className="blog_postArea">
          <div className="blogpost_hdng">
            <h2>
              <span
                className="placeholder col-12"
                style={{ height: "40px", width: "220px" }}
              ></span>
            </h2>
          </div>

          <div className="blog_social_outer">
            <div className="right_blog_author">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <span className="blog_usr">
                      <span
                        className="placeholder col-12"
                        style={{ height: "35px" }}
                      ></span>
                    </span>
                    <span
                      className="placeholder col-12"
                      style={{ width: "55px" }}
                    ></span>
                  </Link>
                </li>
                <li>
                  <span className="material-icons-outlined date">
                    calendar_month
                  </span>
                  <span
                    className="placeholder col-12"
                    style={{ width: "55px" }}
                  ></span>
                </li>
              </ul>
            </div>
          </div>

          <div className="blog_details_pic">
            <span
              className="placeholder col-12"
              style={{ height: "400px", width: "100%" }}
            ></span>
          </div>

          <div className="blog_details_para">
            <p>
              <span
                className="placeholder rounded-2 col-6 mt-2"
                style={{ height: "20px", width: "100%" }}
              ></span>
              <span
                className="placeholder rounded-2 col-6 mt-2"
                style={{ height: "20px", width: "100%" }}
              ></span>
              <span
                className="placeholder rounded-2 col-6 mt-2"
                style={{ height: "20px", width: "100%" }}
              ></span>
              <span
                className="placeholder rounded-2 col-6 mt-2"
                style={{ height: "20px", width: "100%" }}
              ></span>
            </p>
          </div>

          <div className="blog_taglinks">
            <div className="tag_icon">
              <Link to="#">
                <span className="material-icons-outlined">local_offer</span>
              </Link>
            </div>
            <ul className="list_stye_none">
              <li>
                <Link to="#" className="blog_category">
                  <span
                    className="placeholder col-12"
                    style={{ height: "20px", width: "40px" }}
                  ></span>
                </Link>
              </li>
              <li>
                <Link to="#" className="blog_category">
                  <span
                    className="placeholder col-12"
                    style={{ height: "20px", width: "40px" }}
                  ></span>
                </Link>
              </li>
              <li>
                <Link to="#" className="blog_category">
                  <span
                    className="placeholder col-12"
                    style={{ height: "20px", width: "40px" }}
                  ></span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <>
          {blogDetailsData == null || blogDetailsData.length == 0 ? (
            <div className="blog_postArea">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea mt-3">
                    <div className="empty_lessonpic">
                      <img src={assetImages.emptyLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>Sorry....!</h2>
                      <p>Blog details are not available at this moment.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="blog_postArea">
              <div className="blogpost_hdng">
                {/* <h5>BLOG POST</h5> */}

                {/* ----- blog title ----- */}
                <h2>{blogDetailsData.title}</h2>
              </div>
              <div className="blog_social_outer">
                {/* <div className="left_blogsocial">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">
                        <img src="images/blog-social1.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src="images/blog-social2.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src="images/blog-social3.svg" alt="" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src="images/blog-social4.svg" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div> */}

                {/* ----- blog authore and author image start ----- */}
                {blogDetailsData.author == undefined ||
                blogDetailsData.author == null ? null : (
                  <div className="right_blog_author">
                    <ul className="list_stye_none">
                      <li>
                        <Link to="#">
                          <span className="blog_usr">
                            <img
                              src={
                                blogDetailsData.author.image
                                  ? blogDetailsData.author.image
                                  : assetImages.defaultUser
                              }
                              alt=""
                            />
                          </span>
                          <span>{blogDetailsData.author.name}</span>
                        </Link>
                      </li>
                      <li>
                        <span className="material-icons-outlined date">
                          calendar_month
                        </span>
                        <span>{blogDetailsData.created}</span>
                      </li>
                    </ul>
                  </div>
                )}
                {/* ----- blog authore and author image end ----- */}
              </div>

              {/* ------ blog image start ----- */}
              <div className="blog_details_pic">
                <img
                  src={blogDetailsData.image || assetImages.blogDetails}
                  alt=""
                />
              </div>
              {/* ------ blog image end ----- */}

              {/* ----- blog paragraph start ----- */}
              <div className="blog_details_para">
                <p
                  dangerouslySetInnerHTML={{
                    __html: blogDetailsData.details,
                  }}
                ></p>
              </div>
              {/* ----- blog paragraph end ----- */}

              {/* ----- blog category start ----- */}
              <div className="blog_taglinks">
                <div className="tag_icon">
                  <Link to="#">
                    <span className="material-icons-outlined">local_offer</span>
                  </Link>
                </div>
                <ul className="list_stye_none">
                  {blogDetailsData.category.map((catItem, index) => {
                    return (
                      <li key={index}>
                        <Link to="#" className="blog_category">
                          {catItem.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* ----- blog category end ----- */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BlogDetailsArea;
