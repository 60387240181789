/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";
/* ------------------------------------------------------------------------*/

// importing images here
import { assetImages } from "../../../../../constants";

const AddTagsModal = (props) => {
  const token = localStorage.getItem("token");

  const params = useParams();

  const [tags, setTags] = useState([]);
  // Initial useState to store the entered tags name
  const [newTag, setNewTag] = useState("");

  //initial useState to hold all the selected users
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTagWarining, setNewTagWarining] = useState(false);
  const [tagWarning, setTagWarning] = useState(false);
  const [isAddTagLoading, setIsAddTagLoading] = useState(false);
  const [isRemoveTagLoading, setIsRemoveTagLoading] = useState(false);

  // function for get chat details from API
  const getTagsData = async () => {
    let requestURL = url.API_BaseUrl + url.API_ALL_TAGS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setTags(response.data);
      }
    } catch (error) {}
  };

  //function for add new tag for tag contact/group
  const addNewTagHandler = async (event) => {
    event.preventDefault();

    if (newTag == "") {
      setNewTagWarining(true);
    } else {
      let tagData = {
        name: newTag,
      };
      setNewTag("");
      setNewTagWarining(false);
      let requestURL =
        url.API_BaseUrl + url.API_CREATE_NEW_TAG + `?token=${token}`;

      // console.log(requestURL);

      try {
        const response = await postData(requestURL, tagData);

        // console.log(response);

        if (response.status) {
          // call the get function again to update the dom
          getTagsData();
        }
      } catch (error) {}
    }
    // resetting the useState to clear the text field.
    resetTagFieldHandler();
  };

  // reset all tag fields
  const resetTagFieldHandler = () => {
    setNewTag("");
    setNewTagWarining(false);
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = selectedTags;

    if (prevTags.includes(data.id)) {
      let selectedTagItem = document.getElementById("tagselected-" + data.id);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.id);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagselected-" + data.id);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.id);
    }
    setSelectedTags(prevTags);
  };

  // function for add tags to chat/contact/group
  const saveTagsHandler = async () => {
    let taglists = "";

    selectedTags.map((data, index) => {
      taglists += `&tags[]=${data}`;
    });

    if (selectedTags.length >= 1) {
      setIsAddTagLoading(true);
      setTagWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.ADD_TAG_EVENT +
        `?token=${token}` +
        `&eid=${params.id}` +
        `${taglists}`;

      try {
        const response = await getData(requestURL);

        console.log(response);

        if (response.status) {
          setIsAddTagLoading(false);
          props.afterModalClose();
        }
        let addTagModal = document.querySelector("#event_addtag_mdl");
        let modal = bootstrap.Modal.getInstance(addTagModal);
        modal.hide();
        closeModalHandler();
      } catch (error) {}
    } else {
      setTagWarning(true);
    }
  };

  // function for remove tag from contact/group
  const removeTagsHandler = async () => {
    let taglists = "";

    selectedTags.map((data, index) => {
      taglists += `&tags[]=${data}`;
    });

    if (selectedTags.length >= 1) {
      setIsRemoveTagLoading(true);
      setTagWarning(false);
      let requestURL =
        url.API_BaseUrl +
        url.REMOVE_TAG_EVENT +
        `?token=${token}` +
        `&eid=${params.id}` +
        `${taglists}`;

      try {
        const response = await getData(requestURL);

        if (response.status) {
          setIsRemoveTagLoading(false);
          props.afterModalClose();
        }
        let addTagModal = document.querySelector("#event_addtag_mdl");
        let modal = bootstrap.Modal.getInstance(addTagModal);
        modal.hide();
        closeModalHandler();
      } catch (error) {}
    } else {
      setTagWarning(true);
    }
  };

  // function for reset all data after close modal
  const closeModalHandler = () => {
    resetTagFieldHandler();
    setSelectedTags([]);
    resetSelectTags();
    setTagWarning(false);
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("displayTags");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  useEffect(() => {
    // API call to get all the Tag data here
    getTagsData();
  }, []);

  return (
    <div className="chat_modalbx">
      <div
        className="modal fade show"
        id="event_addtag_mdl"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header mdl_hdng">
              <h5 className="modal-title">Add Tag</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModalHandler}
              >
                <span className="material-icons-round">close </span>
              </button>
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="group_srchfill add_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter to create new tag"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                />
                <Link
                  to="#"
                  className="add_icon"
                  onClick={(e) => addNewTagHandler(e)}
                >
                  <span className="material-icons-round">add_circle </span>
                </Link>
              </div>

              {/* ---- tag list start ---- */}
              {tags.length > 0 && (
                <div className="group_innrScroll">
                  <div className="tag_links">
                    <ul className="list_stye_none">
                      {tags.map((data, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to="#"
                              className="displayTags"
                              onClick={() => tagselectedHandler(data)}
                              id={"tagselected-" + data.id}
                            >
                              {data.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
              {/* ---- tag list end ---- */}

              {/* ---- modal buttons ----- */}
              <div className="add_deleteouter mx-3 mb-3">
                {tagWarning && (
                  <p className="text-danger mb-2">
                    * {t("Select atleast one tag")}
                  </p>
                )}
                <div className="add_btn">
                  <Link
                    to="#"
                    className="basic_btn"
                    onClick={saveTagsHandler}
                    style={{ pointerEvents: isAddTagLoading ? "none" : "" }}
                  >
                    {isAddTagLoading ? (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i className="material-icons-round"> check_circle </i>
                    )}
                    <span>Add</span>
                  </Link>
                </div>
                <div className="add_btn">
                  <Link
                    to="#"
                    className="border_btn"
                    onClick={removeTagsHandler}
                    style={{
                      pointerEvents: isRemoveTagLoading ? "none" : "",
                    }}
                  >
                    {isRemoveTagLoading ? (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i className="material-icons-round"> delete </i>
                    )}
                    <span>Remove</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagsModal;
