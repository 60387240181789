/* eslint-disable */
import React from "react";

const Banner = (props) => {
  return (
    <section className="checkout_area job_serchSec profile_area">
      <div className="container">
        <div className="chkout_hdng">
          <h2>Search for experts</h2>
          <h5>Over 5 million expert listed</h5>
        </div>
      </div>
    </section>
  );
};

export default Banner;
