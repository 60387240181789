/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import Gateway for API fetch call
import { getData } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../../../../constants";

const AboutTab = () => {
  const token = localStorage.getItem("token");

  const glowCarousel = [1, 2, 3];

  const params = useParams();

  const CardResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //initial useState for event details about data
  const [eventAboutData, setEventAboutData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to get all the about data from API
  const getEventBannerData = async (id) => {
    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl + url.API_EVENT_ABOUT + `?token=${token}` + `&id=${id}`;

    try {
      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        if (response.data) {
          if (response.data.length == 0) {
            setEventAboutData(null);
          } else {
            setEventAboutData(response.data);
          }
        } else {
          setEventAboutData(null);
        }
        setIsLoading(false);
      } else {
        setEventAboutData(null);
      }
    } catch (error) {}
  };

  // useEffect from here
  useEffect(() => {
    getEventBannerData(params.id);
  }, [params.id]);

  return (
    <div className="tab-pane fade show active">
      {isLoading ? (
        <div className="exprt_overviewOuter">
          <div className="job_detailspnl">
            <div className="event_catagoryslide_Outer">
              <div className="evenmt_catagory_slider">
                <Carousel ssr responsive={CardResponsive}>
                  {glowCarousel.map((count) => {
                    return (
                      <div className="catagory_slideItem" key={count}>
                        <div className="catagory_slideInnr">
                          <div className="catagory_slideHdng">
                            <h3>
                              <span
                                className="placeholder"
                                style={{ width: "70px" }}
                              ></span>
                            </h3>
                          </div>
                          <div className="evnt_catagory_list">
                            <ul className="list_stye_none">
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "90px", height: "34px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "90px", height: "34px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "90px", height: "34px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "90px", height: "34px" }}
                                ></span>
                              </li>
                              <li>
                                <span
                                  className="placeholder rounded-2"
                                  style={{ width: "90px", height: "34px" }}
                                ></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="job_detailspnl">
            <div className="contnt_hdng">
              <h4>
                <span className="placeholder" style={{ width: "80px" }}></span>
              </h4>
            </div>
            <div className="results_para">
              <p>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-10"></span>
              </p>
              <p>
                <span className="placeholder col-12"></span>
                <span className="placeholder col-10"></span>
                <span className="placeholder col-8"></span>
              </p>
            </div>
          </div>
          <div className="job_detailspnl">
            <div className="contnt_hdng">
              <h4>
                <span className="placeholder" style={{ width: "80px" }}></span>
              </h4>
            </div>
            <div className="sponsor_cmpny">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </i>
                    <span className="placeholder w-100"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </i>
                    <span className="placeholder w-100"></span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <span
                        className="placeholder"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </i>
                    <span className="placeholder w-100"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="job_detailspnl">
            <div className="contnt_hdng">
              <h4>
                <span className="placeholder" style={{ width: "100px" }}></span>
              </h4>
            </div>
            <div className="upload_area">
              <div className="upload_item">
                <div className="uploaded_innrBx">
                  <div className="upload_icon">
                    <span
                      className="placeholder mx-auto"
                      style={{ width: "38px", height: "38px" }}
                    ></span>
                  </div>
                  <div className="upload_txt">
                    <h4>
                      <span className="placeholder col-10"></span>
                    </h4>
                    <h5>
                      <span className="placeholder col-4"></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="upload_item">
                <div className="uploaded_innrBx">
                  <div className="upload_icon">
                    <span
                      className="placeholder mx-auto"
                      style={{ width: "38px", height: "38px" }}
                    ></span>
                  </div>
                  <div className="upload_txt">
                    <h4>
                      <span className="placeholder col-10"></span>
                    </h4>
                    <h5>
                      <span className="placeholder col-4"></span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="exprt_overviewOuter">
          {eventAboutData == null ? (
            <div className="event_about_main">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="empty_lessonsArea mt-3">
                    <div className="empty_lessonpic">
                      <img src={assetImages.emptyLesson} alt="blanklesson" />
                    </div>
                    <div className="empty_lessons_text">
                      <h2>Sorry....!</h2>
                      <p>
                        Events are not available for this event at this moment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="event_about_main">
              {/* ----------- Carousel Section starts here ----------- */}
              <div className="job_detailspnl">
                <div className="event_catagoryslide_Outer">
                  <div className="evenmt_catagory_slider">
                    <Carousel ssr responsive={CardResponsive}>
                      {/* ---- category slide start ---- */}
                      {eventAboutData.category == null ? null : (
                        <div className="catagory_slideItem slide_Item">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Category</h3>
                            </div>
                            {eventAboutData.category.length > 0 ? (
                              <div className="evnt_catagory_list">
                                <ul className="list_stye_none">
                                  {eventAboutData.category.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      {/* ---- category slide end ---- */}

                      {/* ---- group slide start ---- */}
                      {/* <div className="catagory_slideItem slide_Item">
                    <div className="catagory_slideInnr">
                      <div className="catagory_slideHdng">
                        <h3>Group</h3>
                      </div>
                      <div className="evnt_catagory_list">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">GameBoy</Link>
                          </li>
                          <li>
                            <Link to="#">TechGuru</Link>
                          </li>
                          <li>
                            <Link to="#">WhatisTech</Link>
                          </li>
                          <li>
                            <Link to="#">Machaionly</Link>
                          </li>
                          <li>
                            <Link to="#">Linus Tech Tips</Link>
                          </li>
                          <li>
                            <Link to="#">TG Game</Link>
                          </li>
                          <li>
                            <Link to="#">CarryMinati</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                      {/* ---- group slide end ---- */}

                      {/* ---- search slide start ----- */}
                      {eventAboutData.searching == null ? null : (
                        <div className="catagory_slideItem slide_Item">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Search</h3>
                            </div>
                            {eventAboutData.searching.length > 0 ? (
                              <div className="evnt_catagory_list">
                                <ul className="list_stye_none">
                                  {eventAboutData.searching.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      {/* ---- search slide end ----- */}

                      {/* ----- interest slide start ----- */}
                      {eventAboutData.interest == null ? null : (
                        <div className="catagory_slideItem slide_Item">
                          <div className="catagory_slideInnr">
                            <div className="catagory_slideHdng">
                              <h3>Interest</h3>
                            </div>
                            {eventAboutData.interest.length > 0 ? (
                              <div className="evnt_catagory_list">
                                <ul className="list_stye_none">
                                  {eventAboutData.interest.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <Link to="#">{item}</Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      {/* ----- interest slide end ----- */}

                      {/* ----- another offer slide start ----- */}
                      {/* <div className="catagory_slideItem slide_Item">
                    <div className="catagory_slideInnr">
                      <div className="catagory_slideHdng">
                        <h3>Another Offer</h3>
                      </div>
                      <div className="evnt_catagory_list">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">Tech Event</Link>
                          </li>
                          <li>
                            <Link to="#">Latest Events</Link>
                          </li>
                          <li>
                            <Link to="#">Digital</Link>
                          </li>
                          <li>
                            <Link to="#">OS Events</Link>
                          </li>
                          <li>
                            <Link to="#">Highest Rated</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                      {/* ----- another offer slide end ----- */}
                    </Carousel>
                  </div>
                </div>
              </div>

              {/* ----------- Summary Section starts here ----------- */}
              <div className="job_detailspnl">
                <div className="contnt_hdng">
                  <h4>Summary</h4>
                </div>
                <div className="results_para">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: eventAboutData.description,
                    }}
                  ></p>
                </div>
              </div>

              {/* ----------- Sponsors Section starts here ----------- */}
              {/* <div className="job_detailspnl">
            <div className="contnt_hdng">
              <h4>Sponsors</h4>
            </div>
            <div className="sponsor_cmpny">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <i>
                      <img src={assetImages.sponsor1} alt="" />
                    </i>
                    <span>Microsoft</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <img src={assetImages.sponsor2} alt="" />
                    </i>
                    <span>Dropbox</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i>
                      <img src={assetImages.sponsor3} alt="" />
                    </i>
                    <span>Google</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}

              {/* ----------- Documents Section starts here ----------- */}
              {eventAboutData.document.length === 0 ? null : (
                <div className="job_detailspnl">
                  <div className="contnt_hdng">
                    <h4>Documents</h4>
                  </div>
                  <div className="upload_area">
                    {eventAboutData.document.map((document, index) => {
                      return (
                        <div
                          className="upload_item"
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open(document, "_blank")}
                        >
                          <div className="uploaded_innrBx">
                            <div className="upload_icon">
                              {document.split(".").pop() == "pdf" ? (
                                <img src={assetImages.pdf} alt="" />
                              ) : (
                                <>
                                  {document.split(".").pop() == "docx" ||
                                  document.split(".").pop() == "txt" ||
                                  document.split(".").pop() == "doc" ? (
                                    <img src={assetImages.docs} alt="docs" />
                                  ) : (
                                    <img src={assetImages.xls} alt="pdf" />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="upload_txt">
                              {/* <h4>MyResume.pdf</h4>
                          <h5>1.5mb</h5> */}
                              <Link
                                to="#"
                                className="download-file d-block mt-2"
                              >
                                <i className="material-icons ">file_download</i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AboutTab;
