/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const ValidateModal = () => {
  const { setUserRegisterData } = useContext(GlobalFindelOiProvider);

  const displayInfoModal = () => {
    let validateModal = document.querySelector("#validate_mdl");
    let modal = bootstrap.Modal.getInstance(validateModal);
    modal.hide();
    let myModal = new bootstrap.Modal(document.getElementById("info_mdl"));
    myModal.show();
  };

  return (
    <div className="registration_modal">
      <div className="modal fade show" id="validate_mdl">
        <div className="modal-dialog medium_modal">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              {/* ---------- Modal heading starts here ---------- */}
              <div className="signin_hdng text-left">
                <h3>
                  Do you want us to introduce you to potential clients and
                  collaborators at Lobees?
                </h3>
              </div>

              {/* ---------- Modal Close button starts here ---------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal Body starts here ----------- */}
            <div className="modal-body">
              <div className="login_body">
                {/* ---------- Form section starts here ---------- */}

                <div className="validate_msg">
                  <p>
                    At Lobees we proactively collaborate with our community to
                    generate business and collaborations.
                  </p>
                </div>

                <div className="validate_list">
                  <label>We work as follows:</label>
                  <ul className="list_stye_none">
                    <li>
                      We structure the needs of each company with a team of more
                      than 30 people
                    </li>
                    <li>
                      We seek synergies between companies based on their profile
                      and need
                    </li>
                    <li>We organize events for companies to meet</li>
                    <li>We create GAC groups (High Commitment Groups)</li>
                  </ul>
                </div>

                <div className="interest_hdng">
                  <h4>Are you interested in participating?</h4>
                </div>

                <div className="loginstep_btnsouter">
                  <div className="left_btn">
                    <Link
                      to="#"
                      className="btn gray_btn"
                      onClick={() => {
                        setUserRegisterData(null);
                        window.location.reload();
                      }}
                    >
                      Skip
                    </Link>
                  </div>
                  <div className="right_btn">
                    <button
                      className="btn"
                      // data-bs-dismiss="modal"
                      // data-bs-target="#info_mdl"
                      // data-bs-toggle="modal"
                      onClick={displayInfoModal}
                    >
                      Yes, Continue
                    </button>
                  </div>
                </div>

                {/* ---------- Form section ends here ---------- */}
              </div>
            </div>
            {/* ----------- Modal Body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateModal;
