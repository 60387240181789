/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// importing images here
import { assetImages } from "../../constants";

const Footer = () => {
  /* ------------- Single Select requirements starts here ------------- */

  // useState to display the selected values
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);

  // useState to save the selected values to send to API
  const [selectedCountryValue, setSelectedCountryValue] = useState(null);

  // country options
  const countryOptions = [
    {
      id: 1,
      label: "India",
      value: "India",
    },
    {
      id: 2,
      label: "USA",
      value: "USA",
    },
    {
      id: 3,
      label: "Spain",
      value: "Spain",
    },
  ];

  // function to select
  const countryOptionSelectionHandler = (val) => {
    setSelectedCountryOption();
    setSelectedCountryValue(val);
  };
  /* ------------- Single Select requirements ends here ------------- */

  // return statement starts here
  return (
    <footer className="footer_outer">
      <div className="container">
        {/* ----------------- Footer top section starts here ----------------- */}
        <div className="footer_top">
          <div className="row">
            {/* --------- Logo section here --------- */}
            <div className="col-lg-6">
              <div className="footer_logo">
                <Link to="/">
                  <img src={assetImages.homeLogo} alt="homeLogo" />
                </Link>
              </div>
            </div>

            {/* --------- 'For Candidates' section here --------- */}
            <div className="col-lg-2 col-md-4">
              <div className="ftr_item">
                <h3>For Candidates</h3>
                <div className="ftr_links">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">Overview</Link>
                    </li>
                    <li>
                      <Link to="#">Startup Jobs</Link>
                    </li>
                    <li>
                      <Link to="#">Web3 Jobs</Link>
                    </li>
                    <li>
                      <Link to="#">Featured</Link>
                    </li>
                    <li>
                      <Link to="#">Salary Calculator</Link>
                    </li>
                    <li>
                      <Link to="#">Tech Startups</Link>
                    </li>
                    <li>
                      <Link to="#">Remote</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* --------- 'For recruiters' section here --------- */}
            <div className="col-lg-2 col-md-4">
              <div className="ftr_item">
                <h3>For Recruiters</h3>
                <div className="ftr_links">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">Overview</Link>
                    </li>
                    <li>
                      <Link to="#">Recruit Pro</Link>
                    </li>
                    <li>
                      <Link to="#">Curated</Link>
                    </li>
                    <li>
                      <Link to="#">Remote</Link>
                    </li>
                    <li>
                      <Link to="#">Hire Developers</Link>
                    </li>
                    <li>
                      <Link to="#">Pricing</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* --------- 'Company' section here --------- */}
            <div className="col-lg-2 col-md-4">
              <div className="ftr_item">
                <h3>Company</h3>
                <div className="ftr_links">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">About</Link>
                    </li>
                    <li>
                      <Link to="#">Startup Jobs</Link>
                    </li>
                    <li>
                      <Link to="#">Findeloi Venture</Link>
                    </li>
                    <li>
                      <Link to="#">Help</Link>
                    </li>
                    <li>
                      <Link to="#">Blog</Link>
                    </li>
                    <li>
                      <Link to="#">Terms & Risks</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy & Cookies</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------- Footer top section ends here ----------------- */}

        {/* ----------------- Footer bottom section starts here ----------------- */}
        <div className="footer_bttm">
          <div className="row">
            {/* ----------------- Social Links & dropdown starts here ----------------- */}
            <div className="col-lg-6">
              <div className="ftr_socialpnl">
                {/* ------------ Social Links ------------ */}
                <div className="ftr_social">
                  <ul className="list_stye_none">
                    <li>
                      <Link to="#">
                        <img src={assetImages.twitter} alt="twitterLogo" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={assetImages.instagram} alt="instagramLogo" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <img src={assetImages.facebook} alt="facebookLogo" />
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* ------------ React-select Dropdown ------------ */}
                <div className="flg_selctBtn">
                  <Select
                    isMulti={false}
                    placeholder="Select Country"
                    options={countryOptions} // show all options
                    value={selectedCountryOption} // show selected values
                    onChange={(val) => {
                      countryOptionSelectionHandler(val);
                    }} // handler function
                    classNamePrefix="select2-selection nosearch_select"
                    // styles={customSelectStyle}
                    // onKeyDown={searchByEnterSelect}
                    menuPlacement="top"
                  />
                </div>
              </div>
            </div>

            {/* ----------------- Remote Jobs link starts here ----------------- */}
            <div className="col-lg-6">
              <div className="browse_text">
                <p>
                  Browse by: <Link to="#">Jobs,</Link>
                  <Link to="#">Remote Jobs</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------- Footer bottom section ends here ----------------- */}

        {/* ----------------- Footer copyright starts here ----------------- */}
        <div className="copyright">
          <p>Copyright © 2023 FindelOI. All rights reserved.</p>
        </div>
        {/* ----------------- Footer copyright ends here ----------------- */}
      </div>
    </footer>
  );
};

export default Footer;
