/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";
/* ---------------------------------------------------------------------------------- */

const AddTagsModal = (props) => {
  // getting logged in user's token here
  const token = localStorage.getItem("token");

  // Initial useState to store all the tags from the API
  const [tags, setTags] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [mailTagId, setMailTagId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [groupLabelId, setGroupLabelId] = useState(null);
  const [tagWarning, setTagWarning] = useState(false);
  const [isAddTagLoading, setIsAddTagLoading] = useState(false);
  const [isRemoveTagLoading, setIsRemoveTagLoading] = useState(false);

  // Initial useState to store the entered tags name
  const [newTag, setNewTag] = useState("");
  const [newTagWarining, setNewTagWarining] = useState(false);

  // initial useState to hold all the selected users
  const [selectedTags, setSelectedTags] = useState([]);

  // function for get chat details from API
  const getTagsData = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_CONVERSATION_TAGS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setTags(response.data);
      }
    } catch (error) {}
  };

  //function for add new tag for tag contact/group
  const addNewTagHandler = async (event) => {
    event.preventDefault();

    if (newTag == "") {
      setNewTagWarining(true);
    } else {
      let tagData = {
        name: newTag,
      };
      setNewTagWarining(false);
      let requestURL =
        url.API_BaseUrl + url.API_CREATE_NEW_TAG + `?token=${token}`;

      try {
        const response = await postData(requestURL, tagData);

        if (response.status) {
          // call the get function again to update the dom
          getTagsData();
        }
      } catch (error) {}
    }
    // resetting the useState to clear the text field.
    resetTagFieldHandler();
  };

  // reset all tag fields
  const resetTagFieldHandler = () => {
    setNewTag("");
    setNewTagWarining(false);
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = selectedTags;

    if (prevTags.includes(data.id)) {
      let selectedTagItem = document.getElementById("tagselection-" + data.id);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.id);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagselection-" + data.id);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.id);
    }
    setSelectedTags(prevTags);
  };

  // function for add tags to chat/contact/group
  const saveTagsHandler = async () => {
    let tid = "";

    selectedTags.map((data, index) => {
      tid += `&tags[]=${data}`;
    });

    if (selectedTags.length >= 1) {
      setIsAddTagLoading(true);
      setTagWarning(false);
      let requestURL = url.API_BaseUrl;

      if (chatId != null) {
        requestURL =
          requestURL +
          url.API_SAVE_CHAT_TAGS +
          `?token=${token}` +
          `&chatid=${chatId}` +
          `${tid}`;
      }

      if (mailTagId != null) {
        requestURL =
          requestURL +
          url.API_ADD_TAG_MAIL +
          `?token=${token}` +
          `&mailid=${mailTagId}` +
          `${tid}`;
      }

      if (contactId != null) {
        requestURL =
          requestURL +
          url.API_ADD_TAG_CONTACT +
          `?token=${token}` +
          `&contactid=${contactId}` +
          `${tid}`;
      }

      if (groupLabelId != null) {
        requestURL =
          requestURL +
          url.API_ADD_TAG_GROUP +
          `?token=${token}` +
          `${tid}` +
          `&gid=${groupLabelId}`;
      }

      try {
        const response = await getData(requestURL);

        if (response.status) {
          let addTagModal = document.querySelector("#addtag_mdl");
          let modal = bootstrap.Modal.getInstance(addTagModal);
          modal.hide();
          closeModalHandler();
          props.displayLeftPanel();
          setIsAddTagLoading(false);
        }
      } catch (error) {}
    } else {
      setTagWarning(true);
    }
  };

  // function for remove tag from contact/group
  const removeTagsHandler = async () => {
    let tid = "";

    selectedTags.map((data, index) => {
      tid += `&tags[]=${data}`;
    });

    if (selectedTags.length >= 1) {
      setIsRemoveTagLoading(true);
      setTagWarning(false);
      let requestURL = url.API_BaseUrl;

      if (chatId != null) {
        requestURL =
          requestURL +
          url.API_REMOVE_CHAT_TAGS +
          `?token=${token}` +
          `&chatid=${chatId}` +
          `${tid}`;
      }

      if (mailTagId != null) {
        requestURL =
          requestURL +
          url.API_REMOVE_TAG_MAIL +
          `?token=${token}` +
          `&mailid=${mailTagId}` +
          `${tid}`;
      }

      if (contactId != null) {
        requestURL =
          requestURL +
          url.API_REMOVE_TAG_CONTACT +
          `?token=${token}` +
          `&contactid=${contactId}` +
          `${tid}`;
      }

      if (groupLabelId != null) {
        requestURL =
          requestURL +
          url.API_REMOVE_TAG_GROUP +
          `?token=${token}` +
          `${tid}` +
          `&gid=${groupLabelId}`;
      }

      try {
        const response = await getData(requestURL);

        if (response.status) {
          let addTagModal = document.querySelector("#addtag_mdl");
          let modal = bootstrap.Modal.getInstance(addTagModal);
          modal.hide();
          closeModalHandler();
          props.displayLeftPanel();
          setIsRemoveTagLoading(false);
        }
      } catch (error) {}
    } else {
      setTagWarning(true);
    }
  };

  // function for reset all data after close modal
  const closeModalHandler = () => {
    props.resetTagModal();
    resetTagFieldHandler();
    setChatId(null);
    setContactId(null);
    setGroupLabelId(null);
    setSelectedTags([]);
    resetSelectTags();
    setTagWarning(false);
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("displayTags");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  // useeffect for chatId via props
  useEffect(() => {
    if (props.chatId != null) {
      setChatId(props.chatId);
    } else {
      setChatId(null);
    }
  }, [props.chatId]);

  // useeffect for chatId via props
  useEffect(() => {
    if (props.mailId != null) {
      setMailTagId(props.mailId);
    } else {
      setMailTagId(null);
    }
  }, [props.mailId]);

  // useEffect for contactId via props
  useEffect(() => {
    if (props.contactId != null) {
      setContactId(props.contactId);
    } else {
      setContactId(null);
    }
  }, [props.contactId]);

  // useEffect for groupId via props
  useEffect(() => {
    if (props.groupLabelId != null) {
      setGroupLabelId(props.groupLabelId);
    } else {
      setGroupLabelId(null);
    }
  }, [props.groupLabelId]);

  useEffect(() => {
    // API call to get all the Tag data here
    getTagsData();
  }, []);

  // return  function from here
  return (
    <>
      <div className="chat_modalbx">
        <div className="modal fade" id="addtag_mdl">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ---------- Modal Header starts here ---------- */}
              <div className="modal-header mdl_hdng">
                <h5 className="modal-title">Labels</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModalHandler}
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              {/* ---------- Modal Header ends here ---------- */}

              {/* ---------- Modal Body starts here ---------- */}
              <div className="modal-body">
                {/* -------------------- Searchbar starts here -------------------- */}
                <div className="group_srchfill add_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter to create new label"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                  />
                  <Link
                    to="#"
                    onClick={(e) => addNewTagHandler(e)}
                    className="add_icon"
                  >
                    <span className="material-icons-round">add_circle </span>
                  </Link>
                </div>
                {/* -------------------- Searchbar ends here -------------------- */}

                {/* -------------------- Tags list starts here -------------------- */}
                {tags.length > 0 ? (
                  <div className="group_innrScroll">
                    <div className="tag_links">
                      <ul>
                        {tags.map((data, index) => (
                          <li key={data.id}>
                            <Link
                              to="#"
                              className="displayTags"
                              onClick={() => tagSelectionHandler(data)}
                              id={"tagselection-" + data.id}
                            >
                              {data.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : null}

                {/* -------------------- Tags list ends here -------------------- */}

                {/* -------------------- Button section starts here -------------------- */}
                <div className="group_btnOuter">
                  {tagWarning && (
                    <p className="text-danger mb-2">* Select atleast one tag</p>
                  )}
                  <ul>
                    <li className="w_100">
                      <Link
                        to="#"
                        className="ornage_btn"
                        role="button"
                        onClick={saveTagsHandler}
                      >
                        {isAddTagLoading ? (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="material-icons-round"> check_circle </i>
                        )}
                        <span>Add</span>
                      </Link>
                    </li>
                    <li className="w_100">
                      <Link
                        to="#"
                        className="red_btn"
                        role="button"
                        onClick={removeTagsHandler}
                      >
                        {isRemoveTagLoading ? (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <i className="material-icons-round"> check_circle </i>
                        )}
                        <span>Delete</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* -------------------- Button section ends here -------------------- */}
              </div>
              {/* ---------- Modal Body ends here ---------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTagsModal;
