/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing custom components here
import Header from "../../components/common/Header";
import ExpertDetailsArea from "../../components/expertdetailscomponents/ExpertDetailsArea";
import Footer from "../../components/common/Footer";

const ExpertDetails = () => {
  // return statement from here
  return (
    <>
      <Header activePage={"experts"} />
      <section className="checkout_area job_serchSec">
        <div className="container">
          {/* ----------- Details Page banner & 'back' button ----------- */}
          <div className="page_back">
            <Link to="/experts">
              <span className="material-icons-outlined"> arrow_back</span>
              Back
            </Link>
          </div>

          {/* ----------- Details Page main components starts here ----------- */}
          <div className="job_box_outer details_total exper_detailsSec">
            <ExpertDetailsArea />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ExpertDetails;
