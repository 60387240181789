/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// import Gateway for API fetch call
import { getData } from "../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../../../constants";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../context/FindeloiContext";

// importing modals here
import FeedbackModal from "./modals/FeedbackModal";
import AddTagsModal from "./modals/AddTagsModal";
import ExpertDetailsConnectModal from "./modals/ExpertDetailsConnectModal";

const ExpertDetailsLeftTopSection = () => {
  const { reloadExpertTop, setReloadExpertTop, setIsExpertConnected } =
    useContext(GlobalFindelOiProvider);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const loggedInUserID = localStorage.getItem("userID");

  const params = useParams();
  const history = useHistory();

  //initial for champion data
  const [championData, setChampionData] = useState(null);

  // initial useState for expert fullname
  const [expertName, setExpertName] = useState("");

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  // function for get champion details
  const getChampionsData = async (id) => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_EXPERT_BANNER +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      const response = await getData(requestURL);

      console.log("Expert Details banner response --->", response);

      if (response.status) {
        if (response.data) {
          if (response.data.length == 0) {
            setChampionData(null);
          } else {
            setChampionData(response.data);
            if (response.data.name && response.data.surname) {
              let championName =
                response.data.name + " " + response.data.surname;
              // sending the name to the useContext API
              setExpertName(championName);
            }
            // send champion data
            if (response.data.connectstatus == true) {
              setIsExpertConnected(true);
            } else {
              setIsExpertConnected(false);
            }
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("Expert Details banner error --->", error);
    }
  };

  const afterModalClose = () => {
    getChampionsData(params.id);
  };

  //use effect for refresh top section after close review modal
  useEffect(() => {
    if (reloadExpertTop) {
      getChampionsData(params.id);
      setReloadExpertTop(false);
    }
  }, [reloadExpertTop]);

  // useEffect from here
  useEffect(() => {
    getChampionsData(params.id);
    // console.log("Received Expert Id -----> ", params.id);
  }, [params.id]);

  // return statement starts here
  return (
    <>
      {isLoading ? (
        <div className="alert alert-primary" role="alert">
          Temporary loading!It will removed after glow placeholder
        </div>
      ) : (
        <div className="evnt_details_bx">
          {championData == null || championData.length == 0 ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={assetImages.emptyLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>Sorry....!</h2>
                <p>No details available for this expert at this moment.</p>
              </div>
            </div>
          ) : (
            <div className="top_detailsBx">
              {/* ----- Expert Top Left section starts here ----- */}
              <div className="topdetails_left">
                <div className="expertholder_outer">
                  {/* --------- User image section starts here --------- */}
                  <div className="experts_holderPic">
                    <img src={championData.fullimage} alt="expertPic" />
                  </div>

                  {/* ----- User name, position, location &rating section starts here ----- */}
                  <div className="experts_holdertext">
                    <h3>
                      {championData.name} {championData.surname}{" "}
                      <span>{championData.position}</span>
                    </h3>

                    {/* --------- Expert location & rating section starts here --------- */}
                    <div className="details_tags expert_details">
                      {/* --------- Expert location section starts here --------- */}
                      {championData.city != null ||
                      (championData.city != "" &&
                        championData.country != null) ||
                      championData.country != "" ? (
                        <div className="jb_rating">
                          <span>
                            {championData.city}, {championData.country}
                          </span>
                        </div>
                      ) : null}

                      {/* --------- Expert rating section starts here --------- */}
                      <div className="jb_rating star_color">
                        <i className="material-icons-outlined"> star </i>
                        <span>{championData.rating}</span>
                        <h6>
                          ({championData.reviews}
                          <em>reviews</em>)
                        </h6>
                      </div>
                    </div>

                    {/* --------- Expert social links section starts here --------- */}
                    <div className="expert_social">
                      <ul className="list_stye_none">
                        <li>
                          <Link to="#">
                            <img src={assetImages.fb} alt="facebook" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={assetImages.gmail} alt="gmail" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={assetImages.linkedin} alt="linkedin" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={assetImages.pinterest} alt="pinterest" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* --------- Expert social links section ends here --------- */}
                  </div>
                  {/* ----- User name, position, location &rating section ends here ----- */}
                </div>
              </div>
              {/* ----- Expert Top Left section ends here ----- */}

              {/* ----- Expert Top Right section starts here ----- */}
              <div className="topdetails_right">
                {/* ----- Expert rate starts here ----- */}
                <div className="details_value">
                  <h4>${championData.hourlyrate}/Hour</h4>
                </div>

                {/* ----- Expert 'share', 'more' & 'feedback' buttons starts here ----- */}
                <div className="expartbttm_area">
                  {/* ----- Expert 'share' & 'more' buttons starts here ----- */}
                  <div className="exprt_share">
                    <ul className="list_stye_none">
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#addtag_mdl"
                        >
                          <i className="material-icons-outlined">more</i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="material-icons-outlined">share</i>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* ----- Expert 'feedback' & 'connect' buttons starts here ----- */}
                  <div className="feedback_jointbtnOuter">
                    <div className="feed_btn">
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#feedback_modal"
                        className="border_btn"
                      >
                        Feedback
                      </Link>
                    </div>

                    {/* ------------- 'Connect' button starts here ------------- */}
                    {championData.isconnect ? (
                      <div className="details_apply">
                        {championData.connectstatus ? null : (
                          <Link to="#" className="basic_btn">
                            <i className="material-icons">error</i>
                            Pending Approval
                            {/* {championData.connectdate && (
                              <span>{championData.connectdate}</span>
                            )} */}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div className="details_apply">
                        {championData.id == loggedInUserID ? null : (
                          <Link
                            to="#"
                            className="basic_btn"
                            data-bs-toggle="modal"
                            data-bs-target="#expert_details_contact_modal"
                            onClick={() => setExpertName(expertName)}
                          >
                            Connect
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* ----- Expert Top Right section ends here ----- */}
            </div>
          )}

          {/* ---------- 'Feedback' Modal implemented here ---------- */}
          <FeedbackModal />

          {/* ---------- 'Add Tags' Modal implemented here ---------- */}
          <AddTagsModal />

          {/* ---------- 'Expert Details Connect' Modal implemented here ---------- */}
          <ExpertDetailsConnectModal
            expertName={expertName}
            afterModalClose={afterModalClose}
            token={token}
          />
        </div>
      )}
    </>
  );
};

export default ExpertDetailsLeftTopSection;
