/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../../constants";

// importing custom components here
import OverviewTab from "./tabs/OverviewTab";
import PortfolioTab from "./tabs/PortfolioTab";
import ReviewTab from "./tabs/ReviewTab";

const ExpertDetailsLeftBottomSection = () => {
  // initial useState for all the tabs
  const [overviewTab, setOverviewTab] = useState(true);
  const [portfolioTab, setPortfolioTab] = useState(false);
  const [reviewTab, setReviewTab] = useState(false);

  // functions to toggle the tabs
  const toggleOverviewTab = (e) => {
    e.preventDefault();
    setOverviewTab(true);
    setPortfolioTab(false);
    setReviewTab(false);
  };

  const togglePortfolioTab = (e) => {
    e.preventDefault();
    setOverviewTab(false);
    setPortfolioTab(true);
    setReviewTab(false);
  };

  const toggleReviewTab = (e) => {
    e.preventDefault();
    setOverviewTab(false);
    setPortfolioTab(false);
    setReviewTab(true);
  };

  // return statement starts here
  return (
    <div className="details_tabArea expert_detailsTab">
      {/* ------------ Tab 'Links' section starts here ------------ */}
      <div className="addons_tabLinks jobdetails_tablinks">
        <ul className="nav nav-tabs list_stye_none">
          {/* -------- Overview Link -------- */}
          <li className="nav-item">
            <Link
              className={
                overviewTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleOverviewTab(e)}
            >
              Overview
            </Link>
          </li>

          {/* -------- Portfolio Link -------- */}
          <li className="nav-item">
            <Link
              className={
                portfolioTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => togglePortfolioTab(e)}
            >
              Portfolio
            </Link>
          </li>

          {/* -------- Review Link -------- */}
          <li className="nav-item">
            <Link
              className={
                reviewTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleReviewTab(e)}
            >
              Reviews
            </Link>
          </li>
        </ul>
      </div>

      {/* ------------ Tab 'Contents' section starts here ------------ */}
      <div className="tab-content">
        {/* ----------- Overview Tab ----------- */}
        {overviewTab && <OverviewTab />}

        {/* ----------- Portfolio Tab ----------- */}
        {portfolioTab && <PortfolioTab />}

        {/* ----------- Reviews Tab ----------- */}
        {reviewTab && <ReviewTab />}
      </div>
    </div>
  );
};

export default ExpertDetailsLeftBottomSection;
