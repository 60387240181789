/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// importing images here
import { assetImages } from "../../constants";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import {
  postFormdata,
  uploadFile,
  getData,
  postData,
} from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";
import ProfileUpdateSuccessModal from "./modals/ProfileUpdateSuccessModal";

/* ----------------------------------------------------------------------------------- */

const ProfileArea = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  console.log(userInfo);

  const [userSkillList, setUserSkillList] = useState([]);

  /*---- CKEditor classic editor configuration----- */
  const editorConfig1 = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      // uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: "Enter Details ...",
  };
  /*----- end of CKEditor classic editor part----- */

  //custom style for react select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "6px",
      fontFamily: "sans-serif",
      border: "1px solid #dddddd",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 10%)",
      backgroundColor: "#fff",
      height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8EA6 !important",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // selected value
    singleValue: (provided) => ({
      ...provided,
      color: "666666",
      fontSize: "16px",
    }),

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // initial useState for profile data
  const [uploadedImage, setUploadedImage] = useState(
    userInfo.image || assetImages.defaultUser
  );
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedImageId, setUploadedImageId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [selectedSkillValue, setSelectedSkillValue] = useState(null);
  const [skills, setSkills] = useState([]);
  const [skillWarning, setSkillWarning] = useState(false);
  const [about, setAbout] = useState("");
  const [aboutWarning, setAboutWarning] = useState(false);
  const [startDateValue, setStartDateValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [endDate, setEndDate] = useState("");
  const [experienceCompany, setExperienceCompany] = useState("");
  const [country, setCountry] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // function to get categories details from API
  const getAllSkills = async () => {
    const requestURL = url.API_BaseUrl + url.API_ALL_SKILLS + `?token=${token}`;

    // console.log("skills options url----->",requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("Skills response----->",response);

      if (response.status) {
        if (response.data) {
          setUserSkillList(response.data.options);
        }
      }
    } catch (error) {}
  };

  // start date processing handler
  const startDateChangeHandler = (e, value) => {
    setStartDateValue(value);
    e.preventDefault();
    let formattedDate = value.split("-");
    let day = formattedDate[2];
    let month = formattedDate[1];
    let year = formattedDate[0];
    setStartDate(`${day}/${month}/${year}`);
  };

  // end date processing handler
  const endDateChangeHandler = (e, value) => {
    setEndDateValue(value);
    e.preventDefault();
    let formattedDate = value.split("-");
    let day = formattedDate[2];
    let month = formattedDate[1];
    let year = formattedDate[0];
    setEndDate(`${day}/${month}/${year}`);
  };

  /* ------------- Single Select requirements starts here ------------- */

  // useState to display the selected values
  const [selectedLanguageOption, setSelectedLanguageOption] = useState(null);

  // useState to save the selected values to send to API
  const [selectedLanguageValue, setSelectedLanguageValue] = useState(null);

  // language options
  const languageOptions = [
    {
      id: 1,
      label: "English",
      value: "en",
    },
    {
      id: 2,
      label: "Spanish",
      value: "es",
    },
  ];

  // function to select language
  const languageOptionSelectionHandler = (val) => {
    // console.log(val);
    setSelectedLanguageOption();
    setSelectedLanguageValue(val.value);
  };
  /* ------------- Single Select requirements ends here ------------- */

  /*------- function for skill multi select -------*/
  const skillSelectionHandler = (val) => {
    // console.log(val);
    let skillVal = val.map((item) => {
      return item.id;
    });

    setSelectedSkillValue(val);
    setSkills(skillVal);
  };

  //function for upload image
  const uploadImageHandler = async (e) => {
    setIsUploading(true);
    let imgFile = e.target.files[0];

    try {
      let requestURL =
        url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      console.log("Profile image upload URL ---->", requestURL);

      const response = await uploadFile(requestURL, imgFile);
      console.log("Profile image upload response ---->", response);

      if (response.status) {
        if (response.data && response.data.id) {
          setIsUploading(false);
          setUploadedImageId(response.data.id);
        }
        let previewImage = URL.createObjectURL(imgFile);
        setUploadedImage(previewImage);
      }
    } catch (error) {
      console.log("Profile image upload error ---->", error);
    }
  };

  // function for get champion details
  const getUserDetails = async (id) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_EXPERT_OVERVIEW +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setFirstName(response.data.name);
          setLastName(response.data.surname);
          setPosition(response.data.position);
          setCompany("");
          setLocation(response.data.address);
          setZipcode(response.data.zipcode);
          setCountry(response.data.country);
          if (response.data.language == "en") {
            let langData = {
              id: 1,
              label: "English",
              value: "en",
            };
            setSelectedLanguageOption(langData);
          } else {
            let langData = {
              id: 2,
              label: "Spanish",
              value: "es",
            };
            setSelectedLanguageOption(langData);
          }
          setSelectedLanguageValue(response.data.language);
          setAbout(response.data.about);
          setSelectedSkillValue(response.data.skills);
          skillSelectionHandler(response.data.skills);
        }
      }
    } catch (error) {
      // console.log("Expert Details banner error --->", error);
    }
  };

  // function to handler form submit
  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (firstName == "" || skills.length == 0 || about == "") {
      if (firstName == "") {
        setFirstNameWarning(true);
      } else {
        setFirstNameWarning(false);
      }

      if (skills.length == 0) {
        setSkillWarning(true);
      } else {
        setSkillWarning(false);
      }

      if (about == "") {
        setAboutWarning(true);
      } else {
        setAboutWarning(false);
      }
    } else {
      setFirstNameWarning(false);
      setSkillWarning(false);
      setAboutWarning(false);
      setIsSubmitting(true);

      let requestURL =
        url.API_BaseUrl + url.API_UPDATE_USERINFO + `?token=${token}`;

      console.log("request url---->", requestURL);

      let profileFormData = {
        imageid: uploadedImageId || userInfo.imageid,
        fname: firstName,
        lname: lastName,
        position: position,
        company: company,
        address: location,
        zipcode: zipcode,
        skill: skills,
        about: about,
        country: country,
        lang: selectedLanguageValue,
      };

      console.log("Profile form data---->", profileFormData);

      try {
        const response = await postData(requestURL, profileFormData);
        if (response.status) {
          setIsSubmitting(false);
          console.log("Profile submit response ----->", response);
          let myModal = new bootstrap.Modal(
            document.getElementById("profile_updatemdl")
          );
          myModal.show();
          // getUserDetails(userInfo.id);
          // resetHandler();
        }
      } catch (error) {
        console.log("Profile form error ----->", error);
      }
    }
  };

  //function for after modal close
  const afterModalClose = () => {
    getUserDetails(userInfo.id);
  };

  // function to reset all the values after submission
  const resetHandler = () => {
    setFirstName("");
    setLastName("");
    setPosition("");
    setCompany("");
    setLocation("");
    setZipcode("");
    setSelectedSkillValue(null);
    setSkills([]);
    setAbout("");
    setCountry("");
    setSelectedLanguageOption(null);
  };

  useEffect(() => {
    getAllSkills();
    getUserDetails(userInfo.id);
  }, []);

  // return statement starts here
  return (
    <section className="user_profileArea">
      <div className="container">
        {/* ----------- Image uploader section starts here ----------- */}
        <div className="user_profile_pic">
          <img src={uploadedImage} alt="" />

          {/* --------- file uploader starts here --------- */}
          <div className="image_uploadBtn" style={{ cursor: "pointer" }}>
            <input
              type="file"
              className="file_btn"
              onChange={uploadImageHandler}
            />
            <span
              className="material-icons-outlined"
              style={{ cursor: "pointer" }}
            >
              photo_camera
            </span>
          </div>
          {isUploading && (
            <div className="profile_loader">
              <div className="lds-roller">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
        </div>

        {/* ----------- Form section starts here ----------- */}
        <div className="dashboard_pmlOuter">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard_leftpnl">
                <div className="row">
                  {/* ----------- First name form field ----------- */}
                  <div className="col-lg-6">
                    <div className="input_fill jobinput_fill">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        value={firstName}
                        onChange={(e) => {
                          setFirstNameWarning(false);
                          setFirstName(e.target.value);
                        }}
                      />
                      {firstNameWarning && (
                        <p className="error mt-2">* Enter first name</p>
                      )}
                    </div>
                  </div>

                  {/* ----------- Last name form field ----------- */}
                  <div className="col-lg-6">
                    <div className="input_fill jobinput_fill">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* ----------- Position form field ----------- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Position</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Position"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* ----- company name field ----- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Add Company</label>
                      <div className="eye_btnfill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Company"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                        <Link to="#" className="copy_link">
                          <span className="material-icons-outlined">
                            {" "}
                            business
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Add Location form field ----------- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Add Location</label>
                      <div className="eye_btnfill">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                        <Link to="#" className="copy_link">
                          <span className="material-icons-outlined">
                            {" "}
                            place
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Zipcode form field ----------- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Zipcode</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter zipcode"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* ----------- Country form field ----------- */}
                  <div className="col-lg-6">
                    <div className="input_fill jobinput_fill">
                      <label>Country</label>
                      <div className="model_selectBx">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  {/* ----------- Language form field ----------- */}
                  <div className="col-lg-6">
                    <div className="input_fill jobinput_fill">
                      <label>Language</label>
                      <div className="model_selectBx">
                        <Select
                          isMulti={false}
                          placeholder="Select Language"
                          options={languageOptions} // show all options
                          value={selectedLanguageOption} // show selected values
                          onChange={(val) => {
                            languageOptionSelectionHandler(val);
                          }} // handler function
                          classNamePrefix="select2-selection nosearch_select"
                          styles={customSelectStyle}
                          // onKeyDown={searchByEnterSelect}
                          menuPlacement="top"
                        />
                      </div>
                    </div>
                  </div>

                  {/* ----------- Top Skills form field ----------- */}
                  {/* <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Top Education</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Education"
                        value={topEducation}
                        onChange={(e) => setTopEducation(e.target.value)}
                      />
                    </div>
                  </div> */}

                  {/* ----------- About (CkEditor) form field ----------- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill description_fill">
                      <label>About</label>
                      <CKEditor
                        className="form-control"
                        name="about"
                        id="about"
                        editor={ClassicEditor}
                        config={editorConfig1}
                        data={about ? about : ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          // console.log("Editor is ready to use!", editor)
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "200px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                          // editor.editing.view.change((writer) => {
                          //   writer.setStyle(
                          //     "border-radius",
                          //     "20px",
                          //     editor.editing.view.document.getRoot()
                          //   );
                          // });
                        }}
                        onChange={(event, editor) => {
                          let editContent = editor.getData();
                          //console.log(JSON.stringify(editContent));
                          setAboutWarning(false);
                          setAbout(editContent);
                        }}
                      />
                      {aboutWarning && (
                        <p className="error mt-2">* Enter your about please</p>
                      )}
                    </div>
                  </div>

                  {/* ----------- Skill form field ----------- */}
                  <div className="col-lg-12">
                    <div className="input_fill jobinput_fill">
                      <label>Skill</label>
                      <Select
                        isMulti
                        placeholder="Select skills"
                        options={userSkillList} // show all options
                        value={selectedSkillValue} // show selected values
                        onChange={(val) => {
                          skillSelectionHandler(val);
                        }} // handler function
                        classNamePrefix="select2-selection nosearch_select"
                        styles={customSelectStyle}
                        // onKeyDown={searchByEnterSelect}
                        menuPlacement="top"
                      />
                      {skillWarning && (
                        <p className="error mt-2">* Select skills</p>
                      )}
                    </div>
                  </div>

                  {/* ----------- Experience, position & dates form field ----------- */}
                  <div className="col-lg-12">
                    {/* <div className="input_fill jobinput_fill">
                      <label>Experience</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                      />
                    </div> */}

                    <div className="row">
                      {/* ---- position area start ----- */}
                      {/* <div className="col-lg-12">
                        <div className="input_fill jobinput_fill">
                          <label>Position</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Position"
                            value={experienceCompany}
                            onChange={(e) =>
                              setExperienceCompany(e.target.value)
                            }
                          />
                        </div>
                      </div> */}
                      {/* ---- position area end ----- */}

                      {/* -------- Date components here --------  */}
                      <div className="col-lg-6">
                        <div className="row">
                          {/* -------- Start date -------- */}
                          {/* <div className="col-lg-6">
                            <div className="input_fill jobinput_fill">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Starting Date"
                                value={startDateValue}
                                onChange={(e) =>
                                  startDateChangeHandler(e, e.target.value)
                                }
                              />
                            </div>
                          </div> */}

                          {/* -------- End date -------- */}
                          {/* <div className="col-lg-6">
                            <div className="input_fill jobinput_fill">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="End Date"
                                value={endDateValue}
                                onChange={(e) =>
                                  endDateChangeHandler(e, e.target.value)
                                }
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ----------- Submit button for form ----------- */}
                  <div className="col-lg-12">
                    <div className="dashboard_submit_btn">
                      <button
                        type="button"
                        className="basic_btn ylw_btn"
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                        onClick={(e) => formSubmitHandler(e)}
                      >
                        Submit
                        {isSubmitting && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProfileUpdateSuccessModal afterModalClose={afterModalClose} />
    </section>
  );
};

export default ProfileArea;
