/* eslint-disable */
import React from "react";

// importing custom components here
import ExpertDetailsLeftPanel from "./expertdetailsleftpanel/ExpertDetailsLeftPanel";
import ExpertDetailsRightPanel from "./expertdetailsrightpanel/ExpertDetailsRightPanel";

// importing modals here

const ExpertDetailsArea = () => {
  // return statement starts here
  return (
    <div className="row">
      {/* ---------------- Left side components starts here ---------------- */}
      <ExpertDetailsLeftPanel />
      {/* ---------------- Left side components ends here ---------------- */}

      {/* --------- Right side 'similar profiles' components starts here --------- */}
      <ExpertDetailsRightPanel />
      {/* --------- Right side 'similar profiles' components ends here --------- */}
    </div>
  );
};

export default ExpertDetailsArea;
