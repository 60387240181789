/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// importing images
import { assetImages } from "../../../../../constants";

// importing review modal here
import ReviewModal from "../modals/ReviewModal";

// import Gateway for API fetch call
import { getData } from "../../../../../utils/Gateway";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

const ReviewTab = () => {
  const { reloadExpertReview, setReloadExpertReview, isExpertConnected } =
    useContext(GlobalFindelOiProvider);

  const glowReviewCount = [1, 2];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // Function to get all the review data from API
  const getReviewsLists = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_EXPERT_REVIEW +
      `?token=${token}` +
      `&mod=MOD_USER` +
      `&com=${id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setReviewsList(response.data);
        } else {
          setReviewsList([]);
        }
        setIsLoading(false);
        // console.log("Expert Details Review ---->", response.data);
      }
    } catch (error) {
      // console.log("Expert Details Review error ---->", error);
    }
  };

  //use effect for refresh review tab after close review modal
  useEffect(() => {
    if (reloadExpertReview) {
      getReviewsLists(params.id);
      setReloadExpertReview(false);
    }
  }, [reloadExpertReview]);

  // useEffect starts here
  useEffect(() => {
    getReviewsLists(params.id);
  }, [params.id]);

  // return statement starts here
  return (
    <div className="tab-pane fade show active" id="feedbacks">
      {/* --------- Glow placeholder section starts here --------- */}
      {isLoading ? (
        <div className="job_detailspnl">
          <div className="contnt_hdng">
            <h4>
              <span className="placeholder" style={{ width: "100px" }}></span>
            </h4>
            <span
              className="placeholder rounded-2"
              style={{ width: "130px", height: "46px" }}
            ></span>
          </div>
          <div className="customer_feedbacks">
            {glowReviewCount.map((count) => {
              return (
                <div className="recomend_row" key={count}>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      ></span>
                    </div>
                    <div className="team_usrTxt w-100">
                      <h3>
                        <span className="placeholder col-2"></span>
                      </h3>
                      <h5>
                        <span className="placeholder col-1"></span>
                      </h5>
                      <div className="rating_star">
                        <ul className="list_stye_none">
                          <li className="active">
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li className="active">
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="forum_para">
                        <p>
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-10"></span>
                          <span className="placeholder col-8"></span>
                        </p>
                      </div>
                      <div className="feedback_share">
                        <ul className="list_stye_none">
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="post_time">
                        <span
                          className="placeholder"
                          style={{ width: "80px", height: "14px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="job_detailspnl">
          {/* ----------- 'Add Review' button section starts here ----------- */}
          <div className="contnt_hdng">
            <h4>Feedbacks</h4>
            {isExpertConnected == false ? null : (
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#addreview-modal"
                className="border_btn"
              >
                Add a Review
              </Link>
            )}
          </div>
          {/* ----------- 'Add Review' button section ends here ----------- */}

          {/* ----------- Submitted reviews section starts here ----------- */}
          <div className="customer_feedbacks">
            {/* ----------- Review card starts here ----------- */}
            {reviewsList.length === 0 ? (
              <div className="empty_lessonsArea">
                <div className="empty_lessonpic">
                  <img src={assetImages.emptyLesson} alt="blanklesson" />
                </div>
                <div className="empty_lessons_text">
                  <h2>Sorry....!</h2>
                  <p>No reviews available for this expert.</p>
                </div>
              </div>
            ) : (
              <div className="recomend_row">
                {reviewsList.map((review, index) => {
                  return (
                    <div className="team_userOuter forum_usrOuter" key={index}>
                      {/* ------ Reviewer image section here ------ */}
                      <div className="team_usr_pic">
                        <img
                          src={
                            review.userimage != null
                              ? review.userimage
                              : assetImages.defaultUser
                          }
                          alt="UserProfilePic"
                        />
                      </div>
                      <div className="team_usrTxt">
                        <h3>{review.user}</h3>
                        <h5>{review.useremail}</h5>

                        {/* ------ Reviewer star rating section here ------ */}
                        <div className="rating_star">
                          <ul className="list_stye_none">
                            {_.times(review.globalrating, (i) => (
                              <li className="active" key={i}>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* ------ Reviewer comment section here ------ */}
                        <div className="forum_para">
                          <p>{review.comment}</p>
                        </div>

                        {/* ------ Buttons section here ------ */}
                        <div className="feedback_share">
                          <ul className="list_stye_none">
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  thumb_up
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">chat</i>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">share</i>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        {/* ------ Review date section here ------ */}
                        <div className="post_time">
                          <span>{review.created}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* ----------- Review card ends here ----------- */}
          </div>
        </div>
      )}

      {/* ------------ 'Review' modal implemented here ------------ */}
      <ReviewModal />
    </div>
  );
};

export default ReviewTab;
