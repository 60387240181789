import React from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../../../constants";

const ThankJoinEventModal = (props) => {
  return (
    <div className="conatct_modal success_popup">
      <div
        data-bs-backdrop="static"
        className="modal fade"
        id="thanks_event_join_mdl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success_graphics">
                <img src={assetImages.eventCongrats} alt="" />
              </div>
              <div className="success_text">
                <h3>Thank you!</h3>
                <p>For joining this event</p>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn"
                  onClick={props.afterThanksModalClose}
                >
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankJoinEventModal;
