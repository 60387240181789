/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../constants";

const ProfilePicModal = () => {
  const { uploadedImgUrl } = useContext(GlobalFindelOiProvider);

  return (
    <div className="registration_modal profile_pic_modal">
      <div
        className="modal fade show"
        id="profile_modal"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog medium_modal">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              {/* ---------- Modal back arrow starts here ---------- */}
              <div className="arrw_back">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  data-bs-target="#documets_mdl"
                  data-bs-toggle="modal"
                >
                  <span className="material-icons-outlined">arrow_back</span>
                </Link>
              </div>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal Body starts here ----------- */}
            <div className="modal-body">
              <div className="login_body">
                <form>
                  <div className="profile_pic">
                    <img src={uploadedImgUrl} alt="" />
                  </div>
                  <div className="loginstep_btnsouter">
                    <div className="left_btn">
                      <Link to="#" className="btn gray_btn">
                        Upload
                      </Link>
                    </div>
                    <div className="rotate">
                      <ul className="list_stye_none">
                        <li>
                          <Link to="#">
                            <img src={assetImages.rotate1} alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <img src={assetImages.rotate2} alt="" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="right_btn">
                      <Link
                        to="#"
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#interests_mdl"
                        data-bs-dismiss="modal"
                      >
                        Save
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* ----------- Modal Body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicModal;
