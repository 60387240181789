/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

import * as url from "../../urlhelpers/UrlHelper";

// importing custom components here
import ApplicationMenu from "./ApplicationMenu";
import LoggedInUserMenu from "./LoggedInUserMenu";

const Header = (props) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    // --------------------- Header starts here ---------------------
    <>
      <header className="header_outer">
        <div className="container">
          <div className="header_innr">
            {/* ------------ Hamburger & Logo here ------------ */}
            <div className="logobar_outer">
              {/* ------------ Hamburger Logo here ------------ */}
              <div className="menu_bar">
                <Link
                  to="#"
                  onClick={() => document.body.classList.add("menu_open")}
                >
                  <span className="material-icons-outlined"> menu </span>
                </Link>
              </div>

              {/* ------------ Logo here ------------ */}
              <div className="hdr_logo">
                <Link className="navbar-brand" to="/">
                  <img src={assetImages.homeLogo} alt="brand_logo" />
                </Link>
              </div>
            </div>

            {/* ------------ Search Icon here ------------ */}
            <div className="srch_icon">
              <button>
                <i className="material-icons-outlined"> search </i>
              </button>
            </div>

            {/* ------------ Search Bar here ------------ */}
            <div className="hdr_srchbar">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <input type="submit" className="submit_btn" />
            </div>

            {/* ------------ LoggedIn user section here ------------ */}
            <LoggedInUserMenu />
          </div>

          {/* ------------ Application Menu Toggle Section here ------------ */}
          <ApplicationMenu activePage={props.activePage} />
        </div>
        <div
          className="overlay"
          onClick={() => {
            document.body.classList.remove("menu_open");
          }}
        ></div>
      </header>
    </>
  );
};

export default Header;
