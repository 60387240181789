/*=========== general URL start here ============*/

// export const API_BaseUrl = "https://admindev.atmindra.lobees.com/app/api"; //Base url for indra
export const API_BaseUrl = "https://admin.findeloi.com/app/api";

export const API_BACKEND_URL = "https://admin.findeloi.com/login/oauth"; // Backend url

/*=========== all Demo Data API URL start here ===========*/
export const DEMO_DATA = "/demodata"; // for get demo data
export const DEMO_POST = "/test/post"; // for testing post data
export const DEMO_SELECTIVE_GET = "/single/demodata/"; // for get a single demo data
export const DEMO_URL_GET = "api/test/get";
export const DEMO_FILE_UPLOAD = "/test/formpost"; // Only for file upload (DEMO)
/*=========== all Demo Data API URL end here ===========*/

// URL to upload Image in Text Editor (AskQuestions.js) component.
export const URL_TEXT_EDITOR_IMAGE_UPLOAD =
  "https://dev.lobees.com/web/uploads/documents/";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_2 =
  "https://dev.lobees.com/web/uploads/reports/";

export const API_FILE_UPLOAD = "/fileupload/single"; //for single file upload

export const API_FILE_UPLOAD_MULTIPLE = "/fileupload/multiple"; //for multiple file upload

// API Tags (to fetch all the tags and display in AddTagsModal.js)
export const API_ALL_TAGS = "/tags/list";

// API for list of all champion skills
export const API_ALL_SKILLS = "/skills/list";

// API to create new tag
export const API_CREATE_NEW_TAG = "/tag/save";

// API to submit a rating for a course
export const API_REVIEW_RATING = "/feedback/send";

// API to fetch all the reviews for a course
export const API_VIEW_REVIEWS = "/feedbacks/module";

/*--------- blog api start ----------*/
//Api for get blog category
export const API_BLOG_CATEGORY = "/blog/category/list";

//Api for all blog lists
export const API_BLOG_LISTS = "/blogpost/list";

//API for blog details
export const API_BLOG_DETAILS = "/blogpost/details";
/*--------- blog api end ----------*/

/*-------- login and registration url start ----------*/
// API registration
export const API_REGISTARTION = "/auth/register";

// API forgot password
export const API_FORGOT_PASSWORD = "/auth/forgot/password";

// API login url
export const API_LoginUrl = "/auth/user";

// API update userinfo
export const API_UPDATE_USERINFO = "/user/profile/update";

// API validate user
export const API_VALIDATE_USER = "/auth/user/validate";

// url for get logged in user details
export const API_GET_USER_DETAILS = "/userinfo";

// API for contact Us slider
export const API_CONTACT_US = "/contactus/save";

/*-------- login and registration url end ----------*/

/*----- api for activity section start --------*/
// API list all the posts
export const API_ACTIVITIES_LIST = "/activities/list";

// API display post details
export const API_ACTIVITY_DETAILS = "/activities/details/";

// API add top response (winner)
export const API_ACTIVITY_TOP_RESPONSE_WINNER = "/activity/topresponse/";

// API create a new post
export const API_ACTIVITY_CREATE_POST = "/activity/post/add";

// API like the post
export const API_ACTIVITY_LIKE_POST = "/activity/like";

// API like the post comments and answers
export const API_ACTIVITY_LIKE_POST_COMMENT = "/activitycomment/like";

// API answer the question in post
export const API_ACTIVITY_POST_ANSWER = "/activity/comment/";

// API to reply to answer
export const API_ACTIVITY_REPLY_TO_ANSWER = "/activity/comment/";

// API to fetch all the contacts in Filter Modal
export const API_ACTIVITY_FILTER_CONTACTS = "/user/contacts";

// API to fetch all the categories in Filter Modal
export const API_ACTIVITY_FILTER_CATEGORIES = "/categories/activity";

// API to fetch all the groups in Filter Modal
export const API_ACTIVITY_FILTER_GROUPS = "/user/groups";

// API to fetch all the events in Filter Modal
export const API_ACTIVITY_FILTER_EVENTS = "/user/events";

// API Tags (to fetch all the tags)
export const API_ACTIVITY_FILTER_TAGS = "/tags/list";

//API FOR DELETE COMMENTS IN RIGHT PANEL
export const API_ACTIVITY_REMOVE_COMMENTS = "/activity/removecomment";

//API FOR DELETE FORUM/ACTIVITY IN RIGHT PANEL
export const API_ACTIVITY_REMOVE_FORUM = "/activity/disable";

// API for add tag
export const API_ACTIVITY_ADD_TAG = "/activity/addtags";

//API for remove tag
export const API_ACTIVITY_REMOVE_TAG = "/activity/removetags";

export const API_FORUM_LIST = "/activities/listbymodule";
/*----- api for activity section start --------*/

/*---- general URL end here -----*/

/* -------------------------------- Event List API --------------------------------- */
//Api to get all event category
export const API_EVENT_CATEGORY_LISTS = "/event/categories";

// API TO GET LIST OF ALL THE EVENTS
export const API_EVENT_LIST = "/events/list";

// API to GET BANNER DETAILS OF THE EVENT
export const API_EVENT_BANNER = "/event/info";

// API to GET ABOUT TAB DETAILS OF THE EVENT
export const API_EVENT_ABOUT = "/event/about";

// API to GET ABOUT PARTICIPANTS DETAILS OF THE EVENT
export const API_EVENT_PARTICIPANTS = "/event/members";

//API FOR ADD TAG IN EVENT MODAL
export const ADD_TAG_EVENT = "/event/addtags";

//API FOR REMOVE TAG IN EVENT MODAL
export const REMOVE_TAG_EVENT = "/event/removetags";

//API FOR GALLERY LISTS
export const GALLERY_LISTS = "/event/gallery";

// API FOR JOINING THE EVENT
export const API_JOIN_EVENT = "/event/join";

//Api for dropdown options for project ranking
export const API_PROJECT_RANK_OPTIONS = "/event/feedbackrules";

//API for get event project rank report
export const API_GET_PROJECT_RANK_REPORT = "/event/feedbackresponse/report";
/* -------------------------------- Expert List API --------------------------------- */

// API TO GET LIST OF ALL THE EXPERTS
export const API_EXPERT_LIST = "/champions";

// API to GET BANNER DETAILS OF THE EXPERT
export const API_EXPERT_BANNER = "/champion/info";

// API to GET OVERVIEW TAB DETAILS OF THE EXPERT
export const API_EXPERT_OVERVIEW = "/champion/about";

// API to GET REVIEW TAB DETAILS OF THE EXPERT
export const API_EXPERT_REVIEW = "/feedbacks/module";

// API to GET SKILLS OF THE EXPERT
export const API_EXPERT_SKILLS = "/skills/list";

// API TO SUBMIT A RATING FOR AN EXPERT
export const API_EXPERT_RATING = "/feedback/send";

// API TO SEND CONTACT CHAMPION MODAL
export const API_EXPERT_CONTACT = "/champion/connect";

//API TO GET ALL RECIEVED INVITATION
export const API_INVITE_LISTS = "/connect/invitation";

//API TO ACCEPT/REJECT INVITATION
export const API_INVITE_ACTION = "/connect/respond";

/* -------------------------------- Chat API --------------------------------- */
// API contact url for all contacts (used in the StartNewChatModal.js)
export const API_ALL_CONTACTS = "/contacts-basic";

//API for fetch selected contact from url params
export const API_CONTACT_SELECTED = "/contact/selected";

// API conversation url (used in the LeftPanel.js)
export const API_CONTACTS_ADDEDTOCHAT = "/contacts/addedtochat";

// API Post Chat conversation - basic
export const API_CONTACT_CONVERSATION_SAVE = "/conversation/save";

// API Contact Conversation (to display all the conversations of a contact in ContactRightPanel.js)
export const API_CONTACT_CONVERSATION = "/conversation/contact";

// API Groups
export const API_GROUP_SAVE = "/group/save";

// API Tags (to fetch all the tags and display in AddTagsModal.js)
export const API_CONVERSATION_TAGS = "/tags/list";

// API Associated Groups (to fetch the all the groups that the member is associated with)
export const API_ASSOCIATED_GROUPS = "/groups";

// API send to Group chat (to send chat in seleceted group)
export const API_SEND_MESSAGE_TO_GROUP = "/groupconversation/save";

// API Group Conversation (to display all the conversations of a group in GroupRightPanel.js)
export const API_GROUP_CONVERSATION = "/conversation/group";

// API Post group conversation
export const API_GROUP_CONVERSATION_SAVE = "/groupconversation/save";

// API Message send
export const API_MAIL_SEND = "/quickmail/save";

// mail forward
export const API_FORWARD_MAIL = "/mail/forward";

// API Mail fetch
export const API_FETCH_ALL_CONTACT_EMAILS = "/mails/contact";

// API Group Chat Likes
export const API_GROUP_CHAT_LIKE = "/conversation/like";

// API to Forward conversation
export const API_FORWARD_MESSAGE = "/conversation/forward";

// API to create new tag
// export const API_CREATE_NEW_TAG = "/tag/save";

// API to Save Chat Tag
export const API_SAVE_CHAT_TAGS = "/conversation/savetags";

// API to remove Chat Tag
export const API_REMOVE_CHAT_TAGS = "/conversation/tags/remove";

// API to add tag to contact
export const API_ADD_TAG_CONTACT = "/contact/addtags";

// API to remove tag from contact
export const API_REMOVE_TAG_CONTACT = "/contact/removetags";

//API to add tag to group
export const API_ADD_TAG_GROUP = "/group/addtags";

//API to remove tag from group
export const API_REMOVE_TAG_GROUP = "/group/removetags";

//API for add tag for mail
export const API_ADD_TAG_MAIL = "/message/addtags";

//API for remove tag from mail
export const API_REMOVE_TAG_MAIL = "/message/removetags";

// API for view tag in mail
export const API_VIEW_MAIL_TAG = "/mail/view";

// API to get & view email details
export const API_VIEW_EMAIL_DETAILS = "/mail/view";

// API for group leave
export const API_GROUP_LEAVE = "/group/leave";

// API for group delete
export const API_GROUP_DELETE = "/group/delete";

// API for champion
export const API_GROUP_CHAMPION = "/conversation/topresponse";

/* -------------------------------- Feedback API --------------------------------- */
// API TO GET LIST OF ALL THE QUESTIONS
export const API_FEEDBACK_QUESTIONS = "/survey/feedback/response";

export const API_EVENT_PARTICIPANTS_FEEDBACK_QUESTIONS =
  "/survey/event/feedback";

// API TO SAVE SURVEY RESPONSES
export const API_SURVEY_FEEDBACK_SAVE = "/survey/feedback/save";
