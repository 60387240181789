/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

// import Gateway for API fetch call
import {
  getData,
  postData,
  postFormdata,
} from "../../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../../urlhelpers/UrlHelper";
/* ----------------------------------------------------------------------------------- */

// importing modal here
import ThankReviewModal from "./ThankReviewModal";

const ReviewModal = () => {
  let token = "aW5kcmE6MTY3NDAzNjkyNQ==";

  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState(1);

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setActiveFirstStar(true);
      setActiveSecondStar(false);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 2) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 3) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 4) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(false);
    }

    if (value == 5) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };
  /* ------------ Initial useStates for activating the starts ends here ------------ */

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      setIsSubmitting(true);
      let requestURL =
        url.API_BaseUrl + url.API_REVIEW_RATING + `?token=${token}`;

      let reviewData = {
        mod: "MOD_EVENT",
        com: params.id,
        comments: comments,
        ratings: starRating,
      };

      try {
        const response = await postFormdata(requestURL, reviewData);

        if (response.status) {
          setIsSubmitting(false);
          resetHandler();
          let reviewModal = document.querySelector("#addreview-modal");
          let modal = bootstrap.Modal.getInstance(reviewModal);
          modal.hide();
          let myModal = new bootstrap.Modal(
            document.getElementById("review_success_mdl")
          );
          myModal.show();
        }
      } catch (error) {}
    }
  };

  // function for calling API after closing thank you modal
  // const afterThanksReviewClose = () => {
  //   getReviewsHandler(params.id);
  //   // setAfterReviewEvent(true);
  // };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    setstarRating(star);
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
  };

  return (
    <div className="job_modal feedback_modal review_modal">
      <div
        data-bs-backdrop="static"
        className="modal fade"
        id="addreview-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header">
              <div className="modal_hdng">
                <h3>Add a Review</h3>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="form_commnts_area commnts_newbx">
                <div className="course_rvw_row">
                  <div className="rating_hdng">
                    <h5>Give Ratings</h5>
                  </div>
                  <ul className="list_stye_none">
                    {/* ----------- 1 Star ----------- */}

                    <li
                      className={activeFirstStar == true ? "active_star" : ""}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          selectedStar(e, 1);
                          highlightStars(e, 1);
                        }}
                      >
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 2 Star ----------- */}
                    <li
                      className={activeSecondStar == true ? "active_star" : ""}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          selectedStar(e, 2);
                          highlightStars(e, 2);
                        }}
                      >
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 3 Star ----------- */}
                    <li
                      className={activeThirdStar == true ? "active_star" : ""}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          selectedStar(e, 3);
                          highlightStars(e, 3);
                        }}
                      >
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 4 Star ----------- */}
                    <li
                      className={activeFourthStar == true ? "active_star" : ""}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          selectedStar(e, 4);
                          highlightStars(e, 4);
                        }}
                      >
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 5 Star ----------- */}
                    <li className={activeFiveStar == true ? "active_star" : ""}>
                      <Link
                        to="#"
                        onClick={(e) => {
                          selectedStar(e, 5);
                          highlightStars(e, 5);
                        }}
                      >
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="commnts_send_innr">
                  <div className="rating_hdng">
                    <h5>Give Ratings</h5>
                  </div>
                  <div className="commnts_sendArea">
                    <textarea
                      className="form-control"
                      placeholder="Write your query here"
                      value={comments != "" ? comments : ""}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      {/* <button className="cmnt_btn">
                        <span className="material-icons-outlined">image</span>
                      </button> */}
                      <button
                        className="cmnt_btn"
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                        onClick={(e) => reviewSubmitHandler(e)}
                        // data-bs-target="#review_success_mdl"
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------- 'Congratulations' modal implemented here -------------- */}
      <ThankReviewModal />
    </div>
  );
};

export default ReviewModal;
