/* eslint-disable */
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const BlogSearchTab = () => {
  const { setBlogSearchQuery, setblogSearchCategory } = useContext(
    GlobalFindelOiProvider
  );

  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState("");
  // function for get all category for blog
  const getCategoryLists = async () => {
    let requestURL = url.API_BaseUrl + url.API_BLOG_CATEGORY;

    let response = await getData(requestURL);

    console.log(response);

    if (response.status) {
      setCategories(response.data);
    } else {
      setCategories([]);
    }
  };

  // function for search by query
  const searchByQueryHandler = () => {
    setBlogSearchQuery("");
    setblogSearchCategory(null);
    if (query != "") {
      setBlogSearchQuery(query);
      setQuery("");
    }
  };

  // function for search by enter key
  const searchByEnter = (event) => {
    if (event.key === "Enter") {
      searchByQueryHandler();
    }
  };

  //function for search by category
  const searchByCategory = (category) => {
    setBlogSearchQuery("");
    setblogSearchCategory(null);
    if (category) {
      setblogSearchCategory(category);
    }
  };

  useEffect(() => {
    getCategoryLists();
  }, []);

  return (
    <>
      {/* <div className="lobess_pagelink">
        <ul className="list_stye_none">
          <li className="">
            <Link href="experts.html">Experts</Link>
          </li>
          <li>
            <Link href="events.html">Events</Link>
          </li>
          <li className="active">
            <Link href="blog.html">Blog</Link>
          </li>
        </ul>
      </div> */}
      <div className="jobsrch_slctArea">
        {/* ----- query section start ----- */}
        <div className="tab_formOuter">
          <div className="tab_fill blogsrch_fill w_100">
            <input
              type="text"
              className="form-control"
              placeholder="Search for Blogs"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onKeyDown={searchByEnter}
            />
          </div>
          <div className="submit_fill">
            <button
              type="button"
              className="submit_btn form_submit"
              onClick={searchByQueryHandler}
            >
              <i className="material-icons-round">search</i>
              <span>Search</span>
            </button>
          </div>
        </div>
        {/* ----- query section end ----- */}
        {/* <div className="expert_slctOuter">
          <div className="job_slct_row expertslct_row">
            <div className="job_slctItem">
              <div className="model_selectBx">
                <select className="nosearch_select" title="Experience">
                  <option>Experience</option>
                  <option>2 Years</option>
                  <option>4 Years</option>
                </select>
              </div>
            </div>
            <div className="job_slctItem">
              <div className="model_selectBx">
                <select className="nosearch_select" title="Rates">
                  <option>Lorem Ipsum</option>
                  <option>Lorem Ipsum</option>
                  <option>Lorem Ipsum</option>
                </select>
              </div>
            </div>
          </div>
          <div className="exprt_filter_btn">
            <Link to="#">
              <span className="material-icons-round">filter_alt</span>
            </Link>
          </div>
          <div className="expertrght_icon">
            <ul className="list_stye_none">
              <li className="active">
                <Link to="#" className="grid_icon">
                  <i className="material-icons-outlined">window</i>
                </Link>
              </li>
              <li>
                <Link to="#" className="list_icon">
                  <i className="material-icons-outlined">view_agenda</i>
                </Link>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <div className="filter_skillpnl">
        <div className="filters_exprtHdng">
          <Link to="#" className="filter_back">
            <i className="material-icons-outlined">arrow_back </i>
          </Link>
          <h3>Filters</h3>
        </div>
        {/* <div className="job_slct_row expertslct_row">
          <div className="job_slctItem">
            <div className="model_selectBx">
              <select className="nosearch_select" title="Experience">
                <option>Experience</option>
                <option>2 Years</option>
                <option>4 Years</option>
              </select>
            </div>
          </div>
          <div className="job_slctItem">
            <div className="model_selectBx">
              <select className="nosearch_select" title="Rates">
                <option>Lorem Ipsum</option>
                <option>Lorem Ipsum</option>
                <option>Lorem Ipsum</option>
              </select>
            </div>
          </div>
        </div> */}
        <div className="skill_menu_outer">
          <label>Popular Categories</label>
          <div className="skill_menu">
            {categories.length === 0 ? null : (
              <ul className="list_stye_none">
                {categories.map((category, index) => {
                  return (
                    <li key={index}>
                      <Link
                        to="#"
                        onClick={() => {
                          searchByCategory(category);
                        }}
                      >
                        {category.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {categories.length === 0 ? null : (
            <div className="more_skill">
              <Link to="#">
                More <i className="material-icons-outlined"> more_vert </i>
              </Link>
            </div>
          )}
        </div>
        {/* <div className="hire_meBtn">
          <Link to="#" className="basic_btn">
            Hire Me
          </Link>
        </div> */}
      </div>
    </>
  );
};

export default BlogSearchTab;
