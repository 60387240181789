/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing images here.

const RightProfilePanel = (props) => {
  // get loggedin User information from local storage
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <>
      {props.contactInfo == undefined ? null : (
        <div className="profile_pnlArea">
          {/* ---------- Profile heading section ---------- */}
          <div className="profile_hdngTop">
            <h3>Profile</h3>
            <span
              className="profile_crossbtn"
              onClick={() => {
                document.body.classList.toggle("profile_menuPnl_open");
              }}
            >
              <i className="material-icons-round"> close </i>
            </span>
          </div>
          {/* ---------- end of Profile heading section ---------- */}

          {/* ---------- Profile body section ---------- */}
          <div className="profile_innr_body">
            <div className="profile_innrScroll">
              {/* ---------- User Image && name section ---------- */}
              {props.contactInfo == null ||
              props.contactInfo == undefined ? null : (
                <div className="profile_info_row">
                  <div className="profile_picPnl">
                    <div className="profile_img">
                      <figure>
                        {props.contactInfo.image == null ? (
                          <div className="chatting_user">
                            <span className="material-icons-round">person</span>
                          </div>
                        ) : (
                          <img
                            src={props.contactInfo.image}
                            alt="userProfilePic"
                          />
                        )}
                      </figure>
                    </div>
                    <div className="profile_textPnl">
                      <h4>
                        {props.contactInfo.name != null
                          ? props.contactInfo.name
                          : null}
                        {props.contactInfo.surname != null
                          ? props.contactInfo.surname
                          : null}
                      </h4>
                      <h5>Last Online:{props.contactInfo.lastcommtime}</h5>
                    </div>
                  </div>

                  {props.contactInfo.tags != null ? (
                    <div className="tag_msg">
                      {props.contactInfo.tags.length > 0 ? (
                        <Link to="#">
                          <i className="material-icons">label</i>
                          {props.contactInfo.tags.map((item2, index2) => {
                            return <span key={index2}>{item2.label},</span>;
                          })}
                        </Link>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
              {/* ---------- end of User Image section ---------- */}

              {/* ---------- User email section ---------- */}
              {props.contactInfo == null ? null : (
                <div className="profile_info_row">
                  <div className="profile_details_row">
                    <div className="info_icon">
                      <i className="material-icons-round">email </i>
                      <span>Mail</span>
                    </div>
                    <p>
                      <Link to="#">
                        {props.contactInfo.email != null
                          ? props.contactInfo.email
                          : null}
                      </Link>
                    </p>
                  </div>
                </div>
              )}
              {/* ---------- end of User email section ---------- */}

              {/* ---------- User phone section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">phone_in_talk </i>
                    <span>Phone</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.phone != null
                        ? props.contactInfo.phone
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User phone section ---------- */}

              {/* ---------- User address section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">place </i>
                    <span>Address</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.address != null
                        ? props.contactInfo.address
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User address section ---------- */}

              {/* ---------- User work information section ---------- */}
              <div className="profile_info_row">
                <div className="profile_details_row">
                  <div className="info_icon">
                    <i className="material-icons-round">location_city </i>
                    <span>Position</span>
                  </div>
                  <p>
                    <Link to="#">
                      {props.contactInfo.position != null
                        ? props.contactInfo.position
                        : null}
                    </Link>
                  </p>
                </div>
              </div>
              {/* ---------- end of User work information section ---------- */}

              {/* ---------- end of Signout section ---------- */}
            </div>
          </div>
          {/* ---------- end of Profile heading section ---------- */}
        </div>
      )}
    </>
  );
};

export default RightProfilePanel;
