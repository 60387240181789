import React from "react";

//import images
import { assetImages } from "../../../constants";

const Welcome = () => {
  return (
    <div className="welcome_screnBx">
      <div className="welcome_innrPnl">
        <div className="welcome_graphics">
          <img src={assetImages.welcomeGraphics} alt="welcome" />
        </div>
        <div className="welcome_txtpnl">
          <h2>Welcome to Findeloi Chat</h2>
          <p>
            Start messaging and send email to your connections in a single
            platform
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
