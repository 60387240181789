/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

// import methods from gatway for API call
import { getData } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

//import context
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

//import images
import { assetImages } from "../../../../../constants";
//import tabs
import ProjectRanking from "./participanttabs/ProjectRanking";
import RankingIdea from "./participanttabs/RankingIdea";
import WriteFeedback from "./participanttabs/WriteFeedback";

const EventParticipants = () => {
  const { isEventApproved, isEventModerator } = useContext(
    GlobalFindelOiProvider
  );

  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //custom style for react select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "6px",
      fontFamily: "sans-serif",
      border: "1px solid #dddddd",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 10%)",
      backgroundColor: "#f7f7f7",
      height: 50,
      width: 270,
      borderRadius: "2px",
      "&:hover": {
        border: "1px solid #dddddd",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8EA6 !important",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // selected value
    singleValue: (provided) => ({
      ...provided,
      color: "666666",
      fontSize: "16px",
    }),

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // initial useState for all the tabs
  const [writeFeedbackTab, setWriteFeedbackTab] = useState(false);
  const [projectRankingTab, setProjectRankingTab] = useState(false);
  const [rankingIdeaTab, setRankingIdeaTab] = useState(false);
  const [participantsName, setParticipantsName] = useState("");
  const [projectRankOptions, setProjectRankOptions] = useState([]);
  const [rankValue, setRankValue] = useState(null);
  const [rankSelectedValue, setRankSelectedValue] = useState(null);

  // functions to toggle the tabs
  const toggleWriteFeedbackTab = (e) => {
    e.preventDefault();
    setWriteFeedbackTab(true);
    setProjectRankingTab(false);
    setRankingIdeaTab(false);
  };

  const toggleProjectRankingTab = (e) => {
    e.preventDefault();
    setWriteFeedbackTab(false);
    setProjectRankingTab(true);
    setRankingIdeaTab(false);
  };

  const toggleRankingIdeaTab = (e) => {
    e.preventDefault();
    setWriteFeedbackTab(false);
    setProjectRankingTab(false);
    setRankingIdeaTab(true);
  };

  //function for get all project rank options
  const getAllProjectRankOptions = async () => {
    const requestURL =
      url.API_BaseUrl +
      url.API_PROJECT_RANK_OPTIONS +
      `?id=${params.id}` +
      `&token=${token}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setProjectRankOptions(response.data);
          setRankValue(response.data[0]);
          rankSelectionHandler(response.data[0]);
        }
      }
    } catch (error) {}
  };

  //filter search participants
  const filterParticipantsHandler = (name) => {
    if (writeFeedbackTab) {
      setParticipantsName(name);
    }
  };

  // function to select language
  const rankSelectionHandler = (val) => {
    setRankValue(val);
    setRankSelectedValue(val.value);
  };

  useEffect(() => {
    setWriteFeedbackTab(true);
    getAllProjectRankOptions();
  }, []);

  // return statment from here
  return (
    <div className="tab-pane fade show active" id="evnt_participnt">
      {isEventApproved || isEventModerator || userInfo.isadmin ? (
        <div className="job_detailspnl">
          <div className="participantHdng_outer">
            <div className="contnt_hdng">
              <h4>Participants</h4>
            </div>
            {writeFeedbackTab ? (
              <div className="hdr_srchbar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={participantsName}
                  onChange={(e) => {
                    filterParticipantsHandler(e.target.value);
                  }}
                />
                <input type="submit" className="submit_btn" />
              </div>
            ) : (
              <div>
                {isEventModerator || userInfo.isadmin ? (
                  <Select
                    placeholder="Select Category"
                    options={projectRankOptions} // show all options
                    classNamePrefix="select2-selection nosearch_select"
                    styles={customSelectStyle}
                    menuPlacement="top"
                    value={rankValue} // show selected values
                    onChange={(val) => {
                      rankSelectionHandler(val);
                    }}
                  />
                ) : null}
              </div>
            )}
          </div>

          <div className="inner_tab_links">
            <ul className="list_stye_none">
              {/* --------- Write Feedback Tab Link --------- */}
              <li className={writeFeedbackTab == true ? "active" : ""}>
                <Link to="#" onClick={(e) => toggleWriteFeedbackTab(e)}>
                  <i className="material-icons-outlined">rate_review</i>
                  <span>
                    {" "}
                    <em>Write</em> Feedback
                  </span>
                </Link>
              </li>

              {/* --------- Project Ranking Tab Link --------- */}
              {isEventModerator || userInfo.isadmin ? (
                <li className={projectRankingTab == true ? "active" : ""}>
                  <Link to="#" onClick={(e) => toggleProjectRankingTab(e)}>
                    <i className="material-icons-outlined">fact_check</i>
                    <span>
                      {" "}
                      <em>Project</em> Ranking
                    </span>
                  </Link>
                </li>
              ) : null}

              {/* --------- Ranking Idea Tab Link --------- */}
              {/* {isEventModerator || userInfo.isadmin ? (
                <li className={rankingIdeaTab == true ? "active" : ""}>
                  <Link to="#" onClick={(e) => toggleRankingIdeaTab(e)}>
                    <i className="material-icons-outlined">lightbulb</i>
                    <span>
                      <em>Ranking of</em> Ideas
                    </span>
                  </Link>
                </li>
              ) : null} */}
            </ul>
          </div>

          <div className="inner_tabcontent">
            {/* Write Feedback */}
            {writeFeedbackTab && (
              <WriteFeedback participantsName={participantsName} />
            )}

            {/* ProjectRanking */}
            {projectRankingTab && (
              <ProjectRanking rankSelectedValue={rankSelectedValue} />
            )}

            {/* Ranking Idea */}
            {rankingIdeaTab && <RankingIdea />}
          </div>
        </div>
      ) : (
        <div className="job_detailspnl">
          <div className="participantHdng_outer">
            <div className="contnt_hdng">
              <h4>Participants</h4>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={assetImages.emptyLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>Sorry....!</h2>
                <p className="mx-2">You dont have permission</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventParticipants;
