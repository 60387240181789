/* eslint-disable */
import { useState, createContext } from "react";

export const GlobalFindelOiProvider = createContext();

const FindeloiContext = (props) => {
  /*============ signup requirements start =============*/
  const [userSignUpEmail, setUserSignUpEmail] = useState("");
  const [userRegisterData, setUserRegisterData] = useState(null);
  const [uploadedImgUrl, setUploadedImgUrl] = useState("");
  const [confirmOtpCode, setConfirmOtpCode] = useState(0); // temporary otp basis
  /*============ signup requirements end =============*/

  /*============ blog requirements start =============*/
  const [blogSearchQuery, setBlogSearchQuery] = useState("");
  const [blogSearchCategory, setblogSearchCategory] = useState(null);
  /*============ blog requirements end =============*/

  /*============ Header requirements start =============*/
  const [loadUserLoggedInMenu, setLoadUserLoggedInMenu] = useState(false);
  /*============ Header requirements end =============*/

  /* ==================== events use states requirement start ===================== */
  const [getEventName, setGetEventName] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventCategory, setEventCategory] = useState([]);
  const [eventCity, setEventCity] = useState("");
  const [eventFilterValue, setEventFilterValue] = useState(null);
  const [isEventApproved, setIsEventApproved] = useState(false);
  const [relaodEventTopSection, setRelaodEventTopSection] = useState(false);
  const [reloadReviewTab, setReloadReviewTab] = useState(false);
  const [isEventModerator, setIsEventModerator] = useState(false);
  /* ==================== events use states requirement end ===================== */

  /* ==================== experts use states requirement start ===================== */
  const [searchExpertName, setSearchExpertName] = useState("");
  const [searchExpertLocation, setSearchExpertLocation] = useState("");
  const [searchExpertSkills, setSearchExpertSkills] = useState([]);
  const [reloadExpertTop, setReloadExpertTop] = useState(false);
  const [reloadExpertReview, setReloadExpertReview] = useState(false);
  const [isExpertConnected, setIsExpertConnected] = useState(false);
  /* ==================== experts use states requirement end ===================== */

  /* ==================== conversation use states requirement start ===================== */
  const [realoadChatPanel, setRealoadChatPanel] = useState(false); // for reload chat section conversation -->leftpaenl.js
  const [showToastGroupWarning, setShowToastGroupWarning] = useState(false);
  /* ==================== conversation use states requirement end ===================== */

  return (
    <GlobalFindelOiProvider.Provider
      value={{
        /* login and signup requiremnts start */
        userSignUpEmail,
        setUserSignUpEmail,
        userRegisterData,
        setUserRegisterData,
        uploadedImgUrl,
        setUploadedImgUrl,
        confirmOtpCode,
        setConfirmOtpCode,
        /* login and signup requiremnts end */

        /* header requirement start */
        loadUserLoggedInMenu,
        setLoadUserLoggedInMenu,
        /* header requirement end */

        /*------ blog requirement start ------*/
        blogSearchQuery,
        setBlogSearchQuery,
        blogSearchCategory,
        setblogSearchCategory,
        /*------ blog requirement end ------*/

        /*------ event requirement start -----*/
        getEventName,
        setGetEventName,
        eventTitle,
        setEventTitle,
        eventCategory,
        setEventCategory,
        eventCity,
        setEventCity,
        eventFilterValue,
        setEventFilterValue,
        isEventApproved,
        setIsEventApproved,
        relaodEventTopSection,
        setRelaodEventTopSection,
        reloadReviewTab,
        setReloadReviewTab,
        isEventModerator,
        setIsEventModerator,
        /*------ event requirement end -----*/

        /*------ expert requirement start -----*/
        searchExpertName,
        setSearchExpertName,
        searchExpertLocation,
        setSearchExpertLocation,
        searchExpertSkills,
        setSearchExpertSkills,
        reloadExpertReview,
        setReloadExpertReview,
        reloadExpertTop,
        setReloadExpertTop,
        isExpertConnected,
        setIsExpertConnected,

        /*------ expert requirement end -----*/

        /*====== conversation.js requirements start ======*/
        realoadChatPanel,
        setRealoadChatPanel,
        showToastGroupWarning,
        setShowToastGroupWarning,
        /*====== conversation.js requirements end ======*/
      }}
    >
      {props.children}
    </GlobalFindelOiProvider.Provider>
  );
};

export default FindeloiContext;
