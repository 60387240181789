/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";
/* ---------------------------------------------------------------------------------- */

const GroupPeopleModal = (props) => {
  const token = localStorage.getItem("token");

  const [selectedPersons, setSelectedPersons] = useState([]);

  const [groupName, setGroupName] = useState("");

  const [notification, setNotification] = useState("");

  let contacts = props.contacts;

  // useState to hold value from search field
  const [searchName, setSearchName] = useState("");

  // function to search value from JSON
  function filterByName(name) {
    setSearchName(name);
  }

  // select member from contact, for group -----------------------------------------
  const addToGroup = (memberid) => {
    let prevPersonIds = selectedPersons;

    if (prevPersonIds.includes(memberid)) {
      // exist in array, thus remove
      let key = prevPersonIds.indexOf(memberid);
      prevPersonIds.splice(key, 1);
    } else {
      // not exist in array, thus add
      prevPersonIds.push(memberid);
    }

    setSelectedPersons(prevPersonIds);

    // toggle class based on id
    var block = document.getElementById("checkbox-" + memberid);
    block.classList.toggle("blue_bullet");
  };

  //request API to create new group with selected users
  const addToGroupHandler = async () => {
    let groupData = {
      groupName: groupName,
      selectedPersons: selectedPersons,
    };
    setGroupName("");
    // check condition whether selected person more than two or not
    if (selectedPersons.length >= 2 && groupData.groupName != "") {
      setNotification(t("Setting up your group .."));

      let requestURL = url.API_BaseUrl + url.API_GROUP_SAVE + `?token=${token}`;

      try {
        const response = await postData(requestURL, groupData);

        if (response.status) {
          newGroupHandler(response.data);
          props.displayLeftPanel();

          // reset all after the operation
          resetCreateGroup();
        } else {
          setNotification(t("sorry, the request could not be completed"));
        }
      } catch (error) {}

      // reset all after the operation
      resetCreateGroup();
    } else {
      setNotification(t("Add group name and contacts?"));
    }
  };

  /* function for get the new group info */
  const newGroupHandler = (item) => {
    // passing info to parent component through props
    props.chatGroup(item);
  };

  // Reset the forward activity ---------------------------------------------------
  const resetCreateGroup = () => {
    // select the modal to hide
    var forwardModal = document.querySelector("#group_people_mdl");
    var modal = bootstrap.Modal.getInstance(forwardModal);

    // remove selected classes
    var resetBlock = document.getElementsByClassName("contactToGroup");
    for (var i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("blue_bullet");
    }
    // reset selected person useState
    setSelectedPersons([]);
    // reset notification if any
    setNotification("");
    // reset the group name if any
    setGroupName("");

    // hide modal
    modal.hide();
  };

  return (
    <>
      <div className="chat_modalbx">
        <div
          className="modal fade"
          id="group_people_mdl"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          tabIndex="-1"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ---------- Modal Header starts here ---------- */}
              <div className="modal-header mdl_hdng">
                {/* ---------- Modal Body 'group' search bar ---------- */}
                <div className="enter_group_name">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </div>
                {/* ---------- Modal Body search bar ends here ---------- */}
              </div>
              {/* ---------- Modal Header ends here ---------- */}

              {/* ---------- Modal Body starts here ---------- */}
              <div className="modal-body">
                <div className="text-center text-primary text-weight-bold">
                  {notification}
                </div>
                {/* ---------- Modal Body 'people' search bar ---------- */}
                <div className="group_srchfill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select contact to group"
                    value={searchName}
                    onChange={(e) => filterByName(e.target.value)}
                  />
                </div>
                {/* ---------- Modal Body 'people' search bar ends here ---------- */}

                {/* ---------- Group List ---------- */}
                <div className="group_innrScroll">
                  {contacts.map((data) => (
                    <div
                      className={`chat_usrRow ${
                        data.label
                          .toLowerCase()
                          .includes(searchName.toLowerCase())
                          ? ""
                          : " hide "
                      }`}
                      key={data.id}
                    >
                      <Link
                        to="#"
                        key={data.id}
                        onClick={() => {
                          addToGroup(data.uid);
                        }}
                      >
                        <div className="usr_lftPnl">
                          {data.image != null ? (
                            <div className="chatting_user">
                              <img src={data.image} alt="userProfilePic" />
                            </div>
                          ) : (
                            <div className="chatting_user">
                              <span className="material-icons-round">
                                person
                              </span>
                            </div>
                          )}
                          <div className="user_name">
                            <h3>{data.label}</h3>
                          </div>
                        </div>
                        <div
                          className="members_check contactToGroup"
                          id={"checkbox-" + data.uid}
                        >
                          <span></span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                {/* ---------- Group List ends here ---------- */}

                {/* ---------- Button section ---------- */}
                <div className="group_btnOuter">
                  <ul>
                    {/* ----------- Done button starts here ----------- */}
                    <li className="w_100">
                      <Link
                        to="#"
                        className="ornage_btn"
                        //data-bs-dismiss="modal"
                        onClick={addToGroupHandler}
                      >
                        <i className="material-icons-round"> check_circle </i>
                        <span>Done</span>
                      </Link>
                    </li>

                    {/* ----------- Exit button starts here ----------- */}
                    <li className="w_100">
                      <Link
                        to="#"
                        className="btn"
                        data-bs-dismiss="modal"
                        onClick={resetCreateGroup}
                      >
                        <i className="material-icons-round"> close </i>
                        <span>Exit</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* ---------- Button section ends here ---------- */}
              </div>
              {/* ---------- Modal Body ends here ---------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupPeopleModal;
