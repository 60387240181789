/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../constants";

// importing custom components here
import ExpertDetailsLeftTopSection from "./topsection/ExpertDetailsLeftTopSection";
import ExpertDetailsLeftBottomSection from "./bottomsection/ExpertDetailsLeftBottomSection";

// importing modals here

const ExpertDetailsLeftPanel = () => {
  // return statement starts here
  return (
    <div className="col-lg-8">
      <div className="details_leftPnl">
        {/* ---------------- Top section starts here ---------------- */}
        <ExpertDetailsLeftTopSection />

        {/* ---------------- Bottom 'Tab' section starts here ---------------- */}
        <ExpertDetailsLeftBottomSection />
      </div>
    </div>
  );
};

export default ExpertDetailsLeftPanel;
