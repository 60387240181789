/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// importing images
import { assetImages } from "../../../../../constants";

// import Gateway for API fetch call
import { getData } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

const OverviewTab = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  //initial for champion overview data
  const [overviewData, setOverviewData] = useState(null);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  // function for get about details
  const getAboutDetails = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_EXPERT_OVERVIEW +
      `?token=${token}` +
      `&uid=${id}`;

    try {
      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        if (response.data) {
          if (response.data.length == 0) {
            setOverviewData(null);
          } else {
            setOverviewData(response.data);
          }
        } else {
          setOverviewData(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("Champion Details overview error --->", error);
    }
  };

  // useEffect from here
  useEffect(() => {
    getAboutDetails(params.id);
  }, [params.id]);

  // return statement starts here
  return (
    <>
      {isLoading ? (
        <div className="tab-pane fade show active" id="overview">
          {/* ---------- Glow placeholder starts here ---------- */}
          <div className="exprt_overviewOuter">
            <div className="job_detailspnl">
              <div className="contnt_hdng">
                <span
                  className="placeholder rounded-3 col-2"
                  style={{ height: "22px" }}
                ></span>
              </div>
              <div className="results_para">
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
                <p>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-12 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-10 mb-3"></span>
                  <span className="placeholder placeholder-sm d-block rounded-2 col-8"></span>
                </p>
              </div>
            </div>
            <div className="job_detailspnl">
              <div className="contnt_hdng">
                <span className="placeholder placeholder-md rounded-3 col-1"></span>
              </div>
              <div className="experience_pnl">
                <div className="dates_pnlOuter">
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                  </div>
                  <div className="dates_pnlItem experice_item">
                    <span className="dates_nmbr exprince_logo">
                      <span
                        className="placeholder d-block rounded-2"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </span>
                    <div className="experience_hdng">
                      <h3>
                        <span className="placeholder placeholder-md rounded-3 col-2 ms-0"></span>{" "}
                        <span className="placeholder placeholder-sm rounded-3 col-1"></span>
                      </h3>
                      <span
                        className="placeholder placeholder-sm rounded-3"
                        style={{ width: "134px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---------- Glow placeholder ends here ---------- */}
        </div>
      ) : (
        <div className="tab-pane fade show active" id="overview">
          {overviewData == null ||
          overviewData.length == 0 ||
          ((overviewData.experience == null ||
            overviewData.experience == undefined ||
            overviewData.experience.length === 0) &&
            (overviewData.about == null || overviewData.about == undefined) &&
            (overviewData.skills == null ||
              overviewData.skills == undefined ||
              overviewData.skills.length === 0)) ? (
            <div className="exprt_overviewOuter">
              <div className="job_detailspnl">
                <div className="contnt_hdng">
                  <h4>Details</h4>
                </div>
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={assetImages.emptyLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>Sorry....!</h2>
                    <p>No overview available for this expert.</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="exprt_overviewOuter">
              {/* --------- Details section starts here --------- */}
              {overviewData.about == null ||
              overviewData.about == undefined ? null : (
                <div className="job_detailspnl">
                  <div className="contnt_hdng">
                    <h4>Details</h4>
                  </div>
                  <div className="results_para">
                    <p
                      dangerouslySetInnerHTML={{ __html: overviewData.about }}
                    ></p>
                  </div>
                </div>
              )}

              {/* --------- Details section ends here --------- */}

              {/* --------- Experience section starts here --------- */}
              {overviewData.experience == null ||
              overviewData.experience == undefined ||
              overviewData.experience.length === 0 ? null : (
                <div className="job_detailspnl">
                  <div className="contnt_hdng">
                    <h4>Experience</h4>
                  </div>
                  <div className="experience_pnl">
                    <div className="dates_pnlOuter">
                      {overviewData.experience.map((item, index) => (
                        <div
                          className="dates_pnlItem experice_item"
                          key={index}
                        >
                          {/* ------ Experience image starts here ------ */}
                          <span className="dates_nmbr exprince_logo">
                            <img src={assetImages.expertLogo} alt="Logo1" />
                          </span>
                          {/* ------ Experience image ends here ------ */}

                          {/* ------ Designation starts here ------ */}
                          <div className="experience_hdng">
                            <h3>
                              {item.designation} <span>at {item.company}</span>
                            </h3>
                            {item.datefrom || item.dateto ? (
                              <h5>
                                {item.datefrom} to {item.dateto}
                              </h5>
                            ) : null}
                          </div>

                          {/* ------ Location starts here ------ */}
                          <div className="job_tags">
                            <ul className="list_stye_none">
                              <li>
                                <span>{item.location}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* --------- Experience section ends here --------- */}

              {/* --------- Recommended section starts here --------- */}
              {/* <div className="job_detailspnl">
                <div className="recomended_hdngOuter">
                  <div className="contnt_hdng">
                    <h4>Recommended</h4>
                  </div>
                  <div className="recomended_edit">
                    <Link to="#">
                      <i className="material-icons-outlined">border_color</i>
                      <span>Give Recommendation</span>
                    </Link>
                  </div>
                </div>

                <div className="recomended_outer">
                  <div className="recomend_row">
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src={assetImages.expert2} alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Roma Keller</h3>
                        <h5>Sales Manager</h5>
                        <div className="forum_para">
                          <p>
                            I first met Pierre in 1988. On my first day at Dell
                            Computer he jumped on the elevator with me to say he
                            was excited I joined the company. Over the next 6
                            years he occasionally popped into my office to
                            politely suggest what he thought my priorities
                            should be for the business.
                          </p>
                        </div>
                        <div className="post_time">
                          <span>2 months ago</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="recomend_row">
                    <div className="team_userOuter forum_usrOuter">
                      <div className="team_usr_pic">
                        <img src={assetImages.expert3} alt="" />
                      </div>
                      <div className="team_usrTxt">
                        <h3>Gordon Ramsay</h3>
                        <h5>Sales Manager</h5>
                        <div className="forum_para">
                          <p>
                            Over the next 6 years he occasionally popped into my
                            office to politely suggest what he thought my
                            priorities should be for the business.
                          </p>
                        </div>
                        <div className="post_time">
                          <span>3 months ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* --------- Recommended section ends here --------- */}

              {/* --------- Skills section starts here --------- */}
              {overviewData.skills == null ||
              overviewData.skills == undefined ||
              overviewData.skills.length === 0 ? null : (
                <div className="job_detailspnl">
                  <div className="contnt_hdng">
                    <h4>Skills</h4>
                  </div>
                  {overviewData.skills.length == 0 ? null : (
                    <div className="experts_skill">
                      <ul className="list_stye_none">
                        {overviewData.skills.map((item, index) => (
                          <li key={index}>
                            <Link to="#">{item.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {/* --------- Skills section ends here --------- */}

              {/* --------- Additional Info. section starts here --------- */}
              <div className="job_detailspnl">
                <div className="contnt_hdng">
                  <h4>Additional Information</h4>
                </div>
                <div className="expert_aditonal_outer">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">email</i>
                          Email
                        </h4>
                        <Link to="#">{overviewData.email}</Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">event_note</i>
                          Date of Birth
                        </h4>
                        <h3>{overviewData.dateofbirth}</h3>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">language</i>
                          Language
                        </h4>
                        <h3>{overviewData.language}</h3>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="additional_info">
                        <h4>
                          <i className="material-icons-outlined">school</i>
                          Education
                        </h4>
                        <h3>{overviewData.education}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* --------- Additional Info. section ends here --------- */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OverviewTab;
