/* eslint-disable */
import React from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import InvitationMain from "../../components/InvitationComponents/InvitationMain";

const Invitation = () => {
  return (
    <>
      <Header />
      <InvitationMain />
      <Footer />
    </>
  );
};

export default Invitation;
