/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../../../constants";

const FeedbackModal = () => {
  return (
    <div className="job_modal feedback_modal">
      <div
        className="modal fade"
        id="feedback_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header">
              <div className="modal_hdng">
                <h3>Feedback</h3>
                <h5>
                  to <span>Bertha Sandoval</span> for
                  <span>Windows 11 launch</span>
                </h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="feedback_usrTop">
                <div className="expertholder_outer">
                  <div className="experts_holderPic">
                    <img src={assetImages.expert1} alt="" />
                  </div>
                  <div className="experts_holdertext">
                    <h3>
                      Pierre Gagnaire <span>Sales Manager</span>
                    </h3>
                    <div className="details_tags expert_details">
                      <div className="jb_rating">
                        <span>San Deago,CA USA</span>
                      </div>
                      <div className="jb_rating star_color">
                        <i className="material-icons-outlined"> star </i>
                        <span>4.5</span>
                        <h6>
                          (32<em>valoraciones</em>)
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="others_detailsArea">
                <div className="details_row evnt_feedbackrow">
                  <div className="details_input_txt">
                    <label>Rating</label>
                  </div>
                  <div className="input_fill jobinput_fill">
                    <ul className="gray_rating list_stye_none">
                      <li className="active">
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                      <li className="active">
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">
                            {" "}
                            star{" "}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="warning_info">
                    <i className="material-icons-outlined"> info </i>
                    <p>The library also has delightful and beautifully</p>
                  </div>
                </div>
                <div className="details_row evnt_feedbackrow">
                  <div className="details_input_txt">
                    <label>Select Area</label>
                  </div>
                  <div className="input_fill jobinput_fill">
                    <div className="model_selectBx">
                      <select className="nosearch_select" title="Select">
                        <option>Lorem Ipsum</option>
                        <option>Report</option>
                        <option>Lorem</option>
                      </select>
                    </div>
                  </div>
                  <div className="warning_info">
                    <i className="material-icons-outlined"> info </i>
                    <p>The library also has delightful and beautifully</p>
                  </div>
                </div>
                <div className="details_row evnt_feedbackrow">
                  <div className="details_input_txt">
                    <label>Message</label>
                  </div>
                  <div className="input_fill jobinput_fill description_fill">
                    <textarea
                      className="form-control"
                      placeholder="Enter message"
                    ></textarea>
                  </div>
                  <div className="warning_info">
                    <i className="material-icons-outlined"> info </i>
                    <p>The library also has delightful and beautifully</p>
                  </div>
                </div>
                <div className="details_row evnt_feedbackrow">
                  <div className="details_input_txt">
                    <label>Multiselection Tags</label>
                  </div>
                  <div className="filter_list brand_info">
                    <ul className="list_stye_none">
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>All Brands</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Louis Vuitton</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Nike</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Tiffany & Co.</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Zara</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Lululemon</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Patek Philippe</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Prada</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Uniqlo</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Burberry</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Patek Philippe</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Prada</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Uniqlo</span>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="checkbox" />
                          <span>Burberry</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="warning_info">
                    <i className="material-icons-outlined"> info </i>
                    <p>The library also has delightful and beautifully</p>
                  </div>
                </div>
                <div className="details_row evnt_feedbackrow">
                  <div className="details_input_txt">
                    <label>Radio Tags</label>
                  </div>
                  <div className="feedback_radio_tags">
                    <ul className="list_stye_none">
                      <li>
                        <label className="radio-check">
                          <input type="radio" name="evnt_radio" />
                          <span></span>
                          <p>Radio One</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="radio" name="evnt_radio" />
                          <span></span>
                          <p>Radio Two</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="radio" name="evnt_radio" />
                          <span></span>
                          <p>Radio Three</p>
                        </label>
                      </li>
                      <li>
                        <label className="radio-check">
                          <input type="radio" name="evnt_radio" />
                          <span></span>
                          <p>Radio Four</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="warning_info">
                    <i className="material-icons-outlined"> info </i>
                    <p>The library also has delightful and beautifully</p>
                  </div>
                </div>
                <div className="details_row evnt_feedbackrow">
                  <div className="apply_btn">
                    <button className="basic_btn">Post Feedback</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
