/* eslint-disable */
import { useEffect } from "react";
import { Link } from "react-router-dom";

// importing custom components here
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import BlogDetailsArea from "../../components/blogdetailscomponents/BlogDetailsArea";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header activePage={"blogs"} />
      <section className="checkout_area job_serchSec blog_postSec">
        <div className="container">
          {/* ----------- Details Page banner & 'back' button ----------- */}
          <div className="page_back">
            <Link to="/blogs">
              <span className="material-icons-outlined"> arrow_back </span>
              Back
            </Link>
          </div>

          {/* ----- main blog details component ----- */}
          <BlogDetailsArea />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetails;
