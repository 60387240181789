/* eslint-disable */
import React from "react";

const Banner = (props) => {
  return (
    <div className="chkout_hdng">
      <h2>{props.heading1}</h2>
      <h5>{props.heading2}</h5>
    </div>
  );
};

export default Banner;
