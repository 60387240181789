/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

// import custom components here
import EmptyMessage from "../Others/EmptyMessage";
import ForwardModal from "../Modals/ForwardModal";

// importing images here
import { assetImages } from "../../../constants";

const ChatBoard = (props) => {
  // getting user's info here
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // assign useref hook for auto scrolling
  const messagesEndRef = useRef(null);

  // function for scroll chat bottom behavior
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  // initial state for contact chat section
  const [chatDetails, setChatDetails] = useState(null);
  const [lastChatId, setLastChatId] = useState(null);
  const [toggleReply, setToggleReply] = useState(false);

  const [sender, setSender] = useState("");
  const [sendForwardedMessage, setSendForwardedMessage] = useState("");
  const [forwardedMessageID, setForwardedMessageID] = useState(null);
  const [contactReplyChat, setContactReplyChat] = useState({});

  /*---- function for reply group chat -----*/
  const replyHandler = (item, index) => {
    setToggleReply(true);
    if (item) {
      setContactReplyChat(item);
      setSender(item.sender);
      setSendForwardedMessage(item.message);
    } else {
      setContactReplyChat({});
      setSender("");
      setSendForwardedMessage("");
    }
  };
  /*---- end of function for reply group chat -----*/

  // function for passing props in tag
  const tagChat = (item) => {
    props.getTag(item.id);
  };

  // use effect for toggle reply box and send message to chatbox
  useEffect(() => {
    if (toggleReply) {
      props.contactReplyChat(contactReplyChat);
    } else {
      props.contactReplyChat({});
      setContactReplyChat({});
    }
  }, [toggleReply]);

  // useeffect for hide reply box
  useEffect(() => {
    if (props.hideReplyBox) {
      setToggleReply(false);
    }
  }, [props.hideReplyBox]);

  // function for open file Link by condition
  const openFile = (path) => {
    window.open(path, "_blank");
  };

  /*--------- useEffect for get all chat details -----------*/
  useEffect(() => {
    //Checking if the received props data is empty or not.

    if (props.chatDetails != null || props.chatDetails != undefined) {
      // if not empty then assign to the useState
      setChatDetails(props.chatDetails);

      if (props.lastChatId != lastChatId) {
        setLastChatId(props.lastChatId);
      } else {
        setLastChatId(null);
      }
    } else {
      setChatDetails(null);
    }
  }, [props.chatDetails]);

  /*----- useeffect for scroll to the bottom updating the chats ------*/
  useEffect(() => {
    scrollToBottom();
  }, [chatDetails]);

  return (
    <>
      {props.chatIsLoading == true ? (
        <div className="contact_chat_section">
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              <div className="placeholder_row placeholder-glow">
                <div className="user_placeHoledr">
                  <div className="default_usr">
                    <img src={assetImages.defaultUser} alt="" />
                  </div>
                  <div className="default_usr_name">
                    <span className="placeholder placeholder-sm col-3 rounded-3"></span>
                    <span className="placeholder placeholder-sm col-2 rounded-3"></span>
                  </div>
                </div>
                <p className="card-text placeholder-glow">
                  <span className="placeholder placeholder-sm col-12 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-10 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-9 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-8 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-7 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-6 rounded-3"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="contact_chat_section">
          <div className="chating_mdlPnl">
            <div className="chatting_innr pd_top_60">
              {/* ---- chat section ---- */}
              {chatDetails == null ||
              chatDetails == undefined ||
              chatDetails.length == 0 ? (
                <EmptyMessage />
              ) : (
                <div className="msg_outer">
                  {chatDetails.map((item, index) => (
                    <div key={index} className="message_inner_row">
                      {/* {item.id} */}
                      {item.uid == userInfo.id ? (
                        <div className="msg_row own_msgRow">
                          {/* ---- sent message section ---- */}
                          {/* ---- sender side icon ----- */}
                          <div className="chat_infoLft">
                            <div className="chat_info_timeOuter">
                              <h4>
                                <i className="material-icons-round">
                                  question_answer
                                </i>
                                chat
                              </h4>
                              <h5>
                                {item.time}
                                {item.apiResponse == false ? (
                                  <span className="msg_status double_tick">
                                    <i className="material-icons-round">
                                      done_all
                                    </i>
                                  </span>
                                ) : (
                                  <span className="msg_status double_tick seen_tick">
                                    <i className="material-icons-round">
                                      done_all
                                    </i>
                                  </span>
                                )}
                              </h5>
                            </div>
                          </div>
                          {/* ---- end of sender side icon ----- */}

                          {/*---- sender side message area ------*/}
                          <div className="chat_msgright">
                            {item.parent ? (
                              <div className="own_msgArea">
                                <div className="replied_msg_row">
                                  <span>{item.parent.sender}</span>
                                  <p>{item.parent.message}</p>
                                </div>
                                <div className="replied_msg_text">
                                  <p>{item.message}</p>
                                </div>
                              </div>
                            ) : (
                              <div className="own_msgArea">
                                <p>{item.message}</p>
                              </div>
                            )}

                            {item.media.length == 0 ||
                            item.media == null ||
                            item.media == undefined ? null : (
                              <div className="clearfix">
                                <div className="upload_picOuter">
                                  {item.media.map((path, index2) => {
                                    return (
                                      <div
                                        className="upload_pic_item"
                                        key={index2}
                                      >
                                        <figure
                                          className="upload_viewimg"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => openFile(path)}
                                        >
                                          {path.split(".").pop() == "jpg" ||
                                          path.split(".").pop() == "JPG" ||
                                          path.split(".").pop() == "png" ? (
                                            <img src={path} alt="images" />
                                          ) : (
                                            <div className="pdf-section">
                                              {path.split(".").pop() ==
                                              "pdf" ? (
                                                <img
                                                  src={assetImages.pdf}
                                                  alt="docs"
                                                />
                                              ) : (
                                                <div className="xls-section">
                                                  {path.split(".").pop() ==
                                                    "xlsx" ||
                                                  path.split(".").pop() ==
                                                    "xls" ? (
                                                    <img
                                                      src={assetImages.xls}
                                                      alt="docs"
                                                    />
                                                  ) : (
                                                    <>
                                                      {path.split(".").pop() ==
                                                        "docx" ||
                                                      path.split(".").pop() ==
                                                        "txt" ||
                                                      path.split(".").pop() ==
                                                        "doc" ||
                                                      path.split(".").pop() ==
                                                        "odt" ||
                                                      path.split(".").pop() ==
                                                        "dotx" ||
                                                      path.split(".").pop() ==
                                                        "docm" ||
                                                      path.split(".").pop() ==
                                                        "pptx" ? (
                                                        <img
                                                          src={assetImages.docs}
                                                          alt="docs"
                                                        />
                                                      ) : (
                                                        <>
                                                          {path
                                                            .split(".")
                                                            .pop() == "mp4" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "mkv" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "wmv" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "mov" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "3gp" ? (
                                                            <img
                                                              src={
                                                                assetImages.VIDEO
                                                              }
                                                              alt="image"
                                                            />
                                                          ) : (
                                                            <>
                                                              {path
                                                                .split(".")
                                                                .pop() ==
                                                                "rar" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "zip" ? (
                                                                <img
                                                                  src={
                                                                    assetImages.ZIP
                                                                  }
                                                                  alt="image"
                                                                />
                                                              ) : (
                                                                <img
                                                                  src={path}
                                                                  alt="image"
                                                                />
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </figure>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                          {/*---- end of sender side message area ------*/}
                          {/* ---- end of sent message section ---- */}
                        </div>
                      ) : (
                        <div className="recieved_message_section">
                          {/* ----- receiver side message section ----- */}
                          {item.type == "chat" ? (
                            <div className="msg_row reply_msgRow">
                              {/*----- receiver chat message section -----*/}
                              <div className="chat_infoLft">
                                {/*----- receiver chat user image area -----*/}
                                <div className="msg_usr chatting_user">
                                  {item.image == null ||
                                  item.image == undefined ? (
                                    <span className="material-icons-round">
                                      person
                                    </span>
                                  ) : (
                                    <img src={item.image} alt="user" />
                                  )}
                                </div>
                                {/*----- end of receiver chat user image area -----*/}

                                {/*-- receiver chat typing/Chat indication and time --*/}
                                <div className="chat_info_timeOuter">
                                  {item.typingStatus == null ||
                                  item.typingStatus == undefined ? (
                                    <h4>
                                      <i className="material-icons-round">
                                        question_answer
                                      </i>
                                      chat
                                    </h4>
                                  ) : (
                                    <div className="chat_dots">
                                      <img src={chatDots} alt="typing" />
                                    </div>
                                  )}
                                  {item.time == "" ||
                                  item.time == null ||
                                  item.time == undefined ? null : (
                                    <h5>{item.time}</h5>
                                  )}
                                </div>
                                {/* end of receiver chat typing/Chat indication and time */}
                              </div>

                              {/*----- receiver side chat message area -----*/}
                              <div className="chat_msgright">
                                {item.tags == null ? null : (
                                  <div className="tag_msg">
                                    <Link to="#">
                                      <i className="material-icons">label</i>
                                      {item.tags.map((item2, index2) => {
                                        return (
                                          <span key={index2}>
                                            {item2.label},
                                          </span>
                                        );
                                      })}
                                    </Link>
                                  </div>
                                )}

                                {item.message == "" ||
                                item.message == null ||
                                item.message == undefined ? null : (
                                  <div className="reply_msg_row">
                                    {item.parent ? (
                                      <div className="msg_text">
                                        <div className="replied_msg_row_left">
                                          <span>{item.parent.sender}</span>
                                          <p>{item.parent.message}</p>
                                        </div>
                                        <div className="replied_msg_text_left">
                                          <p>{item.message}</p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="msg_text">
                                        <p>{item.message}</p>
                                      </div>
                                    )}

                                    <div className="msg_shareicon_info">
                                      <ul>
                                        {/* -------------- Reply Button here -------------- */}
                                        <li>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              replyHandler(item, index);
                                            }}
                                          >
                                            <i className="material-icons-round">
                                              reply
                                            </i>
                                            Reply
                                          </Link>
                                        </li>

                                        {/* -------- Calling forward Modal here -------- */}
                                        <li>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#forward_mdl"
                                            onClick={() =>
                                              setForwardedMessageID(item.id)
                                            }
                                          >
                                            <i className="material-icons-round">
                                              fast_forward
                                            </i>
                                            Forward
                                          </Link>
                                        </li>

                                        {/* -------- Calling Add Tag Modal here -------- */}
                                        <li>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#addtag_mdl"
                                            role="button"
                                            onClick={() => tagChat(item)}
                                          >
                                            <i className="material-icons-round">
                                              loupe
                                            </i>
                                            Labels
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}

                                {/*----- end of receiver side chat message area -----*/}

                                {/*----- receiver side media files area -----*/}
                                {item.media.length == 0 ||
                                item.media == null ||
                                item.media == undefined ? null : (
                                  <div className="clearfix">
                                    <div className="upload_picOuter">
                                      {item.media.map((path, index2) => {
                                        return (
                                          <div
                                            className="upload_pic_item"
                                            key={index2}
                                          >
                                            <figure
                                              className="upload_viewimg"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                window.open(path, "_blank")
                                              }
                                            >
                                              {path.split(".").pop() == "jpg" ||
                                              path.split(".").pop() == "JPG" ||
                                              path.split(".").pop() == "png" ? (
                                                <img src={path} alt="images" />
                                              ) : (
                                                <div className="pdf-section">
                                                  {path.split(".").pop() ==
                                                  "pdf" ? (
                                                    <img
                                                      src={assetImages.pdf}
                                                      alt="docs"
                                                    />
                                                  ) : (
                                                    <div className="xls-section">
                                                      {path.split(".").pop() ==
                                                        "xlsx" ||
                                                      path.split(".").pop() ==
                                                        "xls" ? (
                                                        <img
                                                          src={assetImages.xls}
                                                          alt="docs"
                                                        />
                                                      ) : (
                                                        <>
                                                          {path
                                                            .split(".")
                                                            .pop() == "docx" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "txt" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "doc" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "odt" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "dotx" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "docm" ||
                                                          path
                                                            .split(".")
                                                            .pop() == "pptx" ? (
                                                            <img
                                                              src={
                                                                assetImages.docs
                                                              }
                                                              alt="docs"
                                                            />
                                                          ) : (
                                                            <>
                                                              {path
                                                                .split(".")
                                                                .pop() ==
                                                                "mp4" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "mkv" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "wmv" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "mov" ||
                                                              path
                                                                .split(".")
                                                                .pop() ==
                                                                "3gp" ? (
                                                                <img
                                                                  src={
                                                                    assetImages.VIDEO
                                                                  }
                                                                  alt="image"
                                                                />
                                                              ) : (
                                                                <>
                                                                  {path
                                                                    .split(".")
                                                                    .pop() ==
                                                                    "rar" ||
                                                                  path
                                                                    .split(".")
                                                                    .pop() ==
                                                                    "zip" ? (
                                                                    <img
                                                                      src={
                                                                        assetImages.ZIP
                                                                      }
                                                                      alt="image"
                                                                    />
                                                                  ) : (
                                                                    <img
                                                                      src={path}
                                                                      alt="image"
                                                                    />
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </figure>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                                {/*----- end of receiver side media files area -----*/}
                              </div>
                              {/*----- end of receiver chat message section -----*/}
                            </div>
                          ) : null}
                          {/* ----- end of receiver side message section ----- */}
                        </div>
                      )}
                    </div>
                  ))}
                  {/* ---- calling useref hook for scroll behaviour ----- */}
                  <div ref={messagesEndRef} name="scrollRefDiv" />
                </div>
              )}
              {/* ---- end of chat section ---- */}
            </div>

            {/* ----------------- Reply popup starts here ----------------- */}
            {toggleReply && (
              <div className="reply_popup_area" id="replyPopUp">
                <div className="reply_popupmsg">
                  <h5>{sender}</h5>
                  <p>{sendForwardedMessage}</p>
                </div>
                <div className="reply_cross">
                  <Link
                    to="#"
                    role="button"
                    onClick={() => {
                      replyHandler();
                      setToggleReply(false);
                    }}
                  >
                    <i className="material-icons-round">close</i>
                  </Link>
                </div>
              </div>
            )}
            {/* ----------------- Reply popup ends here ----------------- */}
          </div>
        </div>
      )}

      {/* ----------------- Calling the Forward Modal here ----------------- */}
      <ForwardModal messageId={forwardedMessageID} />
    </>
  );
};

export default ChatBoard;
