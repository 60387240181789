/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../../../../constants";

// import methods from gatway for API call
import { getData } from "../../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../../urlhelpers/UrlHelper";

const ProjectRanking = ({ rankSelectedValue }) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const glowCount = [1, 2, 3, 4, 5];

  const [projectRankData, setProjectRankData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProjectRankDatas = async (id) => {
    setIsLoading(true);
    const requestURL =
      url.API_BaseUrl +
      url.API_GET_PROJECT_RANK_REPORT +
      `?efid=${id}` +
      `&token=${token}`;

    try {
      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setIsLoading(false);
        if (response.eventFeedbackData) {
          if (response.eventFeedbackData.providers) {
            setProjectRankData(response.eventFeedbackData.providers);
          } else {
            setProjectRankData([]);
          }
        } else {
          setProjectRankData([]);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (rankSelectedValue) {
      getProjectRankDatas(rankSelectedValue);
    }
  }, [rankSelectedValue]);

  return (
    <div className="inner_tab_pane" id="project_ranking">
      {/* ------------- Table Section starts here ------------- */}
      <div className="ranking_tableinnr">
        <table>
          {/* ------------- Table Head starts here ------------- */}
          <thead>
            <tr>
              <th>
                <span>
                  User Name
                  <Link to="#">
                    <i className="material-icons-outlined">unfold_more</i>
                  </Link>
                </span>
              </th>
              <th>
                <span>
                  Total
                  <Link to="#">
                    <i className="material-icons-outlined">unfold_more</i>
                  </Link>
                </span>
              </th>
              <th>
                <span>
                  Completed
                  <Link to="#">
                    <i className="material-icons-outlined">unfold_more</i>
                  </Link>
                </span>
              </th>
              <th>
                <span>
                  Pending
                  <Link to="#">
                    <i className="material-icons-outlined">unfold_more</i>
                  </Link>
                </span>
              </th>
              <th>
                <span>
                  Ranking
                  <Link to="#">
                    <i className="material-icons-outlined">unfold_more</i>
                  </Link>
                </span>
              </th>
            </tr>
          </thead>

          {/* ------------- Table Body starts here ------------- */}
          {isLoading ? (
            <tbody>
              {glowCount.map((count, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div class="ranking_usr">
                        <span
                          class="placeholder rounded-circle me-3"
                          style={{ width: "28px", height: "28px" }}
                        ></span>
                        <span
                          class="placeholder"
                          style={{ width: "70px", height: "14px" }}
                        ></span>
                      </div>
                    </td>
                    <td>
                      <span
                        class="placeholder"
                        style={{ width: "50px", height: "14px" }}
                      ></span>
                    </td>
                    <td>
                      <span
                        class="placeholder"
                        style={{ width: "50px", height: "14px" }}
                      ></span>
                    </td>
                    <td>
                      <span
                        class="placeholder"
                        style={{ width: "50px", height: "14px" }}
                      ></span>
                    </td>
                    <td>
                      <div class="modal_progres">
                        <label>
                          <span
                            class="placeholder"
                            style={{ width: "30px" }}
                          ></span>
                        </label>
                        <span
                          class="placeholder"
                          style={{ width: "110px", height: "10px" }}
                        ></span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {projectRankData.map((report, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="ranking_usr">
                        {/* <i className="material-icons-outlined">person</i> */}
                        <img src={report.image} alt="" className="me-1" />
                        <span>{report.name}</span>
                      </div>
                    </td>
                    <td>
                      <span>{report.total}</span>
                    </td>
                    <td>
                      <span>{report.responded}</span>
                    </td>
                    <td>
                      <span>{report.pending}</span>
                    </td>
                    <td>
                      <div className="modal_progres">
                        <label>{report.percent}%</label>
                        <div className="progess_outer">
                          <span
                            className="progress_bar"
                            style={{ width: `${report.percent}%` }}
                          ></span>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>

      {/* ------------- Pagination starts here ------------- */}
      {/* <div className="job_pagination">
        <ul className="list_stye_none">
          <li className="prev_pagnation">
            <Link to="#">
              <span className="material-icons-outlined">
                keyboard_arrow_left
              </span>
            </Link>
          </li>
          <li className="active">
            <Link to="#">1</Link>
          </li>
          <li>
            <Link to="#">2</Link>
          </li>
          <li>
            <Link to="#">3</Link>
          </li>
          <li>
            <Link to="#">...</Link>
          </li>
          <li>
            <Link to="#">98</Link>
          </li>
          <li>
            <Link to="#">99</Link>
          </li>
          <li className="next_pagination">
            <Link to="#">
              <span className="material-icons-outlined">
                keyboard_arrow_right
              </span>
            </Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default ProjectRanking;
