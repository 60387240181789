/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// import Gateway for API fetch call
import { getData } from "../../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../../../../../constants";
import ParticipantsFeedbackModal from "../tabmodals/ParticipantsFeedbackModal";

const WriteFeedback = (props) => {
  const {} = useContext(GlobalFindelOiProvider); //import context files
  const params = useParams();
  //initial useState for event details about data
  const [eventParticipantsData, setEventParticipantsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [participantsData, setParticipantsData] = useState([]);
  const [callModalSurveyAPI, setCallModalSurveyAPI] = useState(false);

  //initial for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  // temporary array
  const glowCount = [1, 2, 3, 4];

  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Function to get all the about data from API
  const getEventParticipantsData = async (id) => {
    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl +
      url.API_EVENT_PARTICIPANTS +
      `?token=${token}` +
      `&id=${id}`;

    try {
      const response = await getData(requestURL);

      console.log("Write feedback resp----->", response);

      if (response.status) {
        if (response.data) {
          if (
            response.data.length === 0 ||
            response.data.length !== undefined
          ) {
            setParticipantsData([]);
          } else {
            let memberResult;
            // const mergeArrays = (avalaibleRolesArray, membersByRoleArray) => {
            //   return avalaibleRolesArray.map((obj) => {
            //     const membersFilter = membersByRoleArray.filter(
            //       (memberElements) => memberElements["roleid"] === obj["id"]
            //     );

            //     if (!membersFilter.length) {
            //       obj.members = membersFilter;
            //       return obj;
            //     }
            //     obj.member = membersFilter.map((memberlists) => ({
            //       member: memberlists.member,
            //     }));
            //     return obj;
            //   });
            // };
            // memberResult = mergeArrays(
            //   response.data.availableroles,
            //   response.data.membersbyrole
            // );
            memberResult = response.data.membersbyrole.map((allMember) => {
              return allMember.member;
            });

            const mergeResult = memberResult.concat(response.data.othermembers);

            console.log(mergeResult);

            setParticipantsData(mergeResult);
            let pageCount;

            /*--- Creating dynamic array for pagination ----*/
            let pageArray = [];

            pageCount = Math.ceil(mergeResult.length / 20);

            for (let i = 1; i <= pageCount; i++) {
              pageArray.push(i);
            }

            setTotalPages(pageArray);
            /*--- Creating dynamic array for pagination end ----*/

            if (pageCount > 1) {
              setLastPageNo(pageCount);
            } else {
              setLastPageNo(1);
            }
          }

          // if (response.data.othermembers) {
          //   setGuestMembers(response.data.othermembers);
          // } else {
          //   setGuestMembers([]);
          // }
        }
        setIsLoading(false);
      }
    } catch (error) {}
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for set participants data for modal
  const participantsDetails = (data) => {
    setEventParticipantsData(data);
  };

  //function for reset survey participants after modal close
  const afterModalCallApi = () => {
    setCallModalSurveyAPI(false);
  };

  //function for refresh after close feedback modal
  const afterModalClose = () => {
    getEventParticipantsData(params.id);
  };

  // reload this tab after join event
  // useEffect(() => {
  //   if (reloadParticipantsTab) {
  //     getEventParticipantsData(params.id);
  //     setReloadParticipantsTab(false);
  //   }
  // }, [reloadParticipantsTab]);

  // useEffect from here
  useEffect(() => {
    getEventParticipantsData(params.id);
  }, [params.id]);

  // return statement starts here
  return (
    <div className="inner_tab_pane" id="write-feedback">
      {/* ------------- Main Section starts here ------------- */}
      {isLoading ? (
        <div className="team_outer">
          <div className="row">
            {glowCount.map((count) => {
              return (
                <div className="col-md-6" key={count}>
                  <div className="team_item">
                    <div className="team_topbx">
                      <div className="team_userOuter">
                        <div className="team_usr_pic">
                          <span
                            className="placeholder rounded-circle"
                            style={{ width: "80px", height: "80px" }}
                          ></span>
                        </div>
                        <div className="team_usrTxt">
                          <h3>
                            <span
                              className="placeholder"
                              style={{ width: "130px" }}
                            ></span>
                          </h3>
                          <h5>
                            <span
                              className="placeholder"
                              style={{ width: "100px" }}
                            ></span>
                          </h5>
                          <div className="job_tags">
                            <ul className="list_stye_none">
                              <span
                                className="placeholder"
                                style={{
                                  width: "130px",
                                  height: "30px",
                                  borderRadius: "80px",
                                }}
                              ></span>
                            </ul>
                          </div>
                          <div className="feedback_btn">
                            <span
                              className="placeholder rounded-3"
                              style={{ width: "120px", height: "38px" }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="team_outer">
          {participantsData.length === 0 ? (
            <div className="row">
              <div className="empty_lessonsArea">
                <div className="empty_lessonpic">
                  <img src={assetImages.emptyLesson} alt="blanklesson" />
                </div>
                <div className="empty_lessons_text">
                  <h2>Sorry....!</h2>
                  <p>No participants available for this event.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {/* ------------- Card 1 starts here ------------- */}
              {participantsData.map((participants, index) => {
                return (
                  <div
                    className={
                      participants.name
                        .toLowerCase()
                        .includes(props.participantsName.toLowerCase())
                        ? "col-md-6"
                        : "d-none"
                    }
                    key={index}
                  >
                    <div className="team_item">
                      <div className="team_topbx">
                        <div className="team_userOuter">
                          <div className="team_usr_pic">
                            <img
                              src={
                                participants.image
                                  ? participants.image
                                  : assetImages.expert1
                              }
                              alt=""
                            />
                          </div>
                          <div className="team_usrTxt">
                            <h3>{participants.name}</h3>
                            <h5>{participants.position}</h5>
                            <div className="job_tags">
                              {/* <ul className="list_stye_none">
                              <li>
                                <i className="material-icons-outlined">place</i>
                                <span>Madrid, Spain</span>
                              </li>
                            </ul> */}
                            </div>

                            {/* ----- feedback button start ----- */}

                            {participants.id == userInfo.id ? null : (
                              <div
                                className={
                                  participants.hasreviewed
                                    ? "feedback_btn show_feedback"
                                    : "feedback_btn"
                                }
                              >
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#participants_feedback_modal"
                                  onClick={() => {
                                    participantsDetails(participants);
                                    setCallModalSurveyAPI(true);
                                  }}
                                >
                                  <i className="material-icons-outlined">
                                    rate_review
                                  </i>
                                  <span>Feedback</span>
                                </Link>
                              </div>
                            )}

                            {/* ----- feedback button end ----- */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* ------------- Pagination starts here ------------- */}
      <div className="job_pagination">
        <ul className="list_stye_none">
          <li className="prev_pagnation">
            <Link
              to="#"
              onClick={() => {
                if (page != 1) {
                  nextPage(page - 1);
                  // eventRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_left
              </span>
            </Link>
          </li>
          {totalPages.map((pageNo, index) => {
            return (
              <li className={page == pageNo ? "active" : ""} key={index}>
                <Link
                  to="#"
                  onClick={() => {
                    nextPage(pageNo);
                    // eventRefToggle();
                  }}
                >
                  {pageNo}
                </Link>
              </li>
            );
          })}

          <li className="next_pagination">
            <Link
              to="#"
              onClick={() => {
                if (page != lastPageNo) {
                  nextPage(page + 1);
                  // eventRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_right
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <ParticipantsFeedbackModal
        eventParticipantsData={eventParticipantsData}
        callModalSurveyAPI={callModalSurveyAPI}
        afterModalCallApi={afterModalCallApi}
        afterModalClose={afterModalClose}
      />
    </div>
  );
};

export default WriteFeedback;
