/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

//import images
import { assetImages } from "../../../constants";

//import context files
import { GlobalFindelOiProvider } from "../../../context/FindeloiContext";

const ProfileUpdateSuccessModal = (props) => {
  const { setLoadUserLoggedInMenu } = useContext(GlobalFindelOiProvider);

  //function for executing some task after closing modal
  const closeModalHandler = () => {
    setLoadUserLoggedInMenu(true);
    props.afterModalClose();
  };

  return (
    <div className="conatct_modal success_popup">
      <div
        className="modal fade"
        id="profile_updatemdl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success_graphics">
                <img src={assetImages.profileGraphics} alt="" />
              </div>
              <div className="success_text">
                <h3>Profile Updated!</h3>
                <p>Profile details updated.</p>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn"
                  onClick={closeModalHandler}
                >
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileUpdateSuccessModal;
