/* eslint-disable */
import { useContext } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../../../constants";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

const ThanksReviewExpertModal = () => {
  const { setReloadExpertReview, setReloadExpertTop } = useContext(
    GlobalFindelOiProvider
  );

  //function for reload review and banner expert
  const afterModalClose = () => {
    setReloadExpertReview(true);
    setReloadExpertTop(true);
  };

  return (
    <div className="conatct_modal success_popup">
      <div
        data-bs-backdrop="static"
        className="modal fade"
        id="expert_thanks_mdl"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success_graphics">
                <img src={assetImages.eventCongrats} alt="" />
              </div>
              <div className="success_text">
                <h3>Thank you!</h3>
                <p>Successfully reviewed this expert.</p>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="basic_btn mb-4"
                  onClick={afterModalClose}
                >
                  Close
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksReviewExpertModal;
