/* eslint-disable */
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => {
  const token = localStorage.getItem("token");

  // if (isAuthProtected) {
  //   if (window.location.href.split("/").pop() != "logout") {
  //     localStorage.setItem("startingURL", window.location.href);
  //   }
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        // if user not logged in then the login page will appeared
        if (isAuthProtected && !token) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

// Declare the values of routes proptypes
Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
};

export default Authmiddleware;
