/* eslint-disable */
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const EventListArea = () => {
  const {
    eventTitle,
    setEventTitle,
    eventCategory,
    setEventCategory,
    eventCity,
    setEventCity,
    eventFilterValue,
    setEventFilterValue,
  } = useContext(GlobalFindelOiProvider);

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const eventListRef = useRef(null);

  const glowCount = [1, 2, 3];

  const [page, setPage] = useState(1); // Initial useState to set page numbers for pagination
  const [eventsData, setEventsData] = useState([]); // initial useState for API data
  const [isLoading, setIsLoading] = useState(false); // initial for loading

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  // function for all events lists
  const getEventsLists = async () => {
    // let eventCategoryList;

    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_LIST +
        `?token=${token}` +
        `&page=${page}`;
    } else {
      requestURL = url.API_BaseUrl + url.API_EVENT_LIST + `?page=${page}`;
    }

    if (eventTitle != "") {
      requestURL = requestURL + `&query=${eventTitle}`;
    }

    if (eventCategory && eventCategory.length > 0) {
      eventCategory.map((data) => {
        // eventCategoryList += `&cat[]=${data.id}`;
        requestURL += `&cat[]=${data.id}`;
      });
      // requestURL = requestURL + eventCategoryList;
    }

    if (eventCity != "") {
      requestURL = requestURL + `&location=${eventCity}`;
    }

    if (eventFilterValue != null) {
      requestURL = requestURL + `&selecteditem=${eventFilterValue.value}`;
    }

    console.log("request url for event list----->", requestURL);

    try {
      const response = await getData(requestURL);

      console.log("response in events------>", response);

      if (response.status) {
        if (response.data) {
          setEventsData(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setEventsData([]);
        }
        setIsLoading(false);
      } else {
        setEventsData([]);
      }
    } catch (error) {}
  };

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for toggle eventRef
  const eventRefToggle = () => {
    eventListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //function reset event title
  const resetEventTitle = () => {
    setEventTitle("");
    setPage(1);
  };

  //function for reset event location only
  const resetEventLocation = () => {
    setEventCity("");
    setPage(1);
  };

  //function for delete single event category
  const deleteSingleValue = (index) => {
    if (index === 0) {
      setPage(1);
    }
    let deletedEventCategory = [...eventCategory];
    deletedEventCategory.splice(index, 1);
    setEventCategory(deletedEventCategory);
  };

  //function for reset event category only
  const resetEventCategory = () => {
    setEventCategory([]);
    setPage(1);
  };

  //function for reset event filter value only
  const resetEventFilter = () => {
    setEventFilterValue(null);
    setPage(1);
  };

  //useeffect for get all event list on condition based
  useEffect(() => {
    getEventsLists();
  }, [page, eventTitle, eventCity, eventCategory, eventFilterValue]);

  // return statement from here
  return (
    <>
      {eventTitle != "" ||
      eventCategory.length > 0 ||
      eventCity != "" ||
      eventFilterValue != null ? (
        <div className="mt-4">
          <div className="d-flex flex-wrap gap-2">
            {/* ---------- Search Title Section starts here ---------- */}

            {eventTitle == "" ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Name
                  </label>

                  <label>{eventTitle}</label>
                  <Link to="#" className="cancel" onClick={resetEventTitle}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {/* ---------- Search category Section starts here ---------- */}
            {eventCategory.length == 0 ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Category
                  </label>
                  <ul className="badges d-flex flex-wrap gap-2">
                    {eventCategory.map((eventNames, index) => {
                      return (
                        <li
                          className="d-flex align-items-center px-2 gap-2 border"
                          key={index}
                        >
                          {eventNames.label}
                          <Link to="#" onClick={() => deleteSingleValue(index)}>
                            <i className="d-block material-icons">clear</i>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <Link to="#" className="cancel" onClick={resetEventCategory}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {/* ---------- Search Location Section starts here ---------- */}
            {eventCity == "" ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Location
                  </label>
                  <label>{eventCity}</label>
                  <Link to="#" className="cancel" onClick={resetEventLocation}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {/* ----- searched event filter value ------- */}
            {eventFilterValue == null ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Type
                  </label>
                  <label>{eventFilterValue.label}</label>
                  <Link to="#" className="cancel" onClick={resetEventFilter}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}

      <div ref={eventListRef} name="scrollEventRefDiv" />
      {/* ---------------- Cards List section starts here ---------------- */}
      <div className="event_bxpnl">
        <div className="job_box_outer">
          {isLoading ? (
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={count}>
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "70%" }}
                          ></span>
                        </h3>
                        <h5>
                          <span
                            className="placeholder"
                            style={{ width: "55%" }}
                          ></span>
                        </h5>
                      </div>
                      <div className="event_tags">
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                      </div>
                      <div className="event_pic">
                        <span
                          className="placeholder"
                          style={{ width: "100%", height: "200px" }}
                        ></span>
                      </div>
                      <div className="event_para">
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "100%", height: "15px" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "80%", height: "15px" }}
                          ></span>
                        </p>
                      </div>
                      <div className="ambassadors_users event_user">
                        <ul className="list_stye_none">
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder w-100 rounded-2"
                          style={{ height: "46px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {eventsData.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="empty_lessonsArea mt-3">
                      <div className="empty_lessonpic">
                        <img src={assetImages.emptyLesson} alt="blanklesson" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>Sorry....!</h2>
                        <p>
                          Events are not available{" "}
                          {eventTitle ? "for this event name" : ""}{" "}
                          {eventCity ? "for this city" : ""}{" "}
                          {eventCategory.length != 0
                            ? "for these categories"
                            : ""}{" "}
                          {eventFilterValue ? "for this type" : ""} at this
                          moment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {/* ---------- Card component starts here ---------- */}
                  {eventsData.map((event, index) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={index}>
                        <div className="job_bxItem event_bxItem">
                          <div className="event_hdng">
                            <h3>{event.title}</h3>
                            <h5>
                              <i className="material-icons-outlined">
                                {" "}
                                event_note{" "}
                              </i>
                              <span>
                                {event.startday} {event.startmonth}{" "}
                                {event.startyear}
                              </span>
                            </h5>
                          </div>
                          {event.category.length > 0 ? (
                            <div className="mb-1">
                              {event.category.map((category, index2) => {
                                return (
                                  <div
                                    className="wedding_text jb_rating mb-2 me-2"
                                    key={index2}
                                  >
                                    {category}
                                  </div>
                                );
                              })}
                            </div>
                          ) : null}
                          <div className="event_tags">
                            <div className="jb_rating star_color">
                              <i className="material-icons-outlined"> star </i>
                              <span>{event.rating}</span>
                              <h6>({event.reviews})</h6>
                            </div>
                            <div className="jb_rating">
                              <i className="material-icons-outlined"> place </i>
                              {event.city && event.country ? (
                                <span>
                                  {event.city ? event.city : null} ,{" "}
                                  {event.country ? event.country : null}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="event_pic">
                            <figure>
                              <img
                                src={event.image ? event.image : ""}
                                alt="eventImg"
                              />
                            </figure>
                          </div>
                          <div className="event_para">
                            <p>{event.summary}</p>
                          </div>
                          <div className="ambassadors_users event_user">
                            <ul className="list_stye_none">
                              {event.recentmembersimages.map(
                                (member, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#" className="overflow-hidden">
                                        <img
                                          src={
                                            member.image ||
                                            assetImages.defaultUser
                                          }
                                          alt="expertImg"
                                        />
                                      </Link>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                          <div className="details_btn">
                            <Link
                              to={`/event/details/${event.id}`}
                              className="border_btn jbdetails_btn"
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* ---------- Card component ends here ---------- */}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* ---------------- Pagination section starts here ---------------- */}
      <div className="job_pagination">
        <ul className="list_stye_none">
          <li className="prev_pagnation">
            <Link
              to="#"
              onClick={() => {
                if (page != 1) {
                  nextPage(page - 1);
                  eventRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_left
              </span>
            </Link>
          </li>
          {totalPages.map((pageNo) => {
            return (
              <li className={page == pageNo ? "active" : ""} key={pageNo}>
                <Link
                  to="#"
                  onClick={() => {
                    nextPage(pageNo);
                    eventRefToggle();
                  }}
                >
                  {pageNo}
                </Link>
              </li>
            );
          })}

          <li className="next_pagination">
            <Link
              to="#"
              onClick={() => {
                if (page != lastPageNo) {
                  nextPage(page + 1);
                  eventRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_right
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default EventListArea;
