/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData, postFormdata } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";
/* ------------------------------------------------------------------------*/

// importing images here
import { assetImages } from "../../../../../constants";

// importing modal here
import ThanksReviewExpertModal from "./ThanksReviewExpertModal";

const ReviewModal = () => {
  // accessing the user's token here
  let token = "dmhlcnJlcm9AZ2NvbGFib3JhLmNvbToxNjA0NjUyNjI0";
  // const token = localStorage.getItem("token");
  // const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  // initial useState to save the comment
  const [comments, setComments] = useState("");

  // initial useState to save star rating
  const [starRating, setstarRating] = useState(1);

  /* ------------ Initial useStates for activating the starts starts here ------------ */
  const [activeFirstStar, setActiveFirstStar] = useState(true);
  const [activeSecondStar, setActiveSecondStar] = useState(false);
  const [activeThirdStar, setActiveThirdStar] = useState(false);
  const [activeFourthStar, setActiveFourthStar] = useState(false);
  const [activeFiveStar, setActiveFifthStar] = useState(false);

  // function to display the star ratings
  const highlightStars = (e, value) => {
    e.preventDefault();

    if (value == 1) {
      setActiveFirstStar(true);
      setActiveSecondStar(false);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 2) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(false);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 3) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(false);
      setActiveFifthStar(false);
    }

    if (value == 4) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(false);
    }

    if (value == 5) {
      setActiveFirstStar(true);
      setActiveSecondStar(true);
      setActiveThirdStar(true);
      setActiveFourthStar(true);
      setActiveFifthStar(true);
    }
  };
  /* ------------ Initial useStates for activating the starts ends here ------------ */

  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [isReviewPosting, setIsReviewPosting] = useState(false);

  // Function to submit the review to the API.
  const reviewSubmitHandler = async (e) => {
    e.preventDefault();
    setCommentError(false);
    setRatingError(false);
    if (comments == "" || starRating == 0) {
      if (comments == "") {
        setCommentError(true);
      } else {
        setCommentError(false);
      }
      if (starRating == 0) {
        setRatingError(true);
      } else {
        setRatingError(false);
      }
    } else {
      setIsReviewPosting(true);
      let requestURL =
        url.API_BaseUrl + url.API_EXPERT_RATING + `?token=${token}`;

      let reviewData = {
        mod: "MOD_USER",
        com: params.id,
        comments: comments,
        ratings: starRating,
      };

      try {
        const response = await postFormdata(requestURL, reviewData);

        if (response.status) {
          resetHandler();
          let reviewModal = document.querySelector("#addreview-modal");
          let modal = bootstrap.Modal.getInstance(reviewModal);
          modal.hide();
          let myModal = new bootstrap.Modal(
            document.getElementById("expert_thanks_mdl")
          );
          myModal.show();
          setIsReviewPosting(false);
          console.log("Review Modal: submit response -----> ", response.status);
        }
      } catch (error) {
        console.log("Review Modal: submit error -----> ", error);
      }
    }
  };

  // Function to select the star ratings from the stars
  const selectedStar = (e, star) => {
    e.preventDefault();
    setstarRating(star);
  };

  // function to reset the star and comments
  const resetHandler = () => {
    setActiveFirstStar(true);
    setActiveSecondStar(false);
    setActiveThirdStar(false);
    setActiveFourthStar(false);
    setActiveFifthStar(false);
    setComments("");
  };

  // return statement starts here
  return (
    <div className="job_modal feedback_modal review_modal">
      <div
        data-bs-backdrop="static"
        className="modal fade"
        id="addreview-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header">
              <div className="modal_hdng">
                <h3>Add a Review</h3>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="form_commnts_area commnts_newbx">
                {/* ---------- Star rating section starts here ---------- */}
                <div className="course_rvw_row">
                  <div className="rating_hdng">
                    <h5>Give Ratings</h5>
                  </div>

                  <ul className="list_stye_none">
                    {/* ----------- 1 Star ----------- */}
                    <li
                      className={activeFirstStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 1);
                        highlightStars(e, 1);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 2 Star ----------- */}
                    <li
                      className={activeSecondStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 2);
                        highlightStars(e, 2);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 3 Star ----------- */}
                    <li
                      className={activeThirdStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 3);
                        highlightStars(e, 3);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 4 Star ----------- */}
                    <li
                      className={activeFourthStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 4);
                        highlightStars(e, 4);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>

                    {/* ----------- 5 Star ----------- */}
                    <li
                      className={activeFiveStar == true ? "active_star" : ""}
                      onClick={(e) => {
                        selectedStar(e, 5);
                        highlightStars(e, 5);
                      }}
                    >
                      <Link to="#">
                        <span className="material-icons-outlined">star</span>
                      </Link>
                    </li>
                  </ul>

                  {ratingError && (
                    <p className="text-danger mb-3">* Please give rating</p>
                  )}
                </div>

                {/* ---------- Commenting section starts here ---------- */}
                <div className="commnts_send_innr">
                  <div className="rating_hdng">
                    <h5>Give Ratings</h5>
                  </div>

                  <div className="commnts_sendArea">
                    <textarea
                      className="form-control"
                      placeholder="Write your query here"
                      value={comments != "" ? comments : ""}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                    <div className="furmcmnt_btn">
                      {/* <button className="cmnt_btn">
                        <span className="material-icons-outlined">image</span>
                      </button> */}
                      <button
                        className="cmnt_btn"
                        onClick={(e) => reviewSubmitHandler(e)}
                        disabled={isReviewPosting ? true : false}
                        style={{
                          cursor: isReviewPosting ? "not-allowed" : "pointer",
                        }}
                      >
                        <span className="material-icons-outlined">send</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------- 'Congratulations' modal implemented here -------------- */}

      <ThanksReviewExpertModal />
    </div>
  );
};

export default ReviewModal;
