/* eslint-disable */
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";
import * as url from "../../urlhelpers/UrlHelper";
import LoggedInUserMenu from "../common/LoggedInUserMenu";

const HomeHeader = () => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <header className="home_header">
      <div className="header_top">
        <div className="container">
          {/* ----- catching text start ---- */}
          <div className="top_text">
            <p>
              A new era is coming: Ceim Talent is now <b>Findeloi.</b>
              <Link to="#">Learn More</Link>
            </p>
          </div>
          {/* ----- catching text end ---- */}
        </div>
      </div>
      <div className="header_bttm">
        <div className="container">
          <div className="bttm_innr">
            {/* ----- logo ---- */}
            <div className="home_hdrlogo">
              <Link to="/">
                <img src={assetImages.homeLogo} alt="homeLogo" />
              </Link>
            </div>

            {/* ------ header menu start ----- */}
            <div className="header_right">
              {/* ---- menu list start ---- */}
              <div className="menu_list">
                <ul className="list_stye_none">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/experts">Experts</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/chat">Chat</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                </ul>
              </div>
              {/* ---- menu list end ---- */}

              {/* ----- login and registration button start ----- */}
              {token ? null : (
                <div className="login_btns">
                  <Link
                    to="#"
                    className="btn outline"
                    data-bs-toggle="modal"
                    data-bs-target="#login_modal"
                  >
                    Log In
                  </Link>
                  <Link
                    to="#"
                    className="btn black_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#register_mdl"
                  >
                    Sign Up
                  </Link>
                </div>
              )}
              {/* ----- login and registration button end ----- */}

              {token && <LoggedInUserMenu />}
            </div>
            {/* ------ header menu end ----- */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HomeHeader;
