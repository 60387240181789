/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

// import Gateway for API fetch call
import { getData, postData, postFormdata } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../../../../constants";

// importing modal here
import ReviewModal from "./tabmodals/ReviewModal";

const FeedbackTab = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const glowReviewCount = [1, 2];

  const {
    isEventApproved,
    reloadReviewTab,
    setReloadReviewTab,
    isEventModerator,
  } = useContext(GlobalFindelOiProvider);

  // Intitial useState to save all the review from the API
  const [reviewsList, setReviewsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  // Function to get all the review data from API
  const getReviewsHandler = async (id) => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_VIEW_REVIEWS +
      `?token=${token}` +
      `&mod=MOD_EVENT` +
      `&com=${id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setReviewsList(response.data);
        } else {
          setReviewsList([]);
        }
        setIsLoading(false);
      } else {
        setReviewsList([]);
      }
    } catch (error) {}
  };

  //reload this tab after join event and give review
  useEffect(() => {
    if (reloadReviewTab) {
      getReviewsHandler(params.id);
      setReloadReviewTab(false);
    }
  }, [reloadReviewTab]);

  useEffect(() => {
    getReviewsHandler(params.id);
  }, [params.id]);

  return (
    <div className="tab-pane fade show active" id="feedback">
      {isLoading ? (
        <div className="job_detailspnl">
          <div className="contnt_hdng">
            <h4>
              <span className="placeholder" style={{ width: "100px" }}></span>
            </h4>
            <span
              className="placeholder rounded-2"
              style={{ width: "130px", height: "46px" }}
            ></span>
          </div>
          <div className="customer_feedbacks">
            {glowReviewCount.map((count) => {
              return (
                <div className="recomend_row" key={count}>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "50px", height: "50px" }}
                      ></span>
                    </div>
                    <div className="team_usrTxt w-100">
                      <h3>
                        <span className="placeholder col-2"></span>
                      </h3>
                      <h5>
                        <span className="placeholder col-1"></span>
                      </h5>
                      <div className="rating_star">
                        <ul className="list_stye_none">
                          <li className="active">
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li className="active">
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "18px", height: "18px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="forum_para">
                        <p>
                          <span className="placeholder col-12"></span>
                          <span className="placeholder col-10"></span>
                          <span className="placeholder col-8"></span>
                        </p>
                      </div>
                      <div className="feedback_share">
                        <ul className="list_stye_none">
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-2"
                              style={{ width: "35px", height: "35px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="post_time">
                        <span
                          className="placeholder"
                          style={{ width: "80px", height: "14px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="job_detailspnl">
          {/* ---------- Tab Heading and Button starts here ---------- */}
          <div className="contnt_hdng">
            <h4>Feedbacks</h4>
            {isEventApproved || isEventModerator || userInfo.isadmin ? (
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#addreview-modal"
                className="border_btn"
              >
                Add a Review
              </Link>
            ) : null}
          </div>

          {/* ---------- Feedback Section starts here ---------- */}
          {reviewsList.length == 0 ? (
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={assetImages.emptyLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>Sorry....!</h2>
                <p>No reviews available for this event.</p>
              </div>
            </div>
          ) : (
            <div className="customer_feedbacks">
              {/* ---------- Feedback starts here ---------- */}
              <div className="recomend_row">
                {reviewsList.map((review, index) => {
                  return (
                    <div className="team_userOuter forum_usrOuter" key={index}>
                      {/* ---------- User Image starts here ---------- */}
                      <div className="team_usr_pic">
                        <img
                          src={review.userimage != "" ? review.userimage : ""}
                          alt="UserProfilePic"
                        />
                      </div>
                      <div className="team_usrTxt">
                        <h3>{review.user}</h3>
                        <h5>{review.useremail}</h5>

                        {/* ---------- Star rating starts here ---------- */}
                        <div className="rating_star">
                          <ul className="list_stye_none">
                            {_.times(review.globalrating, (i) => (
                              <li className="active" key={i}>
                                <Link to="#">
                                  <i className="material-icons-outlined">
                                    star
                                  </i>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {/* ---------- Message Body starts here ---------- */}
                        <div className="forum_para">
                          <p>{review.comment}</p>
                        </div>

                        {/* ---------- 'Like', 'Reply' & 'Share' starts here ---------- */}
                        <div className="feedback_share">
                          <ul className="list_stye_none">
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">
                                  thumb_up
                                </i>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">chat</i>
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <i className="material-icons-outlined">share</i>
                              </Link>
                            </li>
                          </ul>
                        </div>

                        {/* ---------- Time starts here ---------- */}
                        {/* <div className="post_time">
                        <span>2 months ago</span>
                      </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <ReviewModal />
    </div>
  );
};

export default FeedbackTab;
