/* eslint-disable */

import { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

// importing images here
import { assetImages } from "../../../../../../constants";

// import Gateway for API fetch
import { postData, getData } from "../../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../../context/FindeloiContext";

const ParticipantsFeedbackModal = ({
  eventParticipantsData,
  callModalSurveyAPI,
  afterModalCallApi,
  afterModalClose,
}) => {
  const { getEventName } = useContext(GlobalFindelOiProvider);

  const token = localStorage.getItem("token");

  const params = useParams();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  //custom style for react select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      // fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "2px",
      // fontFamily: "sans-serif",
      border: "1px solid #dddddd",
      boxShadow: "0px 2px 4px rgb(0 0 0 / 10%)",
      backgroundColor: "#fff",
      height: 46,
      // borderRadius: "8px",
      "&:hover": {
        border: "1px solid #dddddd",
      },
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8EA6 !important",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // selected value
    singleValue: (provided) => ({
      ...provided,
      color: "666666",
      fontSize: "16px",
    }),

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  // Initial priviledge status
  const [hasNoPriviledge, setHasNoPriviledge] = useState(false);

  // Initial no-priviledge comment status
  const [noPriviledgeComment, setNoPriviledgeComment] = useState("");

  //initital for eventfeedbackid
  const [eventFeedbackId, setEventFeedbackId] = useState(0);

  // Initial loading status
  const [isLoading, setIsLoading] = useState(false);

  //initial for survey submitting
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Json data of survey feedback api
  const [surveyJson, setSurveyJson] = useState({});

  // Array data of
  const [surveyFeedback, setSurveyFeedback] = useState([]);

  /*---------- Options selection start -----------*/
  const [selectedOptions, setSelectedOptions] = useState(null);

  const optionSelectionHandler = (val, sectionid, questionid, type, e) => {
    let optionsVal = val.map((item) => {
      return item.value;
    });

    if (optionsVal.length != 0) {
      setSelectedOptions();
      handleFormChange(sectionid, questionid, type, e, optionsVal);
    }
  };

  /*---- Options selection end ----*/

  /* ----------------- Single-Select options start ---------------- */
  const [selectedSingleOption, setSelectedSingleOption] = useState("");

  const singleSelectionHandler = (val, sectionid, questionid, type, e) => {
    setSelectedSingleOption();
    // setData({ ...data, status: val.value })
    handleFormChange(sectionid, questionid, type, e, val.value);
  };
  /* ----------------- Single-Select options end ---------------- */

  // function to call the API and get all the courses
  const getFeedbackQuestionsListData = async (mod, com, eventId) => {
    setIsLoading(true);

    let requestURL;

    // let secret = 4593;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_PARTICIPANTS_FEEDBACK_QUESTIONS +
        `?token=${token}` +
        `&mod=${mod}` +
        `&com=${com}` +
        `&id=${eventId}`;
    } else {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_PARTICIPANTS_FEEDBACK_QUESTIONS +
        `?mod=${mod}` +
        `&com=${com}` +
        `&id=${eventId}`;
    }

    console.log(requestURL);

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (
        response.status == true &&
        response.surveyfeedback == null &&
        response.hasAccess == false
      ) {
        setHasNoPriviledge(true);
        setNoPriviledgeComment(response.comment);
      }

      if (response.status) {
        if (response.surveyfeedback) {
          setSurveyJson(response.surveyfeedback);

          let surveyFeedbackData = [];

          response.surveyfeedback.section.map((sectionComponent, indexS) => {
            let sectionFeedbackData = [];

            // map questions ---------------------->
            sectionComponent.questions.map((question, indexQ) => {
              let questionFeedbackData = [];

              questionFeedbackData["answer"] = question.answer;
              questionFeedbackData["comments"] = question.comment;
              questionFeedbackData["questionid"] = question.id;
              questionFeedbackData["sectionid"] = sectionComponent.id;

              sectionFeedbackData[question.id] = questionFeedbackData;
            });

            surveyFeedbackData[sectionComponent.id] = sectionFeedbackData;
          });

          setSurveyFeedback(surveyFeedbackData);
        }

        if (response.eventfeedbackid) {
          setEventFeedbackId(response.eventfeedbackid);
        } else {
          setEventFeedbackId(0);
        }

        if (response.name) {
          setUserName(response.name);
        } else {
          setUserName("");
        }

        if (response.email) {
          setUserEmail(response.email);
        } else {
          setUserEmail("");
        }

        setIsLoading(false);
      }
    } catch (error) {}
  };

  // handle all feedback response for each section and questionid
  // types can be rating, text, number, select, multiselect, comments
  const handleFormChange = (
    sectionid,
    questionid,
    type = "answer",
    e,
    options = []
  ) => {
    let surveyData = [...surveyFeedback];

    if (type == "comments") {
      e.preventDefault();
      surveyData[sectionid][questionid]["comments"] = e.target.value;
    } else if (type == "rating") {
      surveyData[sectionid][questionid]["answer"] = e;
    } else if (type == "multiselect") {
      surveyData[sectionid][questionid]["answer"] = options;
    } else if (type == "number") {
      surveyData[sectionid][questionid]["answer"] = e.target.value;
    } else if (type == "singleselect") {
      surveyData[sectionid][questionid]["answer"] = options;
    } else {
      e.preventDefault();
      surveyData[sectionid][questionid]["answer"] = e.target.value;
    }

    setSurveyFeedback(surveyData);
  };

  // function to handle the answer submission
  const handleAnswerSubmit = async (e) => {
    // e.preventDefault();
    setIsSubmitting(true);
    let feedbackUrl;
    let feedBackSentData;

    let surveyJsonData = arrayToJSON(surveyFeedback);
    // ---------------------- API CALL STARTS FROM BELOW ----------------------
    try {
      feedbackUrl =
        url.API_BaseUrl + url.API_SURVEY_FEEDBACK_SAVE + `?token=${token}`;

      console.log(feedbackUrl);

      feedBackSentData = {
        mod: "MOD_USER",
        com: eventParticipantsData.id,
        surveyid: surveyJson.surveyid,
        eid: params.id,
        efid: eventFeedbackId,
        name: userInfo.name,
        email: userInfo.email,
        surveyFeedback: surveyJsonData,
      };

      console.log(feedBackSentData);

      let response = await postData(feedbackUrl, feedBackSentData);

      console.log("response in feedback----->", response);

      if (response.status) {
        setIsSubmitting(false);
        let feebackNowModal = document.querySelector(
          "#participants_feedback_modal"
        );
        let feedbackModal = bootstrap.Modal.getInstance(feebackNowModal);
        feedbackModal.hide();
        afterModalClose();
        // let myModal = new bootstrap.Modal(
        //   document.getElementById("feedback_success_mdl")
        // );
        // myModal.show();
        getFeedbackQuestionsListData(mod, com, secret);
      }
    } catch (error) {}
  };

  const arrayToJSON = (surveyFeedback) => {
    let surveyJsonData = {};

    surveyFeedback.forEach(function (sectionData, sectionId) {
      if (sectionData == null) {
        return;
      }

      var sectionJsonData = {};

      sectionData.forEach(function (questionData, questionId) {
        var questionJsonData = {};

        if (questionData == null) {
          return;
        }

        for (var fieldName in questionData) {
          questionJsonData[fieldName] = questionData[fieldName];
        }

        sectionJsonData[questionId] = questionJsonData;
      });

      surveyJsonData[sectionId] = sectionJsonData;
    });

    return surveyJsonData;
  };

  useEffect(() => {
    if (callModalSurveyAPI && eventParticipantsData) {
      getFeedbackQuestionsListData(
        "MOD_USER",
        eventParticipantsData.id,
        params.id
      );
      afterModalCallApi();
    }
  }, [callModalSurveyAPI]);

  return (
    <div className="job_modal feedback_modal">
      <div
        data-bs-backdrop="static"
        className="modal fade"
        id="participants_feedback_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header">
              <div className="modal_hdng">
                <h3>Feedback</h3>

                {/* ----- participants and event name start ----- */}
                <h5>
                  to{" "}
                  <span>
                    {/* {eventParticipantsData ? eventParticipantsData.id : null} */}
                    {eventParticipantsData ? eventParticipantsData.name : ""}
                  </span>{" "}
                  for <span>{getEventName}</span>
                </h5>
                {/* ----- participants and event name end ----- */}
              </div>

              {/* ----- modal close button start ----- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  //   console.log(eventParticipantsData);
                }}
              >
                <i className="material-icons-outlined"> close </i>
              </button>
              {/* ----- modal close button end ----- */}
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="feedback_usrTop">
                <div className="expertholder_outer">
                  {/* ----- participants image start ----- */}
                  <div className="experts_holderPic">
                    <img
                      src={
                        eventParticipantsData
                          ? eventParticipantsData.image
                          : assetImages.expert1
                      }
                      alt=""
                    />
                  </div>
                  {/* ----- participants image end ----- */}

                  {/* ----- participants name and position start ----- */}
                  <div className="experts_holdertext">
                    <h3>
                      {eventParticipantsData ? eventParticipantsData.name : ""}{" "}
                      <span>
                        {eventParticipantsData
                          ? eventParticipantsData.position
                          : ""}
                      </span>
                    </h3>
                    <div className="details_tags expert_details">
                      <div className="jb_rating">
                        <span>San Deago,CA USA</span>
                      </div>
                      <div className="jb_rating star_color">
                        <i className="material-icons-outlined"> star </i>
                        <span>
                          {eventParticipantsData
                            ? eventParticipantsData.rating
                            : null}
                        </span>
                        <h6>
                          (
                          {eventParticipantsData
                            ? eventParticipantsData.reviews
                            : null}
                          <em>Reviews</em>)
                        </h6>
                      </div>
                    </div>
                  </div>
                  {/* ----- participants name and position end ----- */}
                </div>

                <div className="experts_holdertext">
                  {hasNoPriviledge ? (
                    <div className="activity_rightHdng mt-3">
                      <h3>Not enough privilege to view the feedback!</h3>
                      {noPriviledgeComment == "" ? null : (
                        <p className="text-danger mt-1">
                          {noPriviledgeComment}
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* ----- survey questions start ----- */}
              {surveyJson.section != null ? (
                <div className="others_detailsArea">
                  {surveyJson.section.map((sectionComponent, index) => {
                    return (
                      <div className="mb-3" key={index}>
                        {/* ----- question type text start ----- */}
                        <div className="details_row evnt_feedbackrow">
                          <div className="details_input_txt">
                            <label>{sectionComponent.title}</label>
                          </div>

                          {sectionComponent.questions != null ? (
                            <div className="p-3">
                              {sectionComponent.questions.length > 0 ? (
                                <div className="">
                                  {sectionComponent.questions.map(
                                    (question, index2) => {
                                      return (
                                        <div key={index2}>
                                          <p className="my-2">
                                            {question.label}
                                          </p>
                                          {question.type == "rating" ? (
                                            <div className="input_fill jobinput_fill description_fill">
                                              <ul className="gray_rating list_stye_none mb-2">
                                                <li
                                                  className={
                                                    surveyFeedback[
                                                      sectionComponent.id
                                                    ][question.id]["answer"] >=
                                                    1
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={
                                                      question.hasreviewed ==
                                                      false
                                                        ? (e) => {
                                                            handleFormChange(
                                                              sectionComponent.id,
                                                              question.id,
                                                              "rating",
                                                              1
                                                            );
                                                          }
                                                        : (e) => {}
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      {" "}
                                                      star{" "}
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  className={
                                                    surveyFeedback[
                                                      sectionComponent.id
                                                    ][question.id]["answer"] >=
                                                    2
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={
                                                      question.hasreviewed ==
                                                      false
                                                        ? (e) => {
                                                            handleFormChange(
                                                              sectionComponent.id,
                                                              question.id,
                                                              "rating",
                                                              2
                                                            );
                                                          }
                                                        : (e) => {}
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      {" "}
                                                      star{" "}
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  className={
                                                    surveyFeedback[
                                                      sectionComponent.id
                                                    ][question.id]["answer"] >=
                                                    3
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={
                                                      question.hasreviewed ==
                                                      false
                                                        ? (e) => {
                                                            handleFormChange(
                                                              sectionComponent.id,
                                                              question.id,
                                                              "rating",
                                                              3
                                                            );
                                                          }
                                                        : (e) => {}
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      {" "}
                                                      star{" "}
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  className={
                                                    surveyFeedback[
                                                      sectionComponent.id
                                                    ][question.id]["answer"] >=
                                                    4
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={
                                                      question.hasreviewed ==
                                                      false
                                                        ? (e) => {
                                                            handleFormChange(
                                                              sectionComponent.id,
                                                              question.id,
                                                              "rating",
                                                              4
                                                            );
                                                          }
                                                        : (e) => {}
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      {" "}
                                                      star{" "}
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  className={
                                                    surveyFeedback[
                                                      sectionComponent.id
                                                    ][question.id]["answer"] ==
                                                    5
                                                      ? "active"
                                                      : ""
                                                  }
                                                >
                                                  <Link
                                                    to="#"
                                                    onClick={
                                                      question.hasreviewed ==
                                                      false
                                                        ? (e) => {
                                                            handleFormChange(
                                                              sectionComponent.id,
                                                              question.id,
                                                              "rating",
                                                              5
                                                            );
                                                          }
                                                        : (e) => {}
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      {" "}
                                                      star{" "}
                                                    </span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                          ) : question.type == "multiselect" ? (
                                            <div>
                                              {question.hasreviewed == false ? (
                                                <div className="model_selectBx mb-3">
                                                  <Select
                                                    placeholder="Select multiple options"
                                                    options={
                                                      question.answeroptions
                                                    }
                                                    value={selectedOptions}
                                                    isMulti={true}
                                                    onChange={(val, e) => {
                                                      optionSelectionHandler(
                                                        val,
                                                        sectionComponent.id,
                                                        question.id,
                                                        question.type,
                                                        e
                                                      );
                                                    }}
                                                    // classNamePrefix="select2-selection"
                                                    className="select2"
                                                    // className="select2 form-select shadow-none rounded-0"
                                                    styles={customSelectStyle}
                                                  />
                                                </div>
                                              ) : (
                                                <div className="details_row evnt_feedbackrow">
                                                  <div className="details_input_txt">
                                                    <label htmlFor="">
                                                      Answer
                                                    </label>
                                                  </div>
                                                  <div className="response">
                                                    <p>{question.answer}</p>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ) : question.type == "textbox" ? (
                                            <div className="login_fillOuter">
                                              {question.hasreviewed == false ? (
                                                <div className="mb-3 input_fill">
                                                  <input
                                                    type="text"
                                                    placeholder="Type Your Answer"
                                                    className="form-control shadow-none rounded-0"
                                                    value={
                                                      surveyFeedback[
                                                        sectionComponent.id
                                                      ][question.id]["answer"]
                                                        ? surveyFeedback[
                                                            sectionComponent.id
                                                          ][question.id][
                                                            "answer"
                                                          ]
                                                        : ""
                                                    }
                                                    onChange={(e) =>
                                                      handleFormChange(
                                                        sectionComponent.id,
                                                        question.id,
                                                        "answer",
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div className="details_row evnt_feedbackrow">
                                                  <div className="details_input_txt">
                                                    <label htmlFor="">
                                                      Answer
                                                    </label>
                                                  </div>
                                                  <div className="response">
                                                    <p>{question.answer}</p>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ) : question.type == "numeric" ? (
                                            <div className="login_fillOuter">
                                              {question.hasreviewed == false ? (
                                                <div className="mb-3 input_fill">
                                                  <input
                                                    type="number"
                                                    min={0}
                                                    placeholder="Enter a number"
                                                    className="form-control shadow-none rounded-0 pe-3"
                                                    value={
                                                      surveyFeedback[
                                                        sectionComponent.id
                                                      ][question.id]["answer"]
                                                        ? surveyFeedback[
                                                            sectionComponent.id
                                                          ][question.id][
                                                            "answer"
                                                          ]
                                                        : 0
                                                    }
                                                    onChange={(e) =>
                                                      handleFormChange(
                                                        sectionComponent.id,
                                                        question.id,
                                                        "number",
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              ) : (
                                                <div className="details_row evnt_feedbackrow">
                                                  <div className="details_input_txt">
                                                    <label htmlFor="">
                                                      Answer
                                                    </label>
                                                  </div>
                                                  <div className="response">
                                                    <p>{question.answer}</p>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ) : question.type ==
                                            "singleselect" ? (
                                            <div>
                                              {question.hasreviewed == false ? (
                                                <div className="mb-2">
                                                  <Select
                                                    value={selectedSingleOption}
                                                    onChange={(val, e) => {
                                                      singleSelectionHandler(
                                                        val,
                                                        sectionComponent.id,
                                                        question.id,
                                                        question.type,
                                                        e
                                                      );
                                                    }}
                                                    options={
                                                      question.answeroptions
                                                    }
                                                    styles={customSelectStyle}
                                                    //classNamePrefix="select2-selection"
                                                    placeholder="Select option"
                                                    className="select2"
                                                  />
                                                </div>
                                              ) : (
                                                <div className="details_row evnt_feedbackrow">
                                                  <div className="details_input_txt">
                                                    <label htmlFor="">
                                                      Answer
                                                    </label>
                                                  </div>
                                                  <div className="response">
                                                    <p>{question.answer}</p>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                        {/* ----- question type text end ----- */}
                      </div>
                    );
                  })}
                  <div className="details_row evnt_feedbackrow">
                    <div className="apply_btn">
                      <button
                        className="basic_btn"
                        disabled={isSubmitting ? true : false}
                        style={{
                          cursor: isSubmitting ? "not-allowed" : "pointer",
                        }}
                        onClick={(e) => handleAnswerSubmit(e)}
                      >
                        Post Feedback
                        {isSubmitting && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="others_detailsArea">{null}</div>
              )}
              {/* ----- survey questions end ----- */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantsFeedbackModal;
