/* eslint-disable */
import { Link } from "react-router-dom";

//import react carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// importing images here
import { assetImages } from "../../constants";

const HomeEcosystem = () => {
  const CardResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="ecosystem_area pd_80">
      <div className="container">
        <div className="page_heading text-center">
          <h5>Best Ecosystems</h5>
          <h2>Ecosystems we work</h2>
        </div>
        <div className="ecosystem_slide_outer">
          <div className="ecosystem_slider">
            <Carousel ssr responsive={CardResponsive}>
              <div className="ecosystem_slideItem">
                <div className="system_logoitem">
                  <div className="system_logo_pic">
                    <img src={assetImages.systemLogo1} alt="systemLogo1" />
                  </div>
                  <div className="syatem_logo_details">
                    <h3>CEIM</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Companies </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Challenges </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Projects </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Success Stories</span>
                      </li>
                    </ul>
                    <div className="more_list">
                      <Link to="#">+ 5 more</Link>
                    </div>
                    <div className="syatem_detailsbtn">
                      <Link to="#" className="border_btn outline_red">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem_slideItem">
                <div className="system_logoitem">
                  <div className="system_logo_pic">
                    <img src={assetImages.systemLogo2} alt="systemLogo2" />
                  </div>
                  <div className="syatem_logo_details">
                    <h3>Madrid Network</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Companies </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Challenges </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Projects </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Success Stories</span>
                      </li>
                    </ul>
                    <div className="more_list">
                      <Link to="#">+ 5 more</Link>
                    </div>
                    <div className="syatem_detailsbtn">
                      <Link to="#" className="border_btn outline_red">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem_slideItem">
                <div className="system_logoitem">
                  <div className="system_logo_pic">
                    <img src={assetImages.systemLogo3} alt="systemLogo3" />
                  </div>
                  <div className="syatem_logo_details">
                    <h3>Madrid</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Companies </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Challenges </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Projects </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Success Stories</span>
                      </li>
                    </ul>
                    <div className="more_list">
                      <Link to="#">+ 5 more</Link>
                    </div>
                    <div className="syatem_detailsbtn">
                      <Link to="#" className="border_btn outline_red">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem_slideItem">
                <div className="system_logoitem">
                  <div className="system_logo_pic">
                    <img src={assetImages.systemLogo4} alt="" />
                  </div>
                  <div className="syatem_logo_details">
                    <h3>MIDE</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Companies </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Challenges </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Projects </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Success Stories</span>
                      </li>
                    </ul>
                    <div className="more_list">
                      <Link to="#">+ 5 more</Link>
                    </div>
                    <div className="syatem_detailsbtn">
                      <Link to="#" className="border_btn outline_red">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ecosystem_slideItem">
                <div className="system_logoitem">
                  <div className="system_logo_pic">
                    <img src={assetImages.systemLogo2} alt="systemLogo2" />
                  </div>
                  <div className="syatem_logo_details">
                    <h3>Madrid Network</h3>
                    <ul className="list_stye_none">
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Companies </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Challenges </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Projects </span>
                      </li>
                      <li>
                        <i className="material-icons-outlined">check_circle</i>
                        <span>Success Stories</span>
                      </li>
                    </ul>
                    <div className="more_list">
                      <Link to="#">+ 5 more</Link>
                    </div>
                    <div className="syatem_detailsbtn">
                      <Link to="#" className="border_btn outline_red">
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeEcosystem;
