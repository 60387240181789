/* eslint-disable */
import { useState, useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

//import context
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../../../../constants";

// import Gateway for API fetch call
import {
  getData,
  postData,
  // postActivityFormData,
  uploadMultipleFile,
  postFormdata,
} from "../../../../../utils/Gateway.js";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

const ForumTab = () => {
  const { isEventApproved, isEventModerator } = useContext(
    GlobalFindelOiProvider
  );

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const params = useParams();
  const isGlobal = 0;
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(0);
  //initial for validation
  const [titleError, setTitleError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [queryLists, setQueryLists] = useState([]);
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);

  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  // initial state for posting load
  const [isSubmiting, setIsSubmiting] = useState(false);

  //initial for upload bigger file
  const [fileIsBig, setFileIsBig] = useState(false);

  // initial for uploading
  const [isUploading, setIsUploading] = useState(false);
  const [forumReplyMessage, setForumReplyMessage] = useState("");
  //initial for empty message
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [joinCourseLoading, setJoinCourseLoading] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [commentIndex, setCommentIndex] = useState(null);
  const [isCommenting, setIsCommenting] = useState(false);

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    setFileIsBig(false);

    const selectedFIles = []; // initial empty values for file array

    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        setIsUploading(true);
        let requestURL =
          url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

        try {
          const response = await uploadMultipleFile(requestURL, targetFiles);

          if (response.status) {
            let fileResponseId = response.data.map((data) => {
              return data.id;
            });
            if (fileIds.length == 0) {
              setFileIds(fileResponseId);
            } else {
              let fileArr = fileIds.concat(fileResponseId);
              setFileIds(fileArr);
            }
          }

          const targetFilesObject = [...targetFiles]; // store array of those files

          // check condition whether file array is empty or not
          if (media.length == 0) {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            setMedia(selectedFIles); // changing state of files array
          } else {
            targetFilesObject.map((file) => {
              return selectedFIles.push(file);
            });

            // changing state of files array with previous file array values
            setMedia((media) => [...media, ...selectedFIles]);
          }
          setIsUploading(false);
        } catch (error) {}
      } else {
        setFileIsBig(true);
      }
    } else {
      return;
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("eventForumFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // reset function for reset all fields
  const resetQuery = () => {
    setTitle("");
    setQuery("");
    resetFile();
    setFileIds([]);
    setMedia([]);
  };

  //---------------------- Function to submit the question --------------------
  const saveQuery = async () => {
    if (title == "" || query == "") {
      if (title == "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }
      if (query == "") {
        setQueryError(true);
      } else {
        setQueryError(false);
      }
    } else {
      setTitleError(false);
      setQueryError(false);
      setIsSubmiting(true);
      let requestURL =
        url.API_BaseUrl + url.API_ACTIVITY_CREATE_POST + `?token=${token}`;

      let queryData = {
        isglobal: isGlobal,
        events: params.id,
        title,
        message: query,
        media: fileIds,
      };

      try {
        const response = await postFormdata(requestURL, queryData);

        if (response.status) {
          resetQuery();
          setIsSubmiting(false);
          getQuery(params.id);
        }
      } catch (error) {}
    }
  };

  // function for get all forum lists
  const getQuery = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_FORUM_LIST +
      `?token=${token}` +
      `&mod=event` +
      `&com=${params.id}`;

    try {
      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setQueryLists(response.data);
        } else {
          setQueryLists([]);
        }
      } else {
        setQueryLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  // function for display question panel
  const toggleComment = (index) => {
    if (showCommentBox == false) {
      setShowCommentBox(true);
    } else {
      setShowCommentBox(false);
    }

    if (commentIndex == null) {
      setCommentIndex(index);
    } else {
      setCommentIndex(null);
    }
  };

  // function for like
  const forumLikeHandler = async (postId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST +
      `?token=${token}` +
      `&postid=${postId}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        getQuery(params.id);
      }
    } catch (error) {}
  };

  // function for parent like
  const likeCommentHandler = async (commentId) => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_LIKE_POST_COMMENT +
      `?token=${token}` +
      `&commentid=${commentId}`;

    try {
      const response = await getData(requestURL);
      if (response.status) {
        getQuery(params.id);
      }
    } catch (error) {}
  };

  // function for post comment
  const replyToForum = async (postId, index) => {
    if (forumReplyMessage == "") {
      setShowEmptyMessage(true);
      return;
    } else {
      setIsCommenting(true);
      setShowEmptyMessage(false);
      let commentUrl =
        url.API_BaseUrl +
        url.API_ACTIVITY_POST_ANSWER +
        `${postId}?token=${token}`;

      let messageData = {
        message: forumReplyMessage,
      };

      setForumReplyMessage("");

      try {
        const response = await postData(commentUrl, messageData);

        if (response.status) {
          getQuery(params.id);
          toggleComment(index);
          setIsCommenting(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  //reload this tab after join event
  // useEffect(() => {
  //   if (reloadForumTab) {
  //     getQuery(params.id);
  //     setReloadForumTab(false);
  //   }
  // }, [reloadForumTab]);

  useEffect(() => {
    getQuery();
  }, []);

  return (
    <div className="tab-pane fade show active" id="evnt_forum">
      {isEventApproved || isEventModerator || userInfo.isadmin ? (
        <div className="job_detailspnl">
          {/* ---------- Tab title starts here ---------- */}
          {isLoading ? (
            <div className="contnt_hdng">
              <h4>
                <span className="placeholder" style={{ width: "60px" }}></span>
              </h4>
            </div>
          ) : (
            <div className="contnt_hdng">
              <h4>Forum</h4>
            </div>
          )}

          {/* ---------- In starts here ---------- */}
          {isLoading ? (
            <div className="title_fill">
              <span
                className="placeholder col-12 rounded-3"
                style={{ height: "50px" }}
              ></span>
            </div>
          ) : (
            <div className="title_fill">
              <input
                type="text"
                className="form-control"
                placeholder="Enter forum title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {titleError && (
                <p className="error mt-1">* Please Fill up Title</p>
              )}
            </div>
          )}
          {isLoading ? (
            <div className="form_commnts_area">
              <span
                className="placeholder col-12 rounded-3"
                style={{ height: "115px" }}
              ></span>
            </div>
          ) : (
            <div className="form_commnts_area">
              <textarea
                className="form-control"
                placeholder="Write your query here"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              ></textarea>
              <div className="furmcmnt_btn">
                <button className="cmnt_btn">
                  <input
                    type="file"
                    className="file_btn"
                    id="eventForumFile"
                    onChange={multipleFileUploadHandler}
                    multiple
                  />
                  <span className="material-icons-outlined">image</span>
                </button>
                <button
                  className="cmnt_btn"
                  disabled={isSubmiting ? true : false}
                  style={{
                    cursor: isSubmiting ? "not-allowed" : "pointer",
                  }}
                  onClick={saveQuery}
                >
                  <span className="material-icons-outlined">send</span>
                </button>
              </div>
              {queryError && (
                <p className="error mt-1">* Please Fill up Details</p>
              )}
            </div>
          )}

          {/* ----- loading area for file uploading ----- */}
          {isUploading && (
            <div className="loading_sec">
              <p className="ms-3 me-1">Uploading Files</p>
              {spinCount.map((item) => {
                return (
                  <div
                    key={item}
                    className="spinner-grow mx-1"
                    style={{ height: "6px", width: "6px" }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                );
              })}
            </div>
          )}
          {/* ----- loading area for file uploading end ----- */}

          {/* ----- uploaded image section start ------ */}
          <div className="files_upload_info">
            {media.map((files, index) => {
              return (
                <div className="files_uploadItem" key={index}>
                  <span className="uploadfiles_pic">
                    {files.type == "image/jpeg" || files.type == "image/png" ? (
                      <img src={URL.createObjectURL(files)} alt="ImageFile" />
                    ) : null}
                    {files.type == "text/plain" ||
                    files.type ==
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                      <img src={assetImages.docs} alt="docs" />
                    ) : null}
                    {files.type ==
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    files.type == "application/msword" ? (
                      <img src={assetImages.docs} alt="docs" />
                    ) : null}
                    {files.type == "application/pdf" ? (
                      <img src={assetImages.pdf} alt="docs" />
                    ) : null}
                    {files.type ==
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                    files.type == "application/vnd.ms-excel" ? (
                      <img src={assetImages.xls} alt="docs" />
                    ) : null}
                  </span>
                  <div className="uploadfiles_text">
                    <h5>{files.name}</h5>
                  </div>
                </div>
              );
            })}
          </div>
          {/* ----- uploaded image section end ------ */}

          {/* ---------- Responses section starts here ---------- */}
          {isLoading ? (
            <div className="rply_bxArea">
              <div className="reply_forumBx">
                <div className="forum_cmntsRow">
                  <div className="share_btn forum_shreBtn">
                    <Link to="#" className="overflow-hidden">
                      <span
                        className="placeholder"
                        style={{ width: "100%", height: "100%" }}
                      ></span>
                    </Link>
                  </div>
                  <div className="team_userOuter forum_usrOuter">
                    <div className="team_usr_pic">
                      <span
                        className="placeholder rounded-circle"
                        style={{ width: "50%", height: "50%" }}
                      ></span>
                    </div>
                    <div className="team_usrTxt flex-fill">
                      <h3>
                        <span
                          className="placeholder"
                          style={{ width: "100px" }}
                        ></span>
                      </h3>
                      <h5>
                        <span
                          className="placeholder"
                          style={{ width: "80px" }}
                        ></span>
                      </h5>
                      <div className="forum_para">
                        <p>
                          <span
                            className="placeholder col-12"
                            style={{ height: "14px" }}
                          ></span>
                          <span
                            className="placeholder col-10"
                            style={{ height: "14px" }}
                          ></span>
                          <span
                            className="placeholder col-8"
                            style={{ height: "14px" }}
                          ></span>
                          <span
                            className="placeholder col-4"
                            style={{ height: "14px" }}
                          ></span>
                        </p>
                      </div>
                      <div className="forumpic_outer">
                        <div className="forum_pic">
                          <span
                            className="placeholder rounded-3"
                            style={{ width: "130%", height: "85%" }}
                          ></span>
                        </div>
                        <div className="forum_pic">
                          <span
                            className="placeholder rounded-3"
                            style={{ width: "130%", height: "85%" }}
                          ></span>
                        </div>
                      </div>
                      <div className="form_share">
                        <span
                          className="placeholder rounded-2"
                          style={{ width: "100%", height: "38%" }}
                        ></span>
                        <span
                          className="placeholder rounded-2"
                          style={{ width: "130%", height: "38%" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="rply_bxArea">
              {/* ---------- Replies in grey here ---------- */}
              {/* <div className="reply_forumBx forum_grayBx"/> */}
              <div className="reply_forumBx">
                {queryLists.length === 0 ? (
                  <div>
                    <div className="empty_lessonsArea">
                      <div className="empty_lessonpic">
                        <img src={assetImages.emptyLesson} alt="" />
                      </div>
                      <div className="empty_lessons_text">
                        <h2>Sorry....!</h2>
                        <p>Forums are not available at this moment.</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {queryLists.map((queries, index) => {
                      return (
                        <div className="forum_cmntsRow" key={index}>
                          {/* ----- share button start ----- */}
                          <div className="share_btn forum_shreBtn">
                            <Link to="#">
                              <span className="material-icons-outlined">
                                share
                              </span>
                            </Link>
                          </div>
                          {/* ----- share button end ----- */}

                          <div className="team_userOuter forum_usrOuter">
                            <div className="team_usr_pic">
                              <img
                                src={
                                  queries.moderator.thumbnail
                                    ? queries.moderator.thumbnail
                                    : assetImages.defaultUser
                                }
                                alt=""
                              />
                            </div>
                            <div className="team_usrTxt">
                              <h3>{queries.moderator.name}</h3>
                              <h5>{queries.created}</h5>
                              <div className="forum_para">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: queries.description,
                                  }}
                                ></p>
                              </div>
                              <div className="forumpic_outer">
                                {queries.media.map((mediaSrc, index2) => {
                                  return (
                                    <div
                                      className="forum_pic"
                                      key={index2}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        window.open(mediaSrc, "_blank")
                                      }
                                    >
                                      {mediaSrc.split(".").pop() == "jpg" ||
                                      mediaSrc.split(".").pop() == "JPG" ||
                                      mediaSrc.split(".").pop() == "png" ? (
                                        <img src={mediaSrc || ""} alt="" />
                                      ) : (
                                        <div>
                                          {mediaSrc.split(".").pop() ==
                                          "pdf" ? (
                                            <img
                                              src={assetImages.pdf}
                                              alt="image"
                                            />
                                          ) : (
                                            <div>
                                              {mediaSrc.split(".").pop() ==
                                                "xlsx" ||
                                              mediaSrc.split(".").pop() ==
                                                "xls" ? (
                                                <img
                                                  src={assetImages.xls}
                                                  alt="image"
                                                />
                                              ) : (
                                                <div>
                                                  {mediaSrc.split(".").pop() ==
                                                    "docx" ||
                                                  mediaSrc.split(".").pop() ==
                                                    "txt" ||
                                                  mediaSrc.split(".").pop() ==
                                                    "doc" ? (
                                                    <img
                                                      src={assetImages.docs}
                                                      alt="image"
                                                    />
                                                  ) : null}
                                                </div>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>

                              {/* ----- like and comment button start ----- */}
                              <div className="form_share">
                                <Link
                                  to="#"
                                  onClick={() => forumLikeHandler(queries.id)}
                                >
                                  <i className="material-icons-outlined">
                                    thumb_up
                                  </i>
                                  <em>Likes</em>
                                  {queries.likes === 0 ? null : (
                                    <span>{queries.likes}</span>
                                  )}
                                </Link>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleComment(index);
                                  }}
                                >
                                  <i className="material-icons-outlined">
                                    chat
                                  </i>
                                  <em>Comment</em>
                                  {queries.comments.count === 0 ? null : (
                                    <span>{queries.comments.count}</span>
                                  )}
                                </Link>
                              </div>
                              {/* ----- like and comment button end ----- */}

                              {/* ------ reply comment section start ----- */}
                              {showCommentBox && commentIndex == index ? (
                                <div className="mt-3">
                                  {/* ----- reply comment box start ----- */}
                                  <div className="comnts_newbbx mx-0">
                                    <div className="contnt_hdng tab_Hdng">
                                      <h4>Comment Now</h4>
                                    </div>
                                    <div className="form_commnts_area">
                                      <textarea
                                        className="form-control"
                                        placeholder="Write your query here"
                                        value={forumReplyMessage}
                                        onChange={(e) => {
                                          setShowEmptyMessage(false);
                                          setForumReplyMessage(e.target.value);
                                        }}
                                      ></textarea>
                                      <div className="furmcmnt_btn">
                                        {/* <button className="cmnt_btn">
                                    <input type="file" className="file_btn" />
                                    <span className="material-icons-outlined">
                                      image
                                    </span>
                                  </button> */}
                                        <button
                                          className="cmnt_btn"
                                          disabled={isCommenting ? true : false}
                                          style={{
                                            cursor: isCommenting
                                              ? "not-allowed"
                                              : "pointer",
                                          }}
                                          onClick={() => {
                                            replyToForum(queries.id, index);
                                          }}
                                        >
                                          <span className="material-icons-outlined">
                                            send
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    {commentIndex == index ? (
                                      <p className="error mt-1 ">
                                        {" "}
                                        {showEmptyMessage && (
                                          <span>* Please add a comment</span>
                                        )}
                                      </p>
                                    ) : null}
                                  </div>

                                  {/* ----- reply comment box end ----- */}

                                  {/* ----- reply comment list start ----- */}
                                  {queries.comments.records.length > 0 && (
                                    <div className="reply_commntbx_innr mt-3">
                                      {queries.comments.records.map(
                                        (commentElements, index2) => {
                                          return (
                                            <div
                                              className="forum_cmntsRow p-0"
                                              key={index2}
                                            >
                                              <div className="share_btn forum_shreBtn">
                                                <Link to="#">
                                                  <span className="material-icons-outlined">
                                                    share
                                                  </span>
                                                </Link>
                                              </div>
                                              <div className="team_userOuter forum_usrOuter">
                                                <div className="team_usr_pic">
                                                  <img
                                                    src={
                                                      commentElements.senderimage ||
                                                      assetImages.defaultUser
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="team_usrTxt">
                                                  <h3>
                                                    {commentElements.sender}
                                                  </h3>
                                                  <h5>
                                                    {commentElements.created}
                                                  </h5>
                                                  <div className="forum_para">
                                                    <p>
                                                      {commentElements.message}
                                                    </p>
                                                  </div>

                                                  <div className="form_share">
                                                    <Link
                                                      to="#"
                                                      onClick={() =>
                                                        likeCommentHandler(
                                                          commentElements.id
                                                        )
                                                      }
                                                    >
                                                      <i className="material-icons-outlined">
                                                        thumb_up
                                                      </i>
                                                      {commentElements.likes ==
                                                      0 ? null : (
                                                        <span>
                                                          {
                                                            commentElements.likes
                                                          }
                                                        </span>
                                                      )}
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                  {/* ----- reply comment list end ----- */}
                                </div>
                              ) : null}
                              {/* ------ reply comment section end ----- */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* ---------- Reply 1 starts here ---------- */}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="job_detailspnl">
          <div className="contnt_hdng">
            <h4>Forum</h4>
          </div>

          <div className="d-flex justify-content-center">
            <div className="empty_lessonsArea">
              <div className="empty_lessonpic">
                <img src={assetImages.emptyLesson} alt="blanklesson" />
              </div>
              <div className="empty_lessons_text">
                <h2>Sorry....!</h2>
                <p className="mx-2">You dont have permission</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForumTab;
