/* eslint-disable */
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

const HomeOption = () => {
  return (
    <section className="option_explore_sec pd_80">
      <div className="container">
        <div className="page_heading text-center">
          <h5>OPTIONS TO EXPLORE</h5>
          <h2>
            4 opciones para gestionar <br />
            la innovación
          </h2>
        </div>
        <div className="option_bxOuter">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="option_bxItem">
                <div className="option_pic">
                  <img src={assetImages.optionPic1} alt="optionPic1" />
                </div>
                <div className="option_textBtn">
                  <h3>Challenges</h3>
                  <Link to="#" className="border_btn outline_red">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="option_bxItem">
                <div className="option_pic">
                  <img src={assetImages.optionPic2} alt="" />
                </div>
                <div className="option_textBtn">
                  <h3>Projects</h3>
                  <Link to="#" className="border_btn outline_red">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="option_bxItem">
                <div className="option_pic">
                  <img src={assetImages.optionPic3} alt="" />
                </div>
                <div className="option_textBtn">
                  <h3>Competition</h3>
                  <Link to="#" className="border_btn outline_red">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="option_bxItem">
                <div className="option_pic">
                  <img src={assetImages.optionPic4} alt="" />
                </div>
                <div className="option_textBtn">
                  <h3>Programs</h3>
                  <Link to="#" className="border_btn outline_red">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="registration_text">
          <div className="page_heading text-center">
            <h2>
              El registro en <br />
              findelOI es gratuito
            </h2>
            <h4>
              Si tienes algo que compartir que requiere colaboración regístrate
            </h4>
          </div>
          <Link to="#" className="btn">
            Discover our IO of 10
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeOption;
