/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Recieved from "./Tabs/Recieved";
import Sent from "./Tabs/Sent";

const InvitationMain = (props) => {
  const [showRecievedTab, setShowRecievedTab] = useState(true);
  const [showSentTab, setShowSentTab] = useState(false);
  const [glowLoading, setGlowLoading] = useState(false);

  // function for toggle recieved tab
  const toggleRecievedTab = () => {
    setShowRecievedTab(true);
    setShowSentTab(false);
  };

  // function for toggle sent tab
  const toggleSentTab = () => {
    setShowRecievedTab(false);
    setShowSentTab(true);
  };

  const isLoading = (data) => {
    if (data) {
      setGlowLoading(true);
    } else {
      setGlowLoading(false);
    }
  };

  return (
    <section className="invitation_sec">
      <div className="container">
        <div className="invitation_bx">
          <div className="invite_hdng">
            <h3>Manage invitations</h3>
          </div>
          <div className="invitation_tabs_area">
            {/* ---- tab names ---- */}

            <div className="invite_tablinks">
              {glowLoading ? (
                <div className="default_usr_name mb-20">
                  <span className="placeholder placeholder-sm col-1 rounded-3"></span>
                  <span className="placeholder placeholder-sm col-1 rounded-3"></span>
                </div>
              ) : (
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${
                        showRecievedTab ? "active show" : ""
                      }`}
                      data-bs-toggle="tab"
                      to="#"
                      // to="#received-tab"
                      onClick={toggleRecievedTab}
                    >
                      Received
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${showSentTab ? "active show" : ""}`}
                      data-bs-toggle="tab"
                      to="#"
                      // to="#sent-tab"
                      onClick={toggleSentTab}
                    >
                      Sent
                    </Link>
                  </li>
                </ul>
              )}
            </div>

            {/* ---- tab names ---- */}

            {/* ---- tab content here ---- */}
            <div className="tab-content">
              {/* ---- recieved tab start ---- */}
              <div
                className={`tab-pane fade ${
                  showRecievedTab ? "show active" : ""
                }`}
                id="received-tab"
              >
                {showRecievedTab && <Recieved isLoading={isLoading} />}
              </div>
              {/* ---- recieved tab end ---- */}

              {/* ---- sent tab start ---- */}
              <div
                className={`tab-pane fade ${showSentTab ? "show active" : ""}`}
                id="sent-tab"
              >
                {showSentTab && <Sent isLoading={isLoading} />}
              </div>
              {/* ---- sent tab end ---- */}
            </div>
            {/* ---- tab content end ---- */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvitationMain;
