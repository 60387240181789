/* eslint-disable */
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

const HomeBlog = () => {
  const token = localStorage.getItem("token");

  let page = 1;

  const glowCount = [1, 2, 3];

  const [blogLists, setBlogLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for get all blog list
  const getBlogLists = async () => {
    try {
      setIsLoading(true);
      let requestURL;

      if (token) {
        requestURL = url.API_BaseUrl + url.API_BLOG_LISTS + `?token=${token}`;
      } else {
        requestURL = url.API_BaseUrl + url.API_BLOG_LISTS;
      }

      const response = await getData(requestURL);

      // console.log("Home blog response----->", response);

      if (response.status) {
        if (response.data) {
          setBlogLists(response.data);
        } else {
          setBlogLists([]);
        }
      } else {
        setBlogLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    getBlogLists();
  }, []);

  return (
    <section className="blog_area pd_80">
      <div className="container">
        <div className="blog_hdngOuter mb-4">
          <div className="page_heading mb-0">
            <h2>From the blog</h2>
          </div>
          <div className="view_allabtn">
            <Link to="/blogs" className="btn outline">
              View All
            </Link>
          </div>
        </div>

        {/* ---- main blog list start ---- */}
        {isLoading ? (
          <div className="blog_outer">
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={count}>
                    <div className="blog_item">
                      <div className="blog_pic">
                        <span
                          className="placeholder col-12"
                          style={{ height: "197px" }}
                        ></span>
                      </div>
                      <div className="blog_textpnl">
                        <h5 className="d-flex justify-content-between">
                          <span className="placeholder placeholder-md rounded-2 col-3"></span>
                          <span className="placeholder placeholder-md rounded-2 col-3"></span>
                        </h5>

                        <p>
                          <span
                            className="placeholder rounded-2 col-6 mt-2"
                            style={{ height: "20px", width: "100%" }}
                          ></span>
                        </p>
                        <p>
                          <span
                            className="placeholder rounded-2 col-6 mt-2"
                            style={{ height: "20px", width: "100%" }}
                          ></span>
                        </p>

                        <div className="details_btn">
                          <span
                            className="placeholder rounded-3 col-12"
                            style={{ height: "50px" }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="blog_outer">
            {blogLists.length === 0 ? null : (
              <div className="row">
                {blogLists.slice(0, 3).map((blog, index) => {
                  return (
                    <div className="col-lg-4 col-md-6" key={index}>
                      <div className="blog_item">
                        <div className="blog_pic h-auto">
                          <Link to="#">
                            <img
                              src={
                                blog.image == null
                                  ? index == 0
                                    ? assetImages.blogPic1
                                    : index == 1
                                    ? assetImages.blogPic2
                                    : assetImages.blogPic3
                                  : blog.image
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="blog_textpnl">
                          <h5>
                            {blog.author.name}{" "}
                            <span className="ms-5">{blog.created}</span>
                          </h5>
                          {blog.title == null ||
                          blog.title == undefined ? null : (
                            <Link to="#" className="blog_hdng">
                              {blog.title}
                            </Link>
                          )}

                          {blog.category.length == 0 ? null : (
                            <div className="skill_menu mb-2">
                              <ul className="list_stye_none">
                                {blog.category.map((cat, index2) => {
                                  return (
                                    <li key={index2}>
                                      <Link to="#" className="blog_category">
                                        {cat.label}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {blog.summary == null ? null : <p>{blog.summary}</p>}
                          <div className="read_norebtn">
                            <Link
                              to={`/blog/${blog.slug}`}
                              className="btn outline"
                            >
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {/* ----- blog list end ----- */}
      </div>
    </section>
  );
};

export default HomeBlog;
