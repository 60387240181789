// importing components here
import EventDetailsRightPanel from "./eventdetailsrightpanel/EventDetailsRightPanel";
import EventDetailsLeftPanel from "./eventdetailsleftpanel/EventDetailsLeftPanel";

const EventDetailsArea = () => {
  return (
    <div className="row">
      {/* ---------------- Left side component ---------------- */}
      <EventDetailsLeftPanel />

      {/* ---------------- Right side component ---------------- */}
      <EventDetailsRightPanel />
    </div>
  );
};

export default EventDetailsArea;
