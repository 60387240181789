/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing custom components
// import EmptyMessage from "components/ConversationComponents/Others/EmptyMessage";
import LeftPanel from "./LeftPanel";
import ContactRightPanel from "./ContactRightPanel";
import GroupRightPanel from "./GroupRightPanel";
// import ChatInfoSignout from "components/ConversationComponents/Others/ChatInfoSignout";
// import UserMenu from "common/UserMenu";
import Welcome from "../../components/ConversationComponents/Others/Welcome";

const ConversationScreen = () => {
  // getting the loggedin user's token herek
  const token = localStorage.getItem("token");

  // initial state to save received props data
  const [chatInfo, setChatInfo] = useState(null);

  // initial state for set chat id
  const [chatId, setChatId] = useState(null);
  const [mailId, setMailId] = useState(null);
  const [contactId, setContactId] = useState(null);
  const [groupLabelId, setGroupLabelId] = useState(null);
  const [loadRightPanel, setLoadRightPanel] = useState(false);

  // function to set the received props data from LeftPanel.js to useState
  const setReceivedPropsData = (data) => {
    if (data != null || data != undefined) {
      setChatInfo(data);
    } else {
      setChatInfo(null);
    }
  };

  // function for passing chat id for add tag via props
  const chatIdTag = (data) => {
    if (data != null || data != undefined) {
      setChatId(data);
    } else {
      setChatId(null);
    }
  };

  // function for passing chat id for add tag via props
  const mailTagId = (data) => {
    if (data != null || data != undefined) {
      setMailId(data);
    } else {
      setMailId(null);
    }
  };

  // function for send tag contact id to add tag modal via props
  const tagFromContact = (data) => {
    if (data) {
      setContactId(data);
    } else {
      setContactId(null);
    }
  };

  // function for send group id to add tag modal via props
  const tagFromGroup = (data) => {
    if (data) {
      setGroupLabelId(data);
    } else {
      setGroupLabelId(null);
    }
  };

  // function for reset all tag
  const resetTagModal = () => {
    setChatId(null);
    setContactId(null);
    setGroupLabelId(null);
  };

  const reloadRightPanel = () => {
    setLoadRightPanel(true);
  };

  const stopLoadRightPanel = () => {
    setLoadRightPanel(false);
  };

  // useEffect(() => {
  //   document.title = "Chat";
  // }, []);

  return (
    <>
      {/* ----- header part ----- */}

      {/* All display logic to be written in this component page */}
      <section className="chat_conversations">
        <div className="chat_pnlOuter">
          <div className="chat_lftpnl">
            {/* ----- list of all contacts and groups ----- */}
            <LeftPanel
              chatInfo={setReceivedPropsData}
              getTagId
              chatId={chatId}
              mailId={mailId}
              contactId={contactId}
              groupLabelId={groupLabelId}
              resetTagModal={resetTagModal}
              reloadRightPanel={reloadRightPanel}
            />
          </div>

          <div className="chat_rightpnl">
            {/* Condition 1, user has logged in but has not clicked on any chat */}
            {chatInfo == null ? (
              <div className="empty_message_section">
                <Welcome />
              </div>
            ) : (
              <div className="empty_section_top">
                {/* Condition 2, user has logged in and has also clicked on any chat */}
                <div className="chat_section">
                  {/* --- Condition 2 and 3 for display right panel ---- */}
                  {chatInfo.isGroup == false ? (
                    <ContactRightPanel
                      chatInfo={chatInfo}
                      chatIdTag={chatIdTag}
                      mailTagId={mailTagId}
                      tagFromContact={tagFromContact}
                      loadRightPanel={loadRightPanel}
                      stopLoadRightPanel={stopLoadRightPanel}
                    />
                  ) : (
                    <GroupRightPanel
                      chatInfo={chatInfo}
                      getTags={chatIdTag}
                      tagFromGroup={tagFromGroup}
                      loadRightPanel={loadRightPanel}
                      stopLoadRightPanel={stopLoadRightPanel}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ConversationScreen;
