/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AboutTab from "./tabs/AboutTab";
import EventParticipants from "./tabs/EventParticipants";
import FeedbackTab from "./tabs/FeedbackTab";
import ForumTab from "./tabs/ForumTab";
import GalleryTab from "./tabs/GalleryTab";

const EventDetailsLeftBottomSection = () => {
  // initial useState for all the tabs
  const [aboutTab, setAboutTab] = useState(false);
  const [galleryTab, setGalleryTab] = useState(false);
  const [participantsTab, setParticipantsTab] = useState(false);
  const [forumTab, setForumTab] = useState(false);
  const [feedbackTab, setFeedbackTab] = useState(false);

  // functions to toggle the tabs
  const toggleAboutTab = (e) => {
    e.preventDefault();
    setAboutTab(true);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setFeedbackTab(false);
  };

  const toggleGalleryTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setGalleryTab(true);
    setParticipantsTab(false);
    setForumTab(false);
    setFeedbackTab(false);
  };

  const toggleParticipantsTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setGalleryTab(false);
    setParticipantsTab(true);
    setForumTab(false);
    setFeedbackTab(false);
  };

  const toggleForumTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(true);
    setFeedbackTab(false);
  };

  const toggleFeedbackTab = (e) => {
    e.preventDefault();
    setAboutTab(false);
    setGalleryTab(false);
    setParticipantsTab(false);
    setForumTab(false);
    setFeedbackTab(true);
  };

  useEffect(() => {
    setAboutTab(true);
  }, []);

  return (
    <div className="details_tabArea expert_detailsTab">
      {/* ----- tab heading section start ----- */}
      <div className="addons_tabLinks jobdetails_tablinks">
        <ul className="nav nav-tabs list_stye_none">
          <li className="nav-item">
            <Link
              className={aboutTab == true ? "nav-link active show" : "nav-link"}
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleAboutTab(e)}
            >
              About
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                galleryTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleGalleryTab(e)}
            >
              Gallery
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                participantsTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleParticipantsTab(e)}
            >
              Participants
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={forumTab == true ? "nav-link active show" : "nav-link"}
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleForumTab(e)}
            >
              Forum
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={
                feedbackTab == true ? "nav-link active show" : "nav-link"
              }
              data-bs-toggle="tab"
              to="#"
              onClick={(e) => toggleFeedbackTab(e)}
            >
              Review
            </Link>
          </li>
        </ul>
      </div>
      {/* ----- tab heading section end ----- */}

      {/* ---- tab components start ----- */}
      <div className="tab-content">
        {aboutTab && <AboutTab />}

        {galleryTab && <GalleryTab />}

        {participantsTab && <EventParticipants />}

        {forumTab && <ForumTab />}

        {feedbackTab && <FeedbackTab />}
      </div>
      {/* ----- tab component end ---- */}
    </div>
  );
};

export default EventDetailsLeftBottomSection;
