/* eslint-disable */
import { useEffect, useState } from "react";
import { useHistory, Link, Redirect } from "react-router-dom";
import { getData, postData } from "../../utils/Gateway";
import * as url from "../../urlhelpers/UrlHelper";
import jwt_decode from "jwt-decode";

// importing images here
import { assetImages } from "../../constants";

/* ------------- Google login requirements starts here ------------- */
import { GoogleLogin } from "@react-oauth/google";
/* ------------- Google login requirements ends here ------------- */

const LoginModal = () => {
  /*----- UseState to send username and password for Login ----*/
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(true);
  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  // function to handle the logIn process
  const LogInHandler = async (e) => {
    e.preventDefault();

    if (userEmail == "" || userPassword == "") {
      if (userEmail == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }
      if (userPassword == "") {
        setPasswordWarning(true);
      } else {
        setPasswordWarning(false);
      }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (userEmail.match(mailformat)) {
        setNotValidEMail(false);
        setIsLoading(true);
        try {
          let userData = {
            usemail: userEmail,
            uspass: userPassword,
          };
          let requestURL =
            url.API_BaseUrl +
            url.API_LoginUrl +
            "?usemail=" +
            userEmail +
            "&uspass=" +
            userPassword;

          const response = await getData(requestURL);

          if (response.status) {
            if (response.adminstatus) {
              // assigning the received values to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response));
              // assigning the loggedin user's id to the browser's local storage.
              localStorage.setItem("userID", JSON.stringify(response.id));
              //   localStorage.setItem("language", response.language);
              //   i18n.changeLanguage(response.language);

              let loginNowModal = document.querySelector("#login_modal");
              let modal = bootstrap.Modal.getInstance(loginNowModal);
              modal.hide();
              window.location.reload();
            }
          } else {
            console.log("email pass not matched");
          }
          setIsLoading(false);
          resetState();
        } catch (error) {}
      } else {
        setNotValidEMail(true);
      }
    }
  };

  // function to reset the useState containing the email and password text fields
  const resetState = () => {
    setUserEmail("");
    setUserPassword("");
  };

  //function for display email modal for validate code
  //   const displayEmailModal = (data) => {
  //     if (data) {
  //       let myModal = new bootstrap.Modal(
  //         document.getElementById("sent_code_modal")
  //       );
  //       myModal.show();
  //     }
  //   };

  // useEffect for cheking the login token available or not with refferel url
  //   useEffect(() => {
  //     if (token) {
  //       if (startingUrl == null) {
  //         if (document.referrer == "" || document.referrer == null) {
  //           window.location.href = "/";
  //         } else {
  //           if (document.referrer.split("/").pop() == "logout") {
  //             window.location.href = "/";
  //           } else {
  //             window.location.href = document.referrer;
  //           }
  //         }
  //       } else {
  //         window.location.href = startingUrl;
  //       }
  //     }
  //   }, [token]);

  // function for logging in with google
  const responseGoogle = (response) => {
    console.log(response);
    const userObject = jwt_decode(response.credential);
    console.log(userObject);
    // localStorage.setItem("user", JSON.stringify(userObject));
    const { name, sub, picture } = userObject;
    const doc = {
      _id: sub,
      _type: "user",
      userName: name,
      image: picture,
    };
    // console.log(doc);
    // client.createIfNotExists(doc).then(() => {
    //   navigate("/", { replace: true });
    // });
  };

  return (
    <div className="registration_modal">
      <div className="modal fade show" id="login_modal">
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            {/* --------- Modal header starts here --------- */}
            <div className="modal-header">
              {/* ------- Modal Heading starts here ------- */}
              <div className="signin_hdng text-center">
                <h3>Sign in</h3>
                <h5>Please enter details to proceed</h5>
              </div>

              {/* ------- 'Close' button starts here ------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetState}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            {/* --------- Modal header ends here --------- */}

            {/* --------- Modal body starts here --------- */}
            <div className="modal-body">
              <div className="login_body">
                {/* --------- Form section starts here --------- */}
                <form onSubmit={LogInHandler}>
                  {/* ------- Form Inputs section starts here ------- */}
                  <div className="login_fillOuter">
                    {/* ------- Email input starts here ------- */}
                    <div className="input_fill">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={userEmail}
                        onChange={(e) => {
                          setEmailWarning(false);
                          setUserEmail(e.target.value);
                        }}
                      />
                      {emailWarning && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Please enter email
                        </p>
                      )}
                      {notValidEMail && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Please enter valid email
                        </p>
                      )}
                    </div>

                    {/* ------- Password input starts here ------- */}
                    <div className="input_fill">
                      <label>Password</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "password" : "text"}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={(e) => {
                            setPasswordWarning(false);
                            setUserPassword(e.target.value);
                          }}
                        />
                        <Link to="#" className="copy_link" onClick={toggleText}>
                          <span className="material-icons-outlined">
                            {" "}
                            visibility
                          </span>
                        </Link>
                      </div>
                      {passwordWarning && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Please enter password
                        </p>
                      )}
                    </div>
                  </div>

                  {/* ------- Terms & Conditions Checkbox starts here ------- */}
                  <div className="trms_check">
                    <label className="radio-check">
                      <input type="checkbox" />
                      <span></span>
                      <p>
                        Agree with the <Link to="#">T&amp;C</Link>
                      </p>
                    </label>
                  </div>

                  {/* ------- 'Forgot password' & 'Login' button starts here ------- */}
                  <div className="forgot_applyOuter">
                    {/* ------- 'Forgot Password' button starts here ------- */}
                    <div className="forgot_btn">
                      <Link
                        to="#"
                        className="btn gray_btn"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        data-target="#forgot_mdl"
                      >
                        Forgot Password
                      </Link>
                    </div>

                    {/* ------- 'Login' button starts here ------- */}
                    <div className="apply_btn">
                      <button
                        type="submit"
                        className="btn"
                        disabled={isLoading ? true : false}
                        style={{
                          cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                      >
                        Login
                        {isLoading && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* --------- Social Login starts here --------- */}
                  <div className="create_userOuter">
                    {/* ------- Google login starts here ------- */}
                    {/* <div className="linkedin_btn google_btn">
                      <Link to="#">
                        <GoogleLogin
                          render={(renderProps) => (
                            <button
                              type="button"
                              className="basic_btn w_100"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                            >
                              <FcGoogle className="linkedin_btn google_btn" />{" "}
                              Login with Google
                            </button>
                          )}
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy="single_host_origin"
                        />
                      </Link>
                    </div> */}

                    {/* ------- LikedIn login starts here ------- */}
                    {/* <div className="linkedin_btn">
                      <Link to="#">
                        <span>
                          <img src={assetImages.linkedin} alt="" />
                        </span>
                        Login with Linkedin
                      </Link>
                    </div> */}

                    {/* ------- Registration Modal starts here ------- */}
                    <div className="create_text">
                      <p>
                        Don't have an account?{" "}
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          data-bs-target="#register_mdl"
                        >
                          create account
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* --------- Modal body ends here --------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
