/* eslint-disable */
import React from "react";

// importing custom components here
import Header from "../../components/common/Header";
import Banner from "../../components/common/Banner";
import ExpertListArea from "../../components/expertlistcomponents/ExpertListArea";
import Footer from "../../components/common/Footer";
import SearchTab from "../../components/common/SearchTab";

const ExpertList = () => {
  return (
    <>
      <Header activePage={"experts"} />
      <section className="checkout_area job_serchSec">
        <div className="container">
          <Banner
            heading1={"Search for experts"}
            heading2={"Over 5 million expert listed"}
          />
          <SearchTab activePage={"experts"} />
          <ExpertListArea />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ExpertList;
