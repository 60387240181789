/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images here
// import { assetImages } from "../../../constants";

// importing modals here
import QuestionModal from "../tabmodals/QuestionModal";

const RankingIdea = () => {
  return (
    <div className="inner_tab_pane" id="ranking_idea">
      <div className="ranking_mdlPnl">
        <ul className="list_stye_none">
          <li>
            <Link to="#" data-bs-toggle="modal" data-bs-target="#question_1">
              <span>How can you put question here?</span>
              <i className="material-icons-outlined">arrow_forward</i>
            </Link>
          </li>
          <li>
            <Link to="#" data-bs-toggle="modal" data-bs-target="#question_1">
              <span>How can you put question here?</span>
              <i className="material-icons-outlined">arrow_forward</i>
            </Link>
          </li>
        </ul>
      </div>

      {/* -------------- 'Question' modal implemented here -------------- */}
      <QuestionModal />
    </div>
  );
};

export default RankingIdea;
