/* eslint-disable */
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const BlogListArea = () => {
  const {
    blogSearchQuery,
    blogSearchCategory,
    setBlogSearchQuery,
    setblogSearchCategory,
  } = useContext(GlobalFindelOiProvider);

  const token = localStorage.getItem("token");

  const blogListRef = useRef(null);

  const glowCount = [1, 2, 3];

  const [blogLists, setBlogLists] = useState([]);
  const [page, setPage] = useState(1);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for blogref toggle
  const blogRefToggle = () => {
    blogListRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // function for get all blog list
  const getBlogLists = async () => {
    try {
      setIsLoading(true);
      let requestURL;

      if (token) {
        requestURL =
          url.API_BaseUrl + url.API_BLOG_LISTS + `?token=${token}&page=${page}`;
      } else {
        requestURL = url.API_BaseUrl + url.API_BLOG_LISTS + `?page=${page}`;
      }

      if (blogSearchQuery != "") {
        requestURL = requestURL + `&query=${blogSearchQuery}`;
      }

      if (blogSearchCategory != null) {
        requestURL = requestURL + `&cat=${blogSearchCategory.id}`;
      }

      // console.log("blog list url----->",requestURL);

      const response = await getData(requestURL);

      // console.log("blog list response ------>", response);

      if (response.status) {
        if (response.data) {
          setBlogLists(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/
        } else {
          setBlogLists([]);
        }
      } else {
        setBlogLists([]);
      }
      setIsLoading(false);
    } catch (error) {}
  };

  //function for reset search query
  const resetSearchQuery = () => {
    setBlogSearchQuery("");
  };

  //function for reset search category
  const resetSearchCategory = () => {
    setblogSearchCategory(null);
  };

  useEffect(() => {
    getBlogLists();
  }, [page, blogSearchQuery, blogSearchCategory]);

  return (
    <>
      <div ref={blogListRef} name="scrollBlogRefDiv" />
      {blogSearchQuery != "" || blogSearchCategory != null ? (
        <div className="mt-4">
          <div className="d-flex flex-wrap gap-2">
            {/* ---------- Search Title Section starts here ---------- */}

            {blogSearchQuery == "" ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Query
                  </label>

                  <label>{blogSearchQuery}</label>
                  <Link to="#" className="cancel" onClick={resetSearchQuery}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {blogSearchCategory == null ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Category
                  </label>

                  <label>{blogSearchCategory.label}</label>
                  <Link to="#" className="cancel" onClick={resetSearchCategory}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}

      {isLoading ? (
        <div className="blog_outer">
          <div className="row">
            {glowCount.map((count) => {
              return (
                <div className="col-lg-4 col-sm-6" key={count}>
                  <div className="blog_item">
                    <div className="blog_pic">
                      <span
                        className="placeholder col-12"
                        style={{ height: "197px" }}
                      ></span>
                    </div>
                    <div className="blog_textpnl">
                      <h5 className="d-flex justify-content-between">
                        <span className="placeholder placeholder-md rounded-2 col-3"></span>
                        <span className="placeholder placeholder-md rounded-2 col-3"></span>
                      </h5>

                      <p>
                        <span
                          className="placeholder rounded-2 col-6 mt-2"
                          style={{ height: "20px", width: "100%" }}
                        ></span>
                      </p>
                      <p>
                        <span
                          className="placeholder rounded-2 col-6 mt-2"
                          style={{ height: "20px", width: "100%" }}
                        ></span>
                      </p>

                      <div className="details_btn">
                        <span
                          className="placeholder rounded-3 col-12"
                          style={{ height: "50px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="blog_outer">
          {blogLists.length === 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="empty_lessonsArea mt-3">
                  <div className="empty_lessonpic">
                    <img src={assetImages.emptyLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>Sorry....!</h2>
                    <p>
                      Blogs are not available{" "}
                      {blogSearchCategory ? "for this Category" : ""}{" "}
                      {blogSearchQuery ? "for this query" : ""} at this moment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              {blogLists.map((blog, index) => {
                return (
                  <div className="col-lg-4 col-md-6" key={index}>
                    <div className="blog_item">
                      <div className="blog_pic h-auto">
                        <Link to="#">
                          <img
                            src={
                              blog.image == null
                                ? index == 0
                                  ? assetImages.blogPic1
                                  : index == 1
                                  ? assetImages.blogPic2
                                  : index == 2
                                  ? assetImages.blogPic3
                                  : (index + 1) / 4 == 1
                                  ? assetImages.blogPic1
                                  : (index + 1) / 5 == 1
                                  ? assetImages.blogPic2
                                  : (index + 1) / 6 == 1
                                  ? assetImages.blogPic3
                                  : null
                                : blog.image
                            }
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="blog_textpnl">
                        <h5 className="d-flex justify-content-between">
                          {blog.author.name} <span>{blog.created}</span>
                        </h5>
                        {blog.title == null ||
                        blog.title == undefined ? null : (
                          <Link to="#" className="blog_hdng">
                            {blog.title}
                          </Link>
                        )}

                        {blog.category.length == 0 ? null : (
                          <div className="skill_menu mb-2">
                            <ul className="list_stye_none">
                              {blog.category.map((cat, index2) => {
                                return (
                                  <li key={index2}>
                                    <Link to="#" className="blog_category">
                                      {cat.label}
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}

                        {blog.summary == null ? null : <p>{blog.summary}</p>}
                        <div className="read_norebtn">
                          <Link
                            to={`/blog/${blog.slug}`}
                            className="btn outline"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* ---------------- Pagination section starts here ---------------- */}
          <div className="job_pagination">
            <ul className="list_stye_none">
              <li className="prev_pagnation">
                <Link
                  to="#"
                  onClick={() => {
                    if (page != 1) {
                      nextPage(page - 1);
                      blogRefToggle();
                    }
                  }}
                >
                  <span className="material-icons-outlined">
                    keyboard_arrow_left
                  </span>
                </Link>
              </li>
              {totalPages.map((pageNo) => {
                return (
                  <li className={page == pageNo ? "active" : ""} key={pageNo}>
                    <Link
                      to="#"
                      onClick={() => {
                        nextPage(pageNo);
                        blogRefToggle();
                      }}
                    >
                      {pageNo}
                    </Link>
                  </li>
                );
              })}

              <li className="next_pagination">
                <Link
                  to="#"
                  onClick={() => {
                    if (page != lastPageNo) {
                      nextPage(page + 1);
                      blogRefToggle();
                    }
                  }}
                >
                  <span className="material-icons-outlined">
                    keyboard_arrow_right
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogListArea;
