/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const SearchTab = (props) => {
  const {
    setEventTitle,
    setEventCity,
    setEventCategory,
    setEventFilterValue,
    setSearchExpertName,
    setSearchExpertLocation,
    setSearchExpertSkills,
  } = useContext(GlobalFindelOiProvider);

  const history = useHistory();

  const token = localStorage.getItem("token");

  //use states for tab switching
  const [showEventTab, setShowEventTab] = useState(false);
  const [showExpertTab, setShowExpertTab] = useState(false);

  //custom style for react select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      // borderBottom: "1px dotted pink",
      color: "#666666",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "16px",
      padding: "6px",
      fontFamily: "sans-serif",
      border: 0,
      boxShadow: "none",
      // backgroundColor: "#F0F0F0",
      // height: 50,
      borderRadius: "8px",
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#8E8EA6 !important",
        padding: "5px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // selected value
    singleValue: (provided) => ({
      ...provided,
      color: "666666",
      fontSize: "16px",
    }),

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#505050" };
      return Object.assign(base, changes);
    },
  };

  /* ----------- Events Tab requirements starts here ----------- */
  // Initial useState to save the event title
  const [eventName, setEventName] = useState("");

  // Initial useState to save the event city
  const [eventCityValue, setEventCityValue] = useState("");

  // Initial useState to get all the options from API
  const [categoriesEventsValue, setEventsCategoriesValue] = useState([]);

  // Initial useState to save the selection from the React Select Element
  const [selectedEventsItemsCategory, setSelectedEventsItemsCategory] =
    useState([]);

  // Initial useState to display the selected items
  const [selectedEventsCategory, setSelectedEventsCategory] = useState(null);

  const [selectedEventFilter, setSelectedEventFilter] = useState(null);
  const [selectedFilterEventItem, setSelectedFilterEventItem] = useState(null);

  // function for switch tabs
  const toggleTab = (tabName) => {
    setShowEventTab(false);
    setShowExpertTab(false);

    if (tabName == "experts") {
      setShowExpertTab(true);
    }

    if (tabName == "events") {
      setShowEventTab(true);
    }
  };

  // function to get categories details from API
  const getEventCategories = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_EVENT_CATEGORY_LISTS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        if (response.data) {
          setEventsCategoriesValue(response.data.options);
        }
      }
    } catch (error) {}
  };

  //event filter option
  const eventFilterOption = [
    {
      id: 11,
      label: "Current Events",
      name: "CurrentEvents",
      value: "current",
    },
    {
      id: 10,
      label: "Upcoming Events",
      name: "UpcomingEvents",
      value: "upcoming",
    },
    { id: 12, label: "My Events", name: "MyEvents", value: "myevents" },
  ];

  // function to select category
  const eventCategorySelectionHandler = (val) => {
    let eventCategoryVal = val.map((item) => {
      let categoryObject = {
        label: item.label,
        id: item.id,
      };
      return categoryObject;
    });

    setSelectedEventsItemsCategory(eventCategoryVal);
    setSelectedEventsCategory();
  };

  // function to select date
  const eventTypeSelectionHandler = (val) => {
    setSelectedEventFilter();
    setSelectedFilterEventItem(val);
  };

  /* ------------- Single Select requirements ends here ------------- */
  // function for search event lists
  const searchEventHandler = (e) => {
    e.preventDefault();

    setEventTitle("");
    setEventCity("");
    setEventCategory([]);
    setEventFilterValue(null);

    history.push("/events");

    //conditon for event title
    if (eventName != "") {
      setEventTitle(eventName);
      setEventName("");
    }

    //condition for event location
    if (eventCityValue != "") {
      setEventCity(eventCityValue);
      setEventCityValue("");
    }

    //condition for event category
    if (selectedEventsItemsCategory.length > 0) {
      setEventCategory(selectedEventsItemsCategory);
      setSelectedEventsCategory(null);
      setSelectedEventsItemsCategory([]);
    }

    //condition for event type
    if (selectedFilterEventItem) {
      setEventFilterValue(selectedFilterEventItem);
      setSelectedEventFilter(null);
      setSelectedFilterEventItem(null);
    }
  };

  /* -------- event requirement end ----- ----*/

  /*========== experts requirements start ==========*/
  const [expertName, setExpertName] = useState("");
  const [expertLocation, setExpertLocation] = useState("");
  const [expertSkills, setExpertSkills] = useState([]);
  const [expertSkillVal, setExpertSkillVal] = useState(null);
  const [selecetedExpertSkills, setSelecetedExpertSkills] = useState([]);

  //function for get all skills for experts
  const getExpertSkills = async () => {
    const requestURL =
      url.API_BaseUrl + url.API_EXPERT_SKILLS + `?token=${token}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setExpertSkills(response.data.options);
        }
      }
    } catch (error) {}
  };

  // function for select skills
  const expertSkillSelectionHandler = (val) => {
    let expertSkillVal = val.map((item) => {
      let skillObject = {
        label: item.label,
        id: item.id,
      };
      return skillObject;
    });

    if (expertSkillVal.length != 0) {
      setSelecetedExpertSkills(expertSkillVal);
      setExpertSkillVal();
    }
  };

  // function for search experts
  const searchExpertHandler = (e) => {
    e.preventDefault();

    setSearchExpertName("");
    setSearchExpertLocation("");
    setSearchExpertSkills([]);

    history.push("/experts");

    if (expertName != "") {
      setSearchExpertName(expertName);
      setExpertName("");
    }

    if (expertLocation != "") {
      setSearchExpertLocation(expertLocation);
      setExpertLocation("");
    }

    if (selecetedExpertSkills.length > 0) {
      setSearchExpertSkills(selecetedExpertSkills);
      setExpertSkillVal(null);
      setSelecetedExpertSkills([]);
    }
  };
  /*========== experts requirements end ==========*/

  // function for search by enter key
  const searchByEnter = (event) => {
    if (event.key === "Enter") {
      // condition for champion tab
      if (showEventTab) {
        searchEventHandler(event);
      }

      if (showExpertTab) {
        searchExpertHandler(event);
      }
    }
  };

  //function for search for react select
  const searchByEnterSelect = (event) => {
    if (event.key === "Enter") {
      // condition for expert tab
      if (showExpertTab && selecetedExpertSkills.length > 0) {
        searchExpertHandler(event);
      }

      // condition for event tab
      if (showEventTab && selectedEventsItemsCategory.length > 0) {
        searchEventHandler(event);
      }

      if (showEventTab && selectedFilterEventItem != null) {
        searchEventHandler(event);
      }
    }
  };

  // use effect for display tabs
  useEffect(() => {
    if (props.activePage == "experts") {
      setShowExpertTab(true);
    }
    if (props.activePage == "events") {
      setShowEventTab(true);
    }
  }, [props.activePage]);

  // useeffect for get category list
  useEffect(() => {
    getEventCategories();
    getExpertSkills();
  }, []);

  return (
    <>
      {/* ----- toggle tab area start ----- */}
      <div className="lobess_pagelink">
        <ul className="list_stye_none">
          <li className={showExpertTab ? "active" : ""}>
            <Link
              to="#"
              onClick={() => {
                toggleTab("experts");
              }}
            >
              Experts
            </Link>
          </li>
          <li className={showEventTab ? "active" : ""}>
            <Link
              to="#"
              onClick={() => {
                toggleTab("events");
              }}
            >
              Events
            </Link>
          </li>
        </ul>
      </div>
      {/* ----- toggle tab area end ----- */}

      {/* ----- expert tab area start ----- */}
      {showExpertTab && (
        <div className="jobsrch_slctArea">
          <div className="tab_formOuter">
            {/* --------- Name / Details input field starts here --------- */}
            <div className="tab_fill expert_search">
              <input
                type="text"
                className="form-control"
                placeholder="Name/Details"
                value={expertName}
                onChange={(e) => setExpertName(e.target.value)}
                onKeyDown={searchByEnter}
              />
            </div>

            {/* --------- Category react-select starts here --------- */}
            <div className="tab_fill select_fill expert_search">
              <Select
                isMulti={true}
                placeholder="Select Skill"
                options={expertSkills}
                value={expertSkillVal}
                classNamePrefix="select2-selection nosearch_select"
                onChange={(val) => {
                  expertSkillSelectionHandler(val);
                }}
                styles={customSelectStyle}
                onKeyDown={searchByEnterSelect}
              />
            </div>

            {/* --------- Location input field starts here --------- */}
            <div className="tab_fill expert_search">
              <input
                type="text"
                className="form-control"
                placeholder="Location"
                value={expertLocation}
                onChange={(e) => setExpertLocation(e.target.value)}
                onKeyDown={searchByEnter}
              />
            </div>

            {/* --------- Expert Type react-select starts here --------- */}

            {/* ----------- Search button starts here ----------- */}
            <div className="submit_fill">
              <button
                type="submit"
                className="submit_btn form_submit"
                onClick={searchExpertHandler}
              >
                <i className="material-icons-round">search</i>
                <span>Search</span>
              </button>
            </div>
          </div>

          {/* <div className="expert_slctOuter">
              <div className="job_slct_row expertslct_row">
                <div className="job_slctItem">
                  <div className="model_selectBx">
                    <select className="nosearch_select" title="Experience">
                      <option>Experience</option>
                      <option>2 Years</option>
                      <option>4 Years</option>
                    </select>
                  </div>
                </div>
                <div className="job_slctItem">
                  <div className="model_selectBx">
                    <select className="nosearch_select" title="Rates">
                      <option>Lorem Ipsum</option>
                      <option>Lorem Ipsum</option>
                      <option>Lorem Ipsum</option>
                    </select>
                  </div>
                </div>
                <div className="job_slctItem">
                  <div className="model_selectBx">
                    <select className="nosearch_select" title="User Rating">
                      <option>Good</option>
                      <option>Very Good</option>
                      <option>Exicilent</option>
                    </select>
                  </div>
                </div>
                <div className="job_slctItem">
                  <div className="model_selectBx">
                    <select className="nosearch_select" title="Short By">
                      <option>Lorem Ipsum</option>
                      <option>Lorem Ipsum</option>
                      <option>Lorem Ipsum</option>
                    </select>
                  </div>
                </div>
              </div>

             
              <div className="exprt_filter_btn">
                <Link to="#">
                  <span className="material-icons-round">filter_alt</span>
                </Link>
              </div>

             
              <div className="expertrght_icon">
                <ul className="list_stye_none">
                  <li className="active">
                    <Link to="#" className="grid_icon">
                      <i className="material-icons-outlined"> window </i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="list_icon">
                      <i className="material-icons-outlined"> view_agenda </i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      )}
      {/* ----- expert tab area end ----- */}

      {/* ----- event tab area start ----- */}
      {showEventTab && (
        <div className="jobsrch_slctArea">
          {/* ------------------ Search Section starts here ------------------ */}
          <div className="tab_formOuter">
            {/* --------- Name / Details input field starts here --------- */}
            <div className="tab_fill">
              <input
                type="text"
                className="form-control"
                placeholder="Name/Details"
                value={eventName}
                onChange={(e) => {
                  setEventName(e.target.value);
                }}
                onKeyDown={searchByEnter}
              />
            </div>

            {/* --------- Category react-select starts here --------- */}
            <div className="tab_fill select_fill">
              <Select
                isMulti={true}
                placeholder="Select Category"
                options={categoriesEventsValue} // show all options
                value={selectedEventsCategory} // show selected values
                onChange={(val) => {
                  eventCategorySelectionHandler(val);
                }} // handler function
                classNamePrefix="select2-selection nosearch_select"
                styles={customSelectStyle}
                onKeyDown={searchByEnterSelect}
              />
            </div>

            {/* --------- Location input field starts here --------- */}
            <div className="tab_fill">
              <input
                type="text"
                className="form-control"
                placeholder="Location"
                value={eventCityValue}
                onChange={(e) => {
                  setEventCityValue(e.target.value);
                }}
                onKeyDown={searchByEnter}
              />
            </div>

            {/* ----- event type select start ----- */}
            <div className="tab_fill select_fill">
              <Select
                isMulti={false}
                placeholder="Type"
                options={eventFilterOption} // show all options
                value={selectedEventFilter} // show selected values
                onChange={(val) => {
                  eventTypeSelectionHandler(val);
                }} // handler function
                classNamePrefix="select2-selection nosearch_select"
                styles={customSelectStyle}
                onKeyDown={searchByEnterSelect}
              />
            </div>

            {/* ----- search button start ----- */}
            <div className="submit_fill">
              <button
                type="submit"
                className="submit_btn form_submit"
                onClick={searchEventHandler}
              >
                <i className="material-icons-round">search</i>
                <span>Search</span>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* ----- event tab area end ----- */}
    </>
  );
};

export default SearchTab;
