/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

const HomeEvent = () => {
  const token = localStorage.getItem("token");

  let page = 1;

  const glowCount = [1, 2, 3];

  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // function for all events lists
  const getEventsLists = async () => {
    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl +
        url.API_EVENT_LIST +
        `?token=${token}` +
        `&page=${page}`;
    } else {
      requestURL = url.API_BaseUrl + url.API_EVENT_LIST + `?page=${page}`;
    }

    // console.log(requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("response in home events------>", response);

      if (response.status) {
        if (response.data) {
          setEventsData(response.data);
        } else {
          setEventsData([]);
        }
        setIsLoading(false);
      } else {
        setEventsData([]);
      }
    } catch (error) {}
  };

  //useeffect for get all event list on condition based
  useEffect(() => {
    getEventsLists();
  }, []);

  return (
    <section className="events_sec pd_80">
      <div className="container">
        <div className="blog_hdngOuter mb-4">
          <div className="page_heading mb-0">
            <h2>Best events</h2>
          </div>
          <div className="view_allabtn">
            <Link to="/events" className="btn outline">
              View All
            </Link>
          </div>
        </div>
        <div className="job_box_outer">
          {isLoading ? (
            <div className="row">
              {glowCount.map((count) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={count}>
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "70%" }}
                          ></span>
                        </h3>
                        <h5>
                          <span
                            className="placeholder"
                            style={{ width: "55%" }}
                          ></span>
                        </h5>
                      </div>
                      <div className="event_tags">
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span
                          className="placeholder"
                          style={{
                            width: "80px",
                            height: "32px",
                            borderRadius: "8px",
                            marginRight: "10px",
                          }}
                        ></span>
                      </div>
                      <div className="event_pic">
                        <span
                          className="placeholder"
                          style={{ width: "100%", height: "200px" }}
                        ></span>
                      </div>
                      <div className="event_para">
                        <p>
                          <span
                            className="placeholder"
                            style={{ width: "100%", height: "15px" }}
                          ></span>
                          <span
                            className="placeholder"
                            style={{ width: "80%", height: "15px" }}
                          ></span>
                        </p>
                      </div>
                      <div className="ambassadors_users event_user">
                        <ul className="list_stye_none">
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                          <li>
                            <span
                              className="placeholder rounded-circle"
                              style={{ width: "40%", height: "40px" }}
                            ></span>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <span
                          className="placeholder w-100 rounded-2"
                          style={{ height: "46px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row">
              {/* ---------- Card component starts here ---------- */}
              {eventsData.slice(0, 3).map((item, index) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <div className="job_bxItem event_bxItem">
                      <div className="event_hdng">
                        <h3>{item.title}</h3>
                        <h5>
                          <i className="material-icons-outlined">
                            {" "}
                            event_note{" "}
                          </i>
                          <span>
                            {item.startday} {item.startmonth} {item.startyear}
                          </span>
                        </h5>
                      </div>
                      {item.category.length > 0 ? (
                        <div className="mb-1">
                          {item.category.slice(0, 3).map((category, index2) => {
                            return (
                              <div
                                className="wedding_text jb_rating mb-2 me-2"
                                key={index2}
                              >
                                {category}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="event_tags">
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{item.rating}</span>
                          <h6>({item.reviews})</h6>
                        </div>
                        <div className="jb_rating">
                          <i className="material-icons-outlined"> place </i>
                          {item.city && item.country ? (
                            <span>
                              {item.city ? item.city : null} ,{" "}
                              {item.country ? item.country : null}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="event_pic">
                        <figure>
                          <img
                            src={item.image ? item.image : ""}
                            alt="eventImg"
                          />
                        </figure>
                      </div>
                      <div className="event_para">
                        <p>{item.summary.slice(0, 80)}</p>
                      </div>
                      <div className="ambassadors_users event_user">
                        <ul className="list_stye_none">
                          <li>
                            <Link to="#">
                              <img src={assetImages.expert1} alt="expertImg" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src={assetImages.expert2} alt="expertImg" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src={assetImages.expert3} alt="expertImg" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src={assetImages.expert4} alt="expertImg" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <img src={assetImages.expert5} alt="expertImg" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="details_btn">
                        <Link
                          to={`/event/details/${item.id}`}
                          className="border_btn jbdetails_btn"
                        >
                          Details
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* ---------- Card component ends here ---------- */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeEvent;
