/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

const ApplicationMenu = (props) => {
  return (
    <div className="header_menu">
      {/* ------------- Close button starts here ------------- */}
      <span
        className="menu_cross menu_cross_button"
        onClick={() => document.body.classList.remove("menu_open")}
      >
        <i className="material-icons-outlined"> close </i>
      </span>

      {/* ------------- Body Section starts here ------------- */}
      <div className="inner_pnlBody" id="application_menu">
        <div className="menupnl_outer">
          {/* ------------- Menu Item Options ------------- */}
          <div className="menu_pnl">
            <ul className="list_stye_none">
              {/* --------------- Home --------------- */}
              <li className={props.activePage == "home" ? "active_menu" : ""}>
                <Link
                  to="/"
                  onClick={() => document.body.classList.remove("menu_open")}
                >
                  <i className="material-icons-outlined"> home </i>
                  <span>Home</span>
                </Link>
              </li>

              {/* --------------- Events --------------- */}
              <li className={props.activePage == "events" ? "active_menu" : ""}>
                <Link
                  to="/events"
                  onClick={() => document.body.classList.remove("menu_open")}
                >
                  <i className="material-icons-outlined">event_note</i>
                  <span>Events</span>
                </Link>
              </li>

              {/* --------------- Experts --------------- */}
              <li
                className={props.activePage == "experts" ? "active_menu" : ""}
              >
                <Link
                  to="/experts"
                  onClick={() => document.body.classList.remove("menu_open")}
                >
                  <i className="material-icons-round">manage_accounts</i>
                  <span>Experts </span>
                </Link>
              </li>

              {/* ----- blogs ------- */}
              <li className={props.activePage == "blogs" ? "active_menu" : ""}>
                <Link
                  to="/blogs"
                  onClick={() => document.body.classList.remove("menu_open")}
                >
                  <i className="material-icons-round">rss_feed</i>
                  <span>Blogs </span>
                </Link>
              </li>

              {/* ------ chat ----- */}
              <li className={props.activePage == "blogs" ? "active_menu" : ""}>
                <Link
                  to="/chat"
                  onClick={() => document.body.classList.remove("menu_open")}
                >
                  <i className="material-icons-round">chat</i>
                  <span>Chat </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* --------------- Social & Other Links --------------- */}
        <div className="bttm_ftr">
          {/* --------------- Important Links --------------- */}
          <div className="pnl_ftr_link">
            <ul className="list_stye_none">
              {/* <li>
                <Link to="#">Login & Register</Link>
              </li> */}
              <li>
                <Link to="#">Policy and Privacy</Link>
              </li>
              <li>
                <Link to="#">Terms</Link>
              </li>
              <li>
                <Link to="#">Site Map</Link>
              </li>
            </ul>
          </div>

          {/* --------------- Social links --------------- */}
          <div className="pnl_social">
            <ul className="list_stye_none">
              <li>
                <Link to="#">
                  <img src={assetImages.facebook} alt="fb_logo" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src={assetImages.instagram} alt="insta_logo" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <img src={assetImages.twitter} alt="twtr_logo" />
                </Link>
              </li>
            </ul>
          </div>

          {/* --------------- Copyright text --------------- */}
          <div className="pnl_copyright">
            <p>2022 © Findeloi.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationMenu;
