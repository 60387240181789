/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// import Gateway for API fetch
import { getData } from "../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../../../constants";
import AddTagsModal from "./modal/AddTagsModal";
import EventDetailsFeedbackModal from "./modal/FeedbackModal";
import ThankJoinEventModal from "./modal/ThankJoinEventModal";

const EventDetailsLeftTopSection = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const params = useParams();

  const {
    isEventApproved,
    setIsEventApproved,
    relaodEventTopSection,
    setRelaodEventTopSection,
    setReloadReviewTab,
    setGetEventName,
    setIsEventModerator,
  } = useContext(GlobalFindelOiProvider);

  //initial for event data
  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isJoined, setIsJoined] = useState(false);

  // Function to get all the review data from API
  const getEventBannerData = async (id) => {
    setIsLoading(true);

    let requestURL =
      url.API_BaseUrl + url.API_EVENT_BANNER + `?token=${token}` + `&id=${id}`;

    // console.log("request url event details ----->", requestURL);
    try {
      const response = await getData(requestURL);

      console.log("response in event details top section ----->", response);

      if (response.status) {
        setIsLoading(false);
        if (response.data) {
          if (response.data.length == 0) {
            setEventData(null);
          } else {
            setEventData(response.data);
            setGetEventName(response.data.title);
            if (response.data.isjoined) {
              setIsJoined(true);
            } else {
              setIsJoined(false);
            }

            if (response.data.isapproved) {
              setIsEventApproved(true);
            } else {
              setIsEventApproved(false);
            }

            if (response.data.owner.id === userInfo.id) {
              setIsEventModerator(true);
            } else {
              setIsEventModerator(false);
            }
          }
        } else {
          setEventData(null);
        }
      } else {
        setEventData(null);
      }
    } catch (error) {}
  };

  // Function to join the Event using join now button
  const joinEvent = async () => {
    setIsJoining(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_JOIN_EVENT +
      `?token=${token}` +
      `&eid=${params.id}`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setIsJoining(false);
        let myModal = new bootstrap.Modal(
          document.getElementById("thanks_event_join_mdl")
        );
        myModal.show();
      }
    } catch (error) {}
  };

  //function for refresh data after close thanks modal
  const afterThanksModalClose = () => {
    getEventBannerData(params.id);
    setReloadReviewTab(true);
  };

  //function for refresh data after add tag modal close
  const afterModalClose = () => {
    getEventBannerData(params.id);
  };

  // use effect for review tab reload
  useEffect(() => {
    if (relaodEventTopSection) {
      getEventBannerData(params.id);
      setRelaodEventTopSection(false);
    }
  }, [relaodEventTopSection]);

  // useEffect from here
  useEffect(() => {
    getEventBannerData(params.id);
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <div className="top_evntdetails_bx">
          {/* ----- glow placeholder start ---- */}
          <div className="evnt_cover_pic">
            <span
              className="placeholder rounded-2"
              style={{ width: "820px", height: "270px" }}
            ></span>
          </div>
          <div className="top_detailsBx">
            <div className="topdetails_left">
              <div className="date_bx">
                <h3>
                  <span
                    className="placeholder d-block mx-auto"
                    style={{ width: "36px", height: "14px" }}
                  ></span>
                </h3>
                <span className="date_clndr">
                  <img src={assetImages.eventCalendar} alt="" />
                </span>
              </div>
              <div className="date_text">
                <h5>
                  <span className="placeholder w-100"></span>
                </h5>
              </div>
              <div className="asign_top asign_mobile">
                <div className="asign_item">
                  <span
                    className="placeholder"
                    style={{ width: "180px" }}
                  ></span>
                </div>
                <div className="asign_item">
                  <span
                    className="placeholder"
                    style={{ width: "80px" }}
                  ></span>
                </div>
              </div>
              <div className="job_hdng evnt_hdng">
                <h3>
                  <span
                    className="placeholder"
                    style={{ width: "200px" }}
                  ></span>
                </h3>
              </div>
              <div className="event_tags">
                <span
                  className="placeholder me-3 rounded-3"
                  style={{ width: "200px", height: "32px" }}
                ></span>
                <span
                  className="placeholder me-3 rounded-3"
                  style={{ width: "65px", height: "32px" }}
                ></span>
              </div>
              {/* <div className="mobile_dateOuter">
                <div className="mobile_date">
                  <h5>
                    <i className="material-icons-outlined">event_note</i> 15th Sept
                    2021
                  </h5>
                </div>
                <div className="jb_rating star_color">
                  <i className="material-icons-outlined">star</i>
                  <span>4.5</span>
                </div>
              </div> */}
              <div className="vdo_link">
                <span className="placeholder w-100"></span>
              </div>
              <div className="evnt_list">
                <ul className="list_stye_none">
                  <span
                    className="placeholder me-3 rounded-2"
                    style={{ width: "150px", height: "44px" }}
                  ></span>
                  <span
                    className="placeholder rounded-2"
                    style={{ width: "120px", height: "44px" }}
                  ></span>
                </ul>
              </div>
            </div>
            <div className="topdetails_right">
              <div className="asign_top">
                <div className="asign_item">
                  <span
                    className="placeholder"
                    style={{ width: "180px" }}
                  ></span>
                </div>
                <div className="asign_item">
                  <span
                    className="placeholder"
                    style={{ width: "80px" }}
                  ></span>
                </div>
              </div>
              <div className="evnt_rght_bttm">
                <div className="exprt_share">
                  <ul className="list_stye_none">
                    <li>
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </li>
                    <li>
                      <span
                        className="placeholder rounded-3"
                        style={{ width: "40px", height: "40px" }}
                      ></span>
                    </li>
                  </ul>
                </div>
                <div className="feedback_jointbtnOuter">
                  <div className="feed_btn">
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "105px", height: "46px" }}
                    ></span>
                  </div>
                  <div className="details_apply">
                    <span
                      className="placeholder rounded-3"
                      style={{ width: "105px", height: "46px" }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ----- glow placeholder end ---- */}
        </div>
      ) : (
        <div className="top_evntdetails_bx">
          {eventData == null ? (
            <div className="d-flex justify-content-center">
              <div className="empty_lessonsArea">
                <div className="empty_lessonpic">
                  <img src={assetImages.emptyLesson} alt="blanklesson" />
                </div>
                <div className="empty_lessons_text">
                  <h2>Sorry....!</h2>
                  <p className="mx-2">
                    Event details are not available for this event at this
                    moment
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="event_details_top">
              <div className="evnt_cover_pic">
                <img src={assetImages.eventcover2} alt="" />
              </div>

              <div className="top_detailsBx">
                <div className="topdetails_left">
                  {/* ----- day and month section start ----- */}
                  <div className="date_bx">
                    <h3>
                      <span>
                        {eventData.startday ? eventData.startday : null}
                      </span>{" "}
                      {eventData.startmonth ? eventData.startmonth : null}
                    </h3>
                    <span className="date_clndr">
                      <img
                        src={assetImages.eventCalendar}
                        alt="eventCalendar"
                      />
                    </span>
                  </div>

                  <div className="date_text">
                    <h5>
                      {eventData.startday ? eventData.startday : null}{" "}
                      {eventData.startmonth ? eventData.startmonth : null}{" "}
                      {eventData.startyear ? eventData.startyear : null}
                    </h5>
                  </div>
                  {/* ----- day and month section end ----- */}

                  {/* ------ arrange mobile section start ------ */}
                  {/* <div className="asign_top asign_mobile">
                <div className="asign_item">
                  <h4>Arranged by</h4>
                  <div className="company_logotxt">
                    <span>
                      <img src={assetImages.googleLogo} alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                </div>
                <div className="asign_item">
                  <h4>Ranked 2nd</h4>
                </div>
              </div> */}
                  {/* ------ arrange mobile section end ------ */}

                  {/* ---- event title ----- */}
                  <div className="job_hdng evnt_hdng">
                    <h3>{eventData.title}</h3>
                  </div>
                  <div className="event_tags">
                    <div className="jb_rating">
                      <i className="material-icons-outlined"> place </i>
                      <span>
                        {eventData.address ? eventData.address + " ," : null}{" "}
                        {eventData.city ? eventData.city + "," : null}{" "}
                        {eventData.country ? eventData.country : null}
                      </span>
                    </div>
                    <div className="jb_rating star_color">
                      <i className="material-icons-outlined"> star </i>
                      <span>
                        {eventData.rating} ({eventData.reviews})
                      </span>
                    </div>
                  </div>

                  <div className="mobile_dateOuter">
                    <div className="mobile_date">
                      <h5>
                        <i className="material-icons-outlined">event_note</i>
                        {eventData.startday ? eventData.startday : null}{" "}
                        {eventData.startmonth ? eventData.startmonth : null}{" "}
                        {eventData.startyear ? eventData.startyear : null}
                      </h5>
                    </div>
                    <div className="jb_rating star_color">
                      <i className="material-icons-outlined"> star </i>
                      <span>{eventData.rating}</span>
                    </div>
                  </div>

                  <div className="vdo_link">
                    <Link to="#">
                      <i className="material-icons-outlined"> video_call </i>
                      <span>{eventData.website}</span>
                    </Link>
                  </div>
                  {/* <div className="evnt_list">
                <ul className="list_stye_none">
                  <li>
                    <i className="material-icons-outlined"> people </i>
                    Attending
                    <span>85m</span>
                  </li>
                  <li>
                    Interested
                    <span>25k</span>
                  </li>
                </ul>
              </div> */}
                </div>
                <div className="topdetails_right">
                  {/* <div className="asign_top">
                <div className="asign_item">
                  <h4>Arranged by</h4>
                  <div className="company_logotxt">
                    <span>
                      <img src={assetImages.googleLogo} alt="" />
                    </span>
                    <span>Google</span>
                  </div>
                </div>
                <div className="asign_item">
                  <h4>Ranked 2nd</h4>
                </div>
              </div> */}
                  <div className="evnt_rght_bttm">
                    <div className="exprt_share">
                      <ul className="list_stye_none">
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#event_addtag_mdl"
                          >
                            <i className="material-icons-outlined">more</i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            // data-bs-toggle="modal"
                            // data-bs-target="#thanks_event_join_mdl"
                          >
                            <i className="material-icons-outlined">share</i>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    {/* --------- Tags list and dropdown start --------- */}
                    <div className="tags_menu">
                      {/* -------- Top 3 tags here -------- */}
                      {eventData.tags.length > 0 ? (
                        <ul className="tags_list">
                          {eventData.tags.slice(0, 3).map((tags, index) => {
                            return (
                              <li key={index}>
                                <Link to="#">{tags.label}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      ) : null}

                      {/* -------- Remaining tags here in dropdown --------- */}
                      {eventData.tags.length > 3 ? (
                        <div className="tags_drop dropdown">
                          <Link to="#" data-bs-toggle="dropdown">
                            <span className="material-icons-outlined">
                              expand_more
                            </span>
                          </Link>
                          {eventData.tags.length > 0 ? (
                            <ul className="dropdown-menu">
                              {eventData.tags
                                .slice(3, eventData.tags.length)
                                .map((tags, index) => {
                                  return (
                                    <li key={index}>
                                      <Link to="#">{tags.label}</Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    {/* --------- Tags list and dropdown end --------- */}

                    {/* ----- Display approval date and join date by condition ----- */}

                    {isEventApproved ? (
                      <p className="">
                        Joining Date : {eventData.joindate} <br />
                        {eventData.myroles.length == 0 ||
                        eventData.myroles == null ||
                        eventData.myroles == undefined ? null : (
                          <>
                            Roles{" "}
                            <span>
                              {eventData.myroles.map((role, index) => {
                                return <span key={index}>|| {role} </span>;
                              })}
                            </span>
                          </>
                        )}
                      </p>
                    ) : eventData.moderatorstatus == 1 ||
                      eventData.memberstatus == 1 ? (
                      <p className="">
                        <i className="material-icons me-2">error</i>
                        {eventData.moderatorstatus == 0
                          ? "Moderator Response Pending"
                          : "Join invitation from Moderator"}
                        <br />
                        Request Date : {eventData.joindate}
                      </p>
                    ) : null}

                    {/* ----- feedback and join button section start ----- */}
                    <div className="feedback_jointbtnOuter">
                      {/* ----------- 'Feedback' button starts here ----------- */}
                      {/* <div className="feed_btn">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#event_details_feedback_modal"
                          className="border_btn"
                        >
                          Feedback
                        </Link>
                      </div> */}

                      {/* ----------- 'Join' button starts here ----------- */}
                      {isJoined ? null : (
                        <div className="details_apply">
                          <Link
                            to="#"
                            className="basic_btn"
                            onClick={joinEvent}
                            style={{ pointerEvents: isJoining ? "none" : "" }}
                          >
                            Join Event
                            {isJoining && (
                              <div
                                className="mx-2 spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </Link>
                        </div>
                      )}
                    </div>
                    {/* ----- feedback and join button section end ----- */}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ---- add tag modal component ----- */}
          <AddTagsModal afterModalClose={afterModalClose} />

          {/* ----- feedback modal component ----- */}
          {eventData == null ? null : (
            <EventDetailsFeedbackModal eventData={eventData} />
          )}
          <ThankJoinEventModal afterThanksModalClose={afterThanksModalClose} />
        </div>
      )}
    </>
  );
};
export default EventDetailsLeftTopSection;
