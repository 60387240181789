/* eslint-disable */
import Home from "../pages/home/Home";
import userProfile from "../pages/profile/userProfile";
import EventList from "../pages/events/EventList";
import EventDetails from "../pages/events/EventDetails";
import ExpertList from "../pages/experts/ExpertList";
import ExpertDetails from "../pages/experts/ExpertDetails";
import Logout from "../components/logincomponents/Logout";
import BlogList from "../pages/blogs/BlogList";
import BlogDetails from "../pages/blogs/BlogDetails";
import ConversationScreen from "../pages/Conversation/Conversation";
import Invitation from "../pages/Invitation/Invitation";

/*======= public routes =======*/
const publicRoutes = [
  { path: "/", component: Home }, // home page
  { path: "/blogs", component: BlogList }, // home page
  { path: "/blog/:postname", component: BlogDetails }, // home page
];
/*======= protected routes =======*/
const protectedRoutes = [
  { path: "/logout", component: Logout }, //logout handler
  { path: "/profile", component: userProfile }, // logged-in user profile
  { path: "/events", component: EventList }, //for event list
  { path: "/event/details/:id", component: EventDetails }, //for event details
  { path: "/experts", component: ExpertList }, //for expert list
  { path: "/expert/details/:id", component: ExpertDetails }, //for expert details
  { path: "/chat", component: ConversationScreen }, // for chat page
  { path: "/myinvitations", component: Invitation }, //for invitation page
];

export { publicRoutes, protectedRoutes };
