/* eslint-disable */
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
// import Select from "react-select";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../constants";

// import Gateway for API fetch call
import { postData } from "../../utils/Gateway.js";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

const InfoModal = () => {
  const { userRegisterData } = useContext(GlobalFindelOiProvider);

  const [firstName, setFirstName] = useState("");
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameWarning, setLastNameWarning] = useState(false);
  const [position, setPosition] = useState("");
  const [positionWarning, setPositionWarning] = useState(false);
  const [serviceValue, setServiceValue] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [serviceWarning, setServiceWarning] = useState(false);
  const [experience, setExperience] = useState("");
  const [experienceWarning, setExperienceWarning] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyWarning, setCompanyWarning] = useState(false);
  const [postPosition, setPostPosition] = useState("");
  const [postWarning, setPostWarning] = useState(false);
  const [peopleCharge, setPeopleCharge] = useState("");
  const [peopleWarning, setPeopleWarning] = useState(false);
  const [billing, setBilling] = useState("");
  const [billingWarning, setbillingWarning] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startdateWarning, setStartdateWarning] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [endDateWarning, setEndDateWarning] = useState(false);
  const [workingCurrently, setWorkingCurrently] = useState(false);
  const [aboutCompany, setAboutCompany] = useState("");
  const [aboutCompanyWarning, setaboutCompanyWarning] = useState(false);

  // start date processing handler
  const startDateChangeHandler = (e, value) => {
    e.preventDefault();
    let formattedDate = value.split("-");
    let day = formattedDate[2];
    let month = formattedDate[1];
    let year = formattedDate[0];
    setStartDate(`${day}/${month}/${year}`);
  };

  // end date processing handler
  const endDateChangeHandler = (e, value) => {
    e.preventDefault();
    let formattedDate = value.split("-");
    let day = formattedDate[2];
    let month = formattedDate[1];
    let year = formattedDate[0];
    setEndDate(`${day}/${month}/${year}`);
  };

  //service filter option
  const serviceFilterOption = [
    {
      id: 1,
      label: "Design",
      name: "design",
      value: "design",
    },
    {
      id: 2,
      label: "Coding",
      name: "coding",
      value: "coding",
    },
    { id: 3, label: "Mangement", name: "management", value: "management" },
  ];

  //function for select service
  const serviceSelectionHandler = (val) => {
    let serviceVal = val.map((item) => {
      return item.value;
    });
    setServiceValue();
    setSelectedServices(serviceVal);
  };

  //function for submit regitration form
  const formSubmitHandler = async () => {
    if (
      firstName == "" ||
      lastName == "" ||
      position == "" ||
      selectedServices.length == 0
    ) {
      if (firstName == "") {
        setFirstNameWarning(true);
      } else {
        setFirstNameWarning(false);
      }
      if (lastName == "") {
        setLastNameWarning(true);
      } else {
        setLastNameWarning(false);
      }
      if (position == "") {
        setPositionWarning(true);
      } else {
        setPositionWarning(false);
      }

      if (selectedServices.length == 0) {
        setServiceWarning(true);
      } else {
        setServiceWarning(false);
      }
    } else {
      setFirstNameWarning(false);
      setLastNameWarning(false);
      setPositionWarning(false);
      setServiceWarning(false);

      let requestURL =
        url.API_BaseUrl + url.DEMO_POST + `?token=${userRegisterData.token}`;

      let registerValues = {
        id: userRegisterData.id,
        email: userRegisterData.email,
        firstname: firstName,
        lastname: lastName,
        position: position,
        experience: experience,
        company: companyName,
        services: selectedServices,
        post: postPosition,
        people: peopleCharge,
        startdate: startDate,
        enddate: endDate,
        billing: billing,
        working: workingCurrently,
        aboutcompany: aboutCompany,
      };

      try {
        const response = await postData(requestURL, registerValues);
        if (response.status) {
          console.log("Profile submit response ----->", response);
          resetRegisterData();
          let infoModal = document.querySelector("#info_mdl");
          let modal = bootstrap.Modal.getInstance(infoModal);
          modal.hide();
          let myModal = new bootstrap.Modal(
            document.getElementById("documets_mdl")
          );
          myModal.show();
        }
      } catch (error) {
        console.log("Profile form error ----->", error);
      }
    }
  };

  //function for reset data
  const resetRegisterData = () => {
    setFirstName("");
    setLastName("");
    setPosition("");
    setExperience("");
    setServiceValue(null);
    setSelectedServices([]);
    setCompanyName("");
    setPostPosition("");
    setPeopleCharge("");
    setBilling("");
    setStartDate("");
    setEndDate("");
    setWorkingCurrently(false);
    setAboutCompany("");
  };

  return (
    <div className="registration_modal">
      <div className="modal fade show" id="info_mdl" data-bs-backdrop="static">
        <div className="modal-dialog medium_modal">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              {/* ---------- Modal heading starts here ---------- */}
              <div className="signin_hdng text-center">
                <span className="emoji">
                  <img src={assetImages.loginEmoji1} alt="" />
                </span>
                <h3>Great, need to know few more things</h3>
                <h5>Surely you want to know with whom you meet and for what</h5>
              </div>

              {/* ---------- Modal Close button starts here ---------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal Body starts here ----------- */}
            <div className="modal-body">
              <div className="login_body">
                <div className="login_infoArea">
                  <div className="login_info_item">
                    <div className="info_hdng_top">
                      <h3>About me</h3>
                    </div>
                    <div className="info_outerFill">
                      {/* --- first name section start ---- */}
                      <div className="input_fill w_50">
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter first name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstNameWarning(false);
                            setFirstName(e.target.value);
                          }}
                        />
                        {firstNameWarning && (
                          <p className="error mt-2">* Enter first name</p>
                        )}
                      </div>
                      {/* --- first name section end ---- */}

                      {/* --- last name section start ---- */}
                      <div className="input_fill w_50">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter last name"
                          value={lastName}
                          onChange={(e) => {
                            setLastNameWarning(false);
                            setLastName(e.target.value);
                          }}
                        />
                        {lastNameWarning && (
                          <p className="error mt-2">* Enter last name</p>
                        )}
                      </div>
                      {/* --- last name section end ---- */}

                      {/* --- position section start ---- */}
                      <div className="input_fill w_100">
                        <label>Position</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Position"
                          value={position}
                          onChange={(e) => {
                            setPositionWarning(false);
                            setPosition(e.target.value);
                          }}
                        />
                        {positionWarning && (
                          <p className="error mt-2">* Enter position</p>
                        )}
                      </div>
                      {/* --- position section end ---- */}

                      {/* --- experience section start ---- */}
                      <div className="input_fill w_100">
                        <label>Experience</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter experience"
                          value={experience}
                          onChange={(e) => {
                            setExperience(e.target.value);
                          }}
                        />
                        {experienceWarning && (
                          <p className="error mt-2">* Enter experience</p>
                        )}
                      </div>
                      {/* --- experience section end ---- */}

                      {/* --- service section start ---- */}
                      <div className="input_fill w_100">
                        <label>Services I offer</label>
                        <CreatableSelect
                          isMulti
                          className="basic-single"
                          classNamePrefix="select"
                          name="color"
                          options={serviceFilterOption}
                          value={serviceValue}
                          onChange={(val) => {
                            serviceSelectionHandler(val);
                            setServiceWarning(false);
                          }}
                        />
                        {serviceWarning && (
                          <p className="error mt-2">
                            * Enter at least one Service
                          </p>
                        )}
                        {/* <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                        /> */}
                        {/* <div className="srvc_tags">
                          <ul className="list_stye_none">
                            <li>
                              <Link to="#">design</Link>
                            </li>
                            <li>
                              <Link to="#">coding</Link>
                            </li>
                            <li>
                              <Link to="#">painting</Link>
                            </li>
                            <li>
                              <Link to="#">management</Link>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                      {/* --- service section end ---- */}
                    </div>
                  </div>
                  <div className="login_info_item">
                    <div className="info_hdng_top">
                      <h3>Professional life</h3>
                      {/* <Link to="#">
                        <i className="material-icons-outlined">
                          add_circle_outline
                        </i>
                        <span>Add Another</span>
                      </Link> */}
                    </div>
                    <div className="personel_information_area">
                      <div className="info_outerFill">
                        {/* --- company section start ---- */}
                        <div className="input_fill w_50">
                          <label>Company name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter company name"
                            value={companyName}
                            onChange={(e) => {
                              setCompanyName(e.target.value);
                            }}
                          />
                          {companyWarning && (
                            <p className="error mt-2">* Enter company name</p>
                          )}
                        </div>
                        {/* --- company section end ---- */}

                        {/* --- post section start ---- */}
                        <div className="input_fill w_50">
                          <label>Post</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter post name"
                            value={postPosition}
                            onChange={(e) => {
                              setPostPosition(e.target.value);
                            }}
                          />
                          {postWarning && (
                            <p className="error mt-2">* Enter post name</p>
                          )}
                        </div>
                        {/* --- post section end ---- */}

                        {/* --- people section start ---- */}
                        <div className="input_fill w_50">
                          <label>People in your charge</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            value={peopleCharge}
                            onChange={(e) => {
                              setPeopleCharge(e.target.value);
                            }}
                          />
                        </div>
                        {/* --- people section end ---- */}

                        {/* --- billing section start ---- */}
                        <div className="input_fill w_50">
                          <label>Billing (CTC)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter last name"
                            value={billing}
                            onChange={(e) => {
                              setBilling(e.target.value);
                            }}
                          />
                        </div>
                        {/* --- billing section end ---- */}

                        {/* --- start date section start ---- */}
                        <div className="input_fill w_50">
                          <label>Start date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter date"
                            onChange={(e) =>
                              startDateChangeHandler(e, e.target.value)
                            }
                          />
                        </div>
                        {/* --- start date section end ---- */}

                        {/* --- end date section start ---- */}
                        <div className="input_fill w_50">
                          <label>End date</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Enter date"
                            onChange={(e) =>
                              endDateChangeHandler(e, e.target.value)
                            }
                          />
                        </div>
                        {/* --- end date section end ---- */}
                      </div>

                      {/* --- working currently checkbox section start ---- */}
                      <div className="toggle_switch">
                        <input
                          type="checkbox"
                          id="switch"
                          value={workingCurrently}
                          onChange={(e) => {
                            setWorkingCurrently(e.target.checked);
                          }}
                        />
                        <label htmlFor="switch"></label>
                        <span>Working Currently</span>
                      </div>
                      {/* --- working currently checkbox section end ---- */}

                      {/* --- about company section start ---- */}
                      <div className="input_fill description_fill">
                        <label>About company</label>
                        <textarea
                          placeholder="Enter something about company"
                          className="form-control"
                          value={aboutCompany}
                          onChange={(e) => {
                            setAboutCompany(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      {/* --- about company section end ---- */}
                    </div>
                  </div>
                </div>
                <div className="loginstep_btnsouter">
                  <div className="left_btn">
                    <Link to="#" className="btn gray_btn">
                      Skip
                    </Link>
                  </div>
                  <div className="step_bullet">
                    <ul className="list_stye_none">
                      <li className="active"></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="right_btn">
                    <Link to="#" className="btn" onClick={formSubmitHandler}>
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------- Modal Body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
