/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

// import Gateway for API fetch call
import { uploadFile, getData, postData } from "../../utils/Gateway.js";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

const InterestsModal = () => {
  const { userRegisterData } = useContext(GlobalFindelOiProvider);

  const [categoriesValue, setcategoriesValue] = useState([]);

  /*---- react multi select rquirments start ------*/
  const [lookForValue, setLookForValue] = useState(null);
  const [selectLookFor, setSelectLookFor] = useState([]);
  const [lookForWarning, setlookForWarning] = useState(false);
  const [serviceValue, setServiceValue] = useState(null);
  const [selectedService, setSelectedService] = useState([]);
  const [serviceWarning, setServiceWarning] = useState(false);
  const [collaborateValue, setcollaborateValue] = useState(null);
  const [selectedCollaborates, setSelectedCollaborates] = useState([]);
  /*---- react multi select rquirments end ------*/

  /*---- days rquirments start ------*/
  const [selectedSunday, setSelectedSunday] = useState(false);
  const [selectedMonday, setSelectedmonday] = useState(false);
  const [selectedTuesday, setSelectedTuesday] = useState(false);
  const [selectedWednesday, setSelectedWednesday] = useState(false);
  const [selectedThursday, setSelectedThursday] = useState(false);
  const [selectedFriday, setSelectedFriday] = useState(false);
  const [selectedSaturday, setSelectedSaturday] = useState(false);
  /*---- days rquirments end ------*/

  /*---- time rquirments start ------*/
  const [sevenAm, setsevenAm] = useState(false);
  const [eightAm, seteightAm] = useState(false);
  const [nineAM, setnineAM] = useState(false);
  const [tenAm, settenAm] = useState(false);
  const [elevenAM, setelevenAM] = useState(false);
  const [twelvePm, settwelvePm] = useState(false);
  const [onePm, setonePm] = useState(false);
  const [twoPm, settwoPm] = useState(false);
  const [threePm, setthreePm] = useState(false);
  const [fourPm, setfourPm] = useState(false);
  const [fivePm, setfivePm] = useState(false);
  const [sixPm, setsixPm] = useState(false);
  const [sevenPm, setsevenPm] = useState(false);
  const [eightPm, seteightPm] = useState(false);
  const [ninePm, setninePm] = useState(false);

  // let token = "ZGVsOjE2Nzc3NDQ5MzI=";

  // function to get categories details from API
  const getCategories = async () => {
    let requestURL =
      url.API_BaseUrl +
      url.API_ACTIVITY_FILTER_CATEGORIES +
      `?token=${userRegisterData.token}`;

    console.log(requestURL);
    try {
      const response = await getData(requestURL);

      console.log("response in interest modal dropdown", response);

      if (response.status) {
        if (
          response.data.length == 0 ||
          response.data == null ||
          response.data == undefined
        ) {
          setcategoriesValue([]);
        } else {
          setcategoriesValue(response.data);
        }
      } else {
        setcategoriesValue([]);
      }
    } catch (error) {}
  };

  //function for select looking for
  const lookForSelect = (val) => {
    let serviceVal = val.map((item) => {
      return item.value;
    });
    setLookForValue();
    setSelectLookFor(serviceVal);
  };

  //function for select offer
  const offerSelect = (val) => {
    let serviceVal = val.map((item) => {
      return item.value;
    });
    setServiceValue();
    setSelectedService(serviceVal);
  };

  //function for select collaboration
  const collaborateSelection = (val) => {
    let serviceVal = val.map((item) => {
      return item.value;
    });
    setcollaborateValue();
    setSelectedCollaborates(serviceVal);
  };

  const sendDataHandler = async () => {
    if (lookForSelect.length == 0 || selectedService.length == 0) {
      if (lookForSelect.length == 0) {
        setlookForWarning(true);
      } else {
        setlookForWarning(false);
      }

      if (selectedService.length == 0) {
        setServiceWarning(true);
      } else {
        setServiceWarning(false);
      }
    } else {
      setlookForWarning(false);
      setServiceWarning(false);

      let registerData = {
        servicelook: selectLookFor,
        offer: selectedService,
        collaborate: selectedCollaborates,
        sunday: selectedSunday,
        monday: selectedMonday,
        tuesday: selectedTuesday,
        wednesday: selectedWednesday,
        thursday: selectedThursday,
        friday: selectedFriday,
        saturday: selectedSaturday,
        sevenam: sevenAm,
        eightam: eightAm,
        nineam: nineAM,
        tenam: tenAm,
        elevenam: elevenAM,
        twelvepm: twelvePm,
        onepm: onePm,
        twopam: twoPm,
        threepm: threePm,
        fourpm: fourPm,
        fivepm: fivePm,
        sixpm: sixPm,
        sevenpm: sevenPm,
        eightpm: eightPm,
        ninepm: ninePm,
      };

      let requestURL =
        url.API_BaseUrl + url.DEMO_POST + `?token=${userRegisterData.token}`;

      try {
        const response = await postData(requestURL, registerData);
        if (response.status) {
          console.log("Data submit response in last modal ----->", response);
          // resetRegisterData();
          let infoModal = document.querySelector("#interests_mdl");
          let modal = bootstrap.Modal.getInstance(infoModal);
          modal.hide();
          resetData();
          //  let myModal = new bootstrap.Modal(
          //    document.getElementById("documets_mdl")
          //  );
          //  myModal.show();
        }
      } catch (error) {
        console.log("Profile form error ----->", error);
      }
    }
  };

  const resetData = () => {
    setLookForValue(null);
    setSelectLookFor([]);
    setServiceValue(null);
    setSelectedService([]);
    setcollaborateValue(null);
    setSelectedCollaborates([]);
    setSelectedSunday(false);
    setSelectedmonday(false);
    setSelectedTuesday(false);
    setSelectedWednesday(false);
    setSelectedThursday(false);
    setSelectedFriday(false);
    setSelectedSaturday(false);
    setsevenAm(false);
    seteightAm(false);
    setnineAM(false);
    settenAm(false);
    setelevenAM(false);
    settwelvePm(false);
    setonePm(false);
    settwoPm(false);
    setthreePm(false);
    setfourPm(false);
    setfivePm(false);
    setsixPm(false);
    setsevenPm(false);
    seteightPm(false);
    setninePm(false);
  };

  useEffect(() => {
    if (userRegisterData != null) {
      getCategories();
    }
  }, [userRegisterData]);

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="interests_mdl"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog medium_modal">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              {/* ---------- Modal heading starts here ---------- */}
              <div className="signin_hdng text-center">
                <span className="emoji">
                  <img src={assetImages.loginEmoji3} alt="" />
                </span>
                <h3>What are your interests and availability?</h3>
                <h5>This information is essential for our team.</h5>
              </div>

              {/* ---------- Modal Close button starts here ---------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal Body starts here ----------- */}
            <div className="modal-body">
              <div className="login_body">
                <div className="info_outerFill">
                  {/* ----- look for selection section start ----- */}
                  <div className="input_fill w_100">
                    <label>Look for</label>
                    <Select
                      isMulti={true}
                      placeholder="Select Skill"
                      options={categoriesValue}
                      value={lookForValue}
                      classNamePrefix="select2-selection nosearch_select"
                      onChange={(val) => {
                        lookForSelect(val);
                      }}
                      // styles={customSelectStyle}
                      // onKeyDown={searchByEnterSelect}
                    />
                    {lookForWarning && (
                      <p className="error mt-2">
                        * please select service looking
                      </p>
                    )}
                  </div>
                  {/* ----- look for selection section end ----- */}

                  {/* ----- service selection section start ----- */}
                  <div className="input_fill w_100">
                    <label>Services I offer</label>
                    <Select
                      isMulti={true}
                      placeholder="Select Skill"
                      options={categoriesValue}
                      value={serviceValue}
                      classNamePrefix="select2-selection nosearch_select"
                      onChange={(val) => {
                        offerSelect(val);
                      }}
                      // styles={customSelectStyle}
                      // onKeyDown={searchByEnterSelect}
                    />
                    {serviceWarning && (
                      <p className="error mt-2">
                        * please select service offer
                      </p>
                    )}
                  </div>
                  {/* ----- service selection section end ----- */}

                  {/* ----- collaborate selection section start ----- */}
                  <div className="input_fill w_100">
                    <label>
                      Want to collaborate with companies that provide services
                      like
                    </label>
                    <Select
                      isMulti={true}
                      placeholder="Select Skill"
                      options={categoriesValue}
                      value={collaborateValue}
                      classNamePrefix="select2-selection nosearch_select"
                      onChange={(val) => {
                        collaborateSelection(val);
                      }}
                      // styles={customSelectStyle}
                      // onKeyDown={searchByEnterSelect}
                    />
                    {/* <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                    /> */}
                    {/* <div className="srvc_tags">
                      <ul className="list_stye_none">
                        <li>
                          <Link to="#">design</Link>
                        </li>
                        <li>
                          <Link to="#">coding</Link>
                        </li>
                        <li>
                          <Link to="#">painting</Link>
                        </li>
                        <li>
                          <Link to="#">management</Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  {/* ----- collaborate selection section end ----- */}

                  <div className="input_fill w_100">
                    <label>
                      Want to collaborate with companies that provide services
                      like
                    </label>
                    {/* ----- days selection section start ----- */}
                    <div className="days_bx">
                      <ul className="list_stye_none">
                        <li
                          className={selectedSunday ? "active" : ""}
                          onClick={() => {
                            setSelectedSunday(!selectedSunday);
                          }}
                        >
                          <span>Sun</span>
                        </li>
                        <li
                          className={selectedMonday ? "active" : ""}
                          onClick={() => {
                            setSelectedmonday(!selectedMonday);
                          }}
                        >
                          <span>Mon</span>
                        </li>
                        <li
                          className={selectedTuesday ? "active" : ""}
                          onClick={() => {
                            setSelectedTuesday(!selectedTuesday);
                          }}
                        >
                          <span>Tue</span>
                        </li>
                        <li
                          className={selectedWednesday ? "active" : ""}
                          onClick={() => {
                            setSelectedWednesday(!selectedWednesday);
                          }}
                        >
                          <span>Wed</span>
                        </li>
                        <li
                          className={selectedThursday ? "active" : ""}
                          onClick={() => {
                            setSelectedThursday(!selectedThursday);
                          }}
                        >
                          <span>Thu</span>
                        </li>
                        <li
                          className={selectedFriday ? "active" : ""}
                          onClick={() => {
                            setSelectedFriday(!selectedFriday);
                          }}
                        >
                          <span>Fri</span>
                        </li>
                        <li
                          className={selectedSaturday ? "active" : ""}
                          onClick={() => {
                            setSelectedSaturday(!selectedSaturday);
                          }}
                        >
                          <span>Sat</span>
                        </li>
                      </ul>
                    </div>
                    {/* ----- days selection section end ----- */}
                  </div>

                  {/* ----- time selection section start ----- */}
                  <div className="input_fill w_100">
                    <label>Preferable time to meet</label>
                    <div className="days_bx time_bx">
                      <ul className="list_stye_none">
                        <li
                          className={sevenAm ? "active" : ""}
                          onClick={() => {
                            setsevenAm(!sevenAm);
                          }}
                        >
                          <span>7:00AM</span>
                        </li>
                        <li
                          className={eightAm ? "active" : ""}
                          onClick={() => {
                            seteightAm(!eightAm);
                          }}
                        >
                          <span>8:00AM</span>
                        </li>
                        <li
                          className={nineAM ? "active" : ""}
                          onClick={() => {
                            setnineAM(!nineAM);
                          }}
                        >
                          <span>9:00AM</span>
                        </li>
                        <li
                          className={tenAm ? "active" : ""}
                          onClick={() => {
                            settenAm(!tenAm);
                          }}
                        >
                          <span>10:00AM</span>
                        </li>
                        <li
                          className={elevenAM ? "active" : ""}
                          onClick={() => {
                            setelevenAM(!elevenAM);
                          }}
                        >
                          <span>11:00AM</span>
                        </li>
                        <li
                          className={twelvePm ? "active" : ""}
                          onClick={() => {
                            settwelvePm(!twelvePm);
                          }}
                        >
                          <span>12:00PM</span>
                        </li>
                        <li
                          className={onePm ? "active" : ""}
                          onClick={() => {
                            setonePm(!onePm);
                          }}
                        >
                          <span>1:00PM</span>
                        </li>
                        <li
                          className={twoPm ? "active" : ""}
                          onClick={() => {
                            settwoPm(!twoPm);
                          }}
                        >
                          <span>2:00PM</span>
                        </li>
                        <li
                          className={threePm ? "active" : ""}
                          onClick={() => {
                            setthreePm(!threePm);
                          }}
                        >
                          <span>3:00PM</span>
                        </li>
                        <li
                          className={fourPm ? "active" : ""}
                          onClick={() => {
                            setfourPm(!fourPm);
                          }}
                        >
                          <span>4:00PM</span>
                        </li>
                        <li
                          className={fivePm ? "active" : ""}
                          onClick={() => {
                            setfivePm(!fivePm);
                          }}
                        >
                          <span>5:00PM</span>
                        </li>
                        <li
                          className={sixPm ? "active" : ""}
                          onClick={() => {
                            setsixPm(!sixPm);
                          }}
                        >
                          <span>6:00PM</span>
                        </li>
                        <li
                          className={sevenPm ? "active" : ""}
                          onClick={() => {
                            setsevenPm(!sevenPm);
                          }}
                        >
                          <span>7:00PM</span>
                        </li>
                        <li
                          className={eightPm ? "active" : ""}
                          onClick={() => {
                            seteightPm(!eightPm);
                          }}
                        >
                          <span>8:00PM</span>
                        </li>
                        <li
                          className={ninePm ? "active" : ""}
                          onClick={() => {
                            setninePm(!ninePm);
                          }}
                        >
                          <span>9:00PM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* ----- time selection section end ----- */}
                </div>
                <div className="loginstep_btnsouter">
                  <div className="left_btn">
                    <Link to="#" className="btn gray_btn">
                      Skip
                    </Link>
                  </div>
                  <div className="step_bullet">
                    <ul className="list_stye_none">
                      <li className=""></li>
                      <li className="active"></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <div className="right_btn">
                    <Link to="#" className="btn" onClick={sendDataHandler}>
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------- Modal Body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestsModal;
