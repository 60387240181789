import React from "react";

const ViewGalleryImageModal = ({ viewImageUrl }) => {
  return (
    <div className="job_modal">
      <div
        className="modal fade"
        id="view_event_gallery_image_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              <div className="company_logotxt"></div>

              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal body starts here ----------- */}
            <div className="modal-body">
              {viewImageUrl ? (
                <div>
                  <img
                    src={viewImageUrl}
                    alt="User"
                    className="img-fluid w-100 h-100 avatar"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGalleryImageModal;
