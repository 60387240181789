/* eslint-disable */
import { useEffect } from "react";
import { Link } from "react-router-dom";

// importing custom components here
import Header from "../../components/common/Header";
import EventDetailsArea from "../../components/eventdetailscomponents/EventDetailsArea";

const EventDetails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header activePage={"events"} />
      <section className="checkout_area job_serchSec">
        <div className="container">
          {/* ----------- Details Page banner & 'back' button ----------- */}
          <div className="page_back">
            <Link to="/events">
              <span className="material-icons-outlined"> arrow_back </span>
              Back
            </Link>
          </div>

          {/* ----------- Details Page main components starts here ----------- */}
          <div className="event_details_total">
            <EventDetailsArea />
          </div>
        </div>
      </section>
    </>
  );
};

export default EventDetails;
