/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../constants";

// importing modal here
import ContactModal from "./modals/ContactModal";

// import methods from gatway for API call
import { getData } from "../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

const ExpertListArea = () => {
  const {
    searchExpertName,
    setSearchExpertName,
    searchExpertLocation,
    setSearchExpertLocation,
    searchExpertSkills,
    setSearchExpertSkills,
  } = useContext(GlobalFindelOiProvider);

  // temp. list to map the card component
  const arrayList = [1, 2, 3, 4, 5];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const championListRef = useRef(null);

  // array for counting glow rows
  const glowCount = [1, 2, 3, 4];

  // Initial useState to set page numbers for pagination
  const [page, setPage] = useState(1);

  const [expertName, setExpertName] = useState("");
  const [selectedExpertId, setSelectedExpertId] = useState(null);

  //initial for champions lists
  const [championsLists, setChampionsLists] = useState([]);

  // initial for loading
  const [isLoading, setIsLoading] = useState(false);

  //initial for paginations
  const [totalPages, setTotalPages] = useState([]);
  const [lastPageNo, setLastPageNo] = useState(1);

  //function for set next page
  const nextPage = (data) => {
    setPage(data);
  };

  //function for toggle eventRef
  const championRefToggle = () => {
    championListRef.current?.scrollIntoView();
  };

  // function for all champion lists
  const getExpertsLists = async () => {
    let skillValue = "";

    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl +
      url.API_EXPERT_LIST +
      `?token=${token}` +
      `&page=${page}`;

    if (searchExpertName != "") {
      requestURL = requestURL + `&query=${searchExpertName}`;
    }

    if (searchExpertSkills != null && searchExpertSkills.length > 0) {
      searchExpertSkills.map((data) => {
        skillValue += `&skill[]=${data.id}`;
      });
      requestURL = requestURL + skillValue;
    }

    if (searchExpertLocation != "") {
      requestURL = requestURL + `&location=${searchExpertLocation}`;
    }

    console.log(requestURL);

    try {
      const response = await getData(requestURL);

      if (response.status) {
        if (response.data) {
          setChampionsLists(response.data);

          // set last page no from API
          setLastPageNo(response.totalpages);

          /*--- Creating dynamic array for pagination ----*/
          let pageArray = [];

          for (let i = 1; i <= response.totalpages; i++) {
            pageArray.push(i);
          }
          setTotalPages(pageArray);
          /*--- Creating dynamic array for pagination end ----*/

          console.log("Expert List API response ----> ", response.data);
        } else {
          setChampionsLists([]);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Expert List API error ----> ", error);
    }
  };

  //function for reset expert name
  const resetExpertName = () => {
    setSearchExpertName("");
    setPage(1);
  };

  //function for reset expert location
  const resetExpertLocation = () => {
    setSearchExpertLocation("");
    setPage(1);
  };

  //function for delete single skill
  const deleteSingleSkills = (index) => {
    if (index === 0) {
      setPage(1);
    }
    let deletedExpertSkill = [...searchExpertSkills];
    deletedExpertSkill.splice(index, 1);
    setSearchExpertSkills(deletedExpertSkill);
  };

  //function for reset expert skills
  const resetExpertSkills = () => {
    setSearchExpertSkills([]);
    setPage(1);
  };

  const afterModalClose = () => {
    getExpertsLists();
  };

  useEffect(() => {
    getExpertsLists();
  }, [page, searchExpertLocation, searchExpertName, searchExpertSkills]);

  // return statement from here
  return (
    <>
      {searchExpertName != "" ||
      searchExpertSkills.length > 0 ||
      searchExpertLocation != "" ? (
        <div className="mt-4">
          <div className="d-flex flex-wrap gap-2">
            {/* ---------- Search Title Section starts here ---------- */}

            {searchExpertName == "" ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Name
                  </label>

                  <label>{searchExpertName}</label>
                  <Link to="#" className="cancel" onClick={resetExpertName}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {/* ---------- Search category Section starts here ---------- */}
            {searchExpertSkills.length == 0 ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center justify-content-between gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Skills
                  </label>
                  <ul className="badges d-flex flex-wrap gap-2">
                    {searchExpertSkills.map((skills, index) => {
                      return (
                        <li
                          className="d-flex align-items-center px-2 gap-2 border"
                          key={index}
                        >
                          {skills.label}
                          <Link
                            to="#"
                            onClick={() => deleteSingleSkills(index)}
                          >
                            <i className="d-block material-icons">clear</i>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  <Link to="#" className="cancel" onClick={resetExpertSkills}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}

            {/* ---------- Search Location Section starts here ---------- */}
            {searchExpertLocation == "" ? null : (
              <div className="col-auto px-0">
                <div className="result-box d-flex align-items-center gap-3 p-2 border">
                  <label htmlFor="name" className="fw-bold">
                    Location
                  </label>
                  <label>{searchExpertLocation}</label>
                  <Link to="#" className="cancel" onClick={resetExpertLocation}>
                    <i className="d-block material-icons">clear</i>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}

      {/* ------------ Filter section for mobile version starts here ------------ */}
      <div className="filter_skillpnl">
        {/* -------- Mobile version back arrow button -------- */}
        <div className="filters_exprtHdng">
          <Link to="#" className="filter_back">
            <i className="material-icons-outlined">arrow_back </i>
          </Link>
          <h3>Filters</h3>
        </div>

        {/* -------- Trending skills list section for desktop & mobile version -------- */}
        <div className="skill_menu_outer">
          <label>Trending Skills</label>
          <div className="skill_menu">
            <ul className="list_stye_none">
              <li>
                <Link to="#">Project Manager</Link>
              </li>
              <li>
                <Link to="#">Product Manger</Link>
              </li>
              <li>
                <Link to="#">SEO</Link>
              </li>
              <li>
                <Link to="#">Content Writer</Link>
              </li>
              <li>
                <Link to="#">Blockchain Developer</Link>
              </li>
              <li>
                <Link to="#">Ui/UX Designer</Link>
              </li>
              <li>
                <Link to="#">Graphics</Link>
              </li>
            </ul>
          </div>
          <div className="more_skill">
            <Link to="#">
              More <i className="material-icons-outlined"> more_vert </i>
            </Link>
          </div>
        </div>

        {/* -------- Mobile version 'Hire me' button -------- */}
        <div className="hire_meBtn">
          <Link to="#" className="basic_btn">
            Hire Me
          </Link>
        </div>
      </div>
      {/* ------------ Filter section for mobile version starts here ------------ */}

      {/* ---------------- Cards List section starts here ---------------- */}
      {isLoading ? (
        <div className="expert_box_outer">
          {/* ---------- glow placeholder section starts here ---------- */}
          <div className="row">
            {glowCount.map((count) => {
              return (
                <div className="col-xl-3 col-lg-4 col-sm-6 col-6" key={count}>
                  <div className="expertbx_item">
                    <div className="expert_namepic">
                      <div className="exper_pic">
                        <span
                          className="placeholder"
                          style={{ width: "100%", height: "100%" }}
                        ></span>
                      </div>
                      <div className="exper_name">
                        <h3>
                          <span
                            className="placeholder"
                            style={{ width: "80%" }}
                          ></span>
                        </h3>
                        <h5>
                          <span
                            className="placeholder"
                            style={{ width: "50%" }}
                          ></span>
                        </h5>
                      </div>
                    </div>
                    <div className="expert_details">
                      <span
                        className="placeholder"
                        style={{
                          display: "block",
                          width: "70%",
                          height: "32px",
                          margin: " 0 auto 10px",
                          borderRadius: "8px",
                        }}
                      ></span>
                      <span
                        className="placeholder"
                        style={{
                          display: "block",
                          width: "50%",
                          height: "32px",
                          margin: "0 auto 10px",
                          borderRadius: "8px",
                        }}
                      ></span>
                      <div className="expert_btnOuter">
                        <div className="connect_btn">
                          <span
                            className="placeholder"
                            style={{
                              width: "100%",
                              height: "46px",
                              borderRadius: "5px",
                            }}
                          ></span>
                        </div>
                        <div className="exprt_detailsBtn">
                          <span
                            className="placeholder"
                            style={{
                              width: "100%",
                              height: "46px",
                              borderRadius: "5px",
                            }}
                          ></span>
                        </div>
                      </div>
                      <div className="list_arw">
                        <Link to="#">
                          <i className="material-icons-outlined">
                            {" "}
                            chevron_right{" "}
                          </i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* ---------- glow placeholder section ends here ---------- */}
        </div>
      ) : (
        <div className="expert_box_outer">
          {championsLists.length === 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={assetImages.emptyLesson} alt="" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>Sorry....!</h2>
                    <p>Experts are not available at this moment.</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div ref={championListRef} name="scrollChampionRefDiv" />
              {/* ---------- Card component starts here ---------- */}
              {championsLists.map((expert, index) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-6" key={index}>
                    <div className="expertbx_item">
                      {/* -------- Expert Top section -------- */}
                      <div className="expert_namepic">
                        {/* -------- Expert Image section -------- */}
                        <div className="exper_pic">
                          <img
                            src={
                              expert.fullimage != null
                                ? expert.fullimage
                                : assetImages.defaultUser
                            }
                            alt="expertProfile"
                          />
                        </div>

                        {/* -------- Expert name & position section -------- */}
                        <div className="exper_name">
                          <h3>
                            {expert.name} {expert.surname}
                          </h3>
                          <h5>{expert.position}</h5>
                        </div>
                      </div>

                      {/* -------- Expert Bottom section -------- */}
                      <div className="expert_details">
                        {/* ------- Rating & Reviews ------- */}
                        <div className="jb_rating star_color">
                          <i className="material-icons-outlined"> star </i>
                          <span>{expert.rating}</span>

                          <h6>
                            ({expert.reviews} <em>reviews</em>){" "}
                          </h6>
                        </div>

                        {/* ------- Hourly Rate ------- */}
                        <div className="jb_rating">
                          <h5>${expert.hourlyrate}/Hour</h5>
                        </div>

                        {/* ------- 'Connect' & 'Details' button ------- */}
                        <div className="expert_btnOuter">
                          {/* --------- 'Connect' button starts here --------- */}
                          {expert.id == userInfo.id ? null : (
                            <>
                              {expert.isconnect ? null : (
                                <div className="connect_btn">
                                  <Link
                                    to="#"
                                    className="basic_btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#contact_owner"
                                    onClick={() => {
                                      setExpertName(
                                        `${expert.name}${" "}${expert.surname}`
                                      );
                                      setSelectedExpertId(expert.id);
                                    }}
                                  >
                                    Connect
                                  </Link>
                                </div>
                              )}
                            </>
                          )}

                          {/* --------- 'Details' button starts here --------- */}
                          <div
                            className={
                              expert.id == userInfo.id || expert.isconnect
                                ? "exprt_detailsBtn contact_btn"
                                : "exprt_detailsBtn"
                            }
                          >
                            <Link
                              to={`/expert/details/${expert.id}`}
                              className="border_btn jbdetails_btn"
                            >
                              Details
                            </Link>
                          </div>
                        </div>

                        <div className="list_arw bg-danger">
                          <Link to="#">
                            <i className="material-icons-outlined">
                              {" "}
                              chevron_right{" "}
                            </i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* ---------------- Cards List section ends here ---------------- */}

      {/* ---------------- Pagination section starts here ---------------- */}
      <div className="job_pagination">
        <ul className="list_stye_none">
          {/* ---------- Left button ---------- */}
          <li className="prev_pagnation">
            <Link
              to="#"
              onClick={() => {
                if (page != 1) {
                  nextPage(page - 1);
                  championRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_left
              </span>
            </Link>
          </li>

          {totalPages.map((pageNo) => {
            return (
              <li className={page == pageNo ? "active" : ""} key={pageNo}>
                <Link
                  to="#"
                  onClick={() => {
                    nextPage(pageNo);
                    championRefToggle();
                  }}
                >
                  {pageNo}
                </Link>
              </li>
            );
          })}

          {/* ---------- Right button ---------- */}
          <li className="next_pagination">
            <Link
              to="#"
              onClick={() => {
                if (page != lastPageNo) {
                  nextPage(page + 1);
                  championRefToggle();
                }
              }}
            >
              <span className="material-icons-outlined">
                keyboard_arrow_right
              </span>
            </Link>
          </li>
        </ul>
      </div>
      {/* ---------------- Pagination section ends here ---------------- */}

      {/* --------- Contact Modal implemented here --------- */}
      <ContactModal
        expertName={expertName}
        afterModalClose={afterModalClose}
        token={token}
        selectedExpertId={selectedExpertId}
      />
    </>
  );
};

export default ExpertListArea;
