/* eslint-disable */
import { Switch } from "react-router-dom";
import FindeloiContext from "./context/FindeloiContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AuthRouteMiddleware from "./routes/AuthMiddleWare";
import { publicRoutes, protectedRoutes } from "./routes/Route";

const App = () => {
  const clientIdValue =
    "671139683852-7ld0usbuv2nj13n5onihvf1dh54628tv.apps.googleusercontent.com";

  return (
    <FindeloiContext>
      <GoogleOAuthProvider clientId={clientIdValue}>
        <Switch>
          {/* ====== non authenticated routes ====== */}
          {publicRoutes.map((routename, index) => (
            <AuthRouteMiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={false}
            />
          ))}
          {/* ======end of  non authenticated routes ====== */}

          {/* ====== authenticated routes ====== */}
          {protectedRoutes.map((routename, index) => (
            <AuthRouteMiddleware
              path={routename.path}
              component={routename.component}
              key={index}
              exact
              isAuthProtected={true}
            />
          ))}
          {/* ====== end of authenticated routes ====== */}
          {/* <Route path="*" component={Error} /> */}
        </Switch>
      </GoogleOAuthProvider>
    </FindeloiContext>
  );
};

export default App;
