/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { postData } from "../../utils/Gateway";
import * as url from "../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const SignupModal = () => {
  const loadingCircle = [1, 2, 3, 4, 5, 6, 7]; // for loading status

  const { setUserSignUpEmail, setConfirmOtpCode } = useContext(
    GlobalFindelOiProvider
  );

  // initial for sign up values
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstNameWarning, setFirstNameWarning] = useState(false);
  const [lastNameWarning, setLastNameWarning] = useState(false);
  const [emailWarning, setEmailWarning] = useState(false);
  const [emailIsNotValid, setEmailIsNotValid] = useState(false);
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [confirmPasswordWarning, setConfirmPasswordWarning] = useState(false);
  const [matchPassWarning, setMatchPassWarning] = useState(false);
  const [agreeWarning, setAgreeWarning] = useState(false);
  const [accountCreating, setAccountCreating] = useState(false);

  //function for toggle show password
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  //function for toggle show confirm password
  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // function for complete registration process
  const signUpHandler = async (event) => {
    event.preventDefault();

    if (email == "" || password == "") {
      //email validation
      if (email == "") {
        setEmailWarning(true);
      } else {
        setEmailWarning(false);
      }

      //password validation
      if (password == "") {
        setPasswordWarning(true);
      } else {
        setPasswordWarning(false);
      }

      // agree validation
      // if (agree == false) {
      //   setAgreeWarning(true);
      // } else {
      //   setAgreeWarning(false);
      // }
    } else {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      // setFirstNameWarning(false);
      // setLastNameWarning(false);
      setEmailWarning(false);
      setPasswordWarning(false);
      // setConfirmPasswordWarning(false);
      setAgreeWarning(false);

      if (email.match(mailformat)) {
        setEmailIsNotValid(false);
        setMatchPassWarning(false);
        setAccountCreating(true);

        // exectuing code for submit here
        try {
          let requestUrl = url.API_BaseUrl + url.API_REGISTARTION;

          console.log(requestUrl);

          let registartionData = {
            // firstName,
            // lastName,
            email,
            password,
            agree: true,
            // captcha,
          };

          let result = await postData(requestUrl, registartionData);

          console.log(result);

          if (result.status) {
            setUserSignUpEmail(result.data.email);
            setConfirmOtpCode(result.code);
            let loginNowModal = document.querySelector("#register_mdl");
            let modal = bootstrap.Modal.getInstance(loginNowModal);
            modal.hide();
            let myModal = new bootstrap.Modal(
              document.getElementById("otp_mdl")
            );
            myModal.show();
            resetData();
            setAccountCreating(false);
          }
        } catch (error) {}
      } else {
        setEmailIsNotValid(true);
      }
    }
  };

  const resetData = () => {
    setEmail("");
    setfirstName("");
    setlastName("");
    setPassword("");
    setConfirmPassword("");
    setAgree(false);
  };

  //  useEffect(() => {
  //    let preUrl = document.referrer;
  //    if (token) {
  //      if (preUrl == "" || preUrl == null) {
  //        history.push("/");
  //      } else {
  //        window.location.href = preUrl;
  //      }
  //    }
  //  }, [token]);

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="register_mdl"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-center">
                <h3>Sign up now</h3>
                <h5>Please enter details to proceed</h5>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetData}
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_body">
                <form onSubmit={signUpHandler}>
                  <div className="login_fillOuter">
                    <div className="input_fill">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => {
                          setEmailWarning(false);
                          setEmail(e.target.value);
                        }}
                      />
                      {emailWarning && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Email you use for login or reset password
                        </p>
                      )}

                      {emailIsNotValid && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Enter valid email please
                        </p>
                      )}
                    </div>
                    <div className="input_fill">
                      <label>Password</label>
                      <div className="eye_btnfill">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Enter Password"
                          value={password}
                          onChange={(e) => {
                            setPasswordWarning(false);
                            setPassword(e.target.value);
                          }}
                        />
                        <Link
                          to="#"
                          className="copy_link"
                          onClick={togglePassword}
                        >
                          <span className="material-icons-outlined">
                            {" "}
                            visibility
                          </span>
                        </Link>
                      </div>
                    </div>
                    {passwordWarning && (
                      <p className="error">
                        <span className="material-icons-outlined"> info</span>
                        Password at least 3 digit
                      </p>
                    )}
                  </div>

                  <div className="forgot_applyOuter">
                    <div className="trms_check">
                      <label className="radio-check">
                        <input
                          type="checkbox"
                          checked={agree}
                          value={agree}
                          onChange={(e) => {
                            setAgreeWarning(false);
                            setAgree(e.target.checked);
                          }}
                        />
                        <span></span>
                        <p>
                          Agree with the <Link to="#">T&amp;C</Link>
                        </p>
                      </label>
                      {agreeWarning && (
                        <p className="error">
                          <span className="material-icons-outlined"> info</span>
                          Please agree with terms and condition
                        </p>
                      )}
                    </div>
                    <div className="apply_btn">
                      <button
                        type="submit"
                        disabled={accountCreating ? true : false}
                        style={{
                          cursor: accountCreating ? "not-allowed" : "pointer",
                        }}
                        className="btn"
                      >
                        Create Now
                        {accountCreating && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="create_userOuter">
                    <div className="create_text">
                      <p>
                        Already have an account{" "}
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          data-bs-target="#login_modal"
                          onClick={resetData}
                        >
                          Login here
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupModal;
