/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

// import Gateway for API fetch call
import { getData } from "../../../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../../../context/FindeloiContext";

// importing images here
import { assetImages } from "../../../../../constants";
import ViewGalleryImageModal from "./tabmodals/ViewGalleryImageModal";

const GalleryTab = () => {
  const token = localStorage.getItem("token");

  const params = useParams();

  const gallerGlowCount = [1, 2, 2, 3, 4, 5, 6, 7, 8, 9];

  const { isEventApproved, isEventModerator } = useContext(
    GlobalFindelOiProvider
  );

  const [galleryData, setGalleryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewImageUrl, setViewImageUrl] = useState(null);

  const getGalleryData = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BaseUrl +
        url.GALLERY_LISTS +
        `?token=${token}` +
        `&id=${params.id}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        if (response.data) {
          if (response.data.length == 0) {
            setGalleryData([]);
          } else {
            if (response.data.gallery) {
              setGalleryData(response.data.gallery);
            } else {
              setGalleryData([]);
            }
          }
        } else {
          setGalleryData([]);
        }
        setIsLoading(false);
      } else {
        setGalleryData([]);
      }
    } catch (error) {}
  };

  // function view image
  const ViewImageHandler = (gallery) => {
    setViewImageUrl(gallery.fullimage);
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  return (
    <div className="tab-pane fade show active">
      {isLoading ? (
        <div className="job_detailspnl">
          <div className="contnt_hdng">
            <h4>
              <span className="placeholder" style={{ width: "60px" }}></span>
            </h4>
          </div>
          <div className="event_galleryOuter">
            {gallerGlowCount.map((count, index) => {
              return (
                <div className="evnt_gallery_item" key={index}>
                  <div className="evnt_glry_pic">
                    <span
                      className="placeholder rounded-2"
                      style={{ width: "150px", height: "150px" }}
                    ></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="job_detailspnl">
          <div className="contnt_hdng">
            <h4>Gallery</h4>
          </div>
          <div className="event_gallery">
            {isEventApproved || isEventModerator ? (
              <div className="event_galleryOuter">
                {galleryData.map((gallery, index) => {
                  return (
                    <div className="evnt_gallery_item" key={index}>
                      <div className="evnt_glry_pic">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#view_event_gallery_image_modal"
                          onClick={() => ViewImageHandler(gallery)}
                        >
                          <img src={gallery.fullimage} alt="" />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="empty_lessonsArea">
                  <div className="empty_lessonpic">
                    <img src={assetImages.emptyLesson} alt="blanklesson" />
                  </div>
                  <div className="empty_lessons_text">
                    <h2>Sorry....!</h2>
                    {galleryData.length === 0 ? (
                      <p className="mx-2">
                        Galleries are not available for this event at this
                        moment
                      </p>
                    ) : (
                      <p className="mx-2">You dont have permission</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ViewGalleryImageModal viewImageUrl={viewImageUrl} />
    </div>
  );
};

export default GalleryTab;
