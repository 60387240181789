/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../../constants";

const ExpertDetailsRightPanel = () => {
  return (
    <div className="col-lg-4">
      <div className="details_rightPnl">
        <div className="details_hdng">
          <h3>Similar Profiles</h3>
        </div>
        <div className="pnl_outer">
          {/* ---------- Similar profile card starts here ---------- */}
          <div className="expert_pnl_row">
            <div className="experts_profiles_row">
              {/* -------- Expert image here -------- */}
              <div className="exprt_profile">
                <img src={assetImages.expert4} alt="" />
              </div>

              {/* ------ Expert name, position, star-rating & rate starts here ------ */}
              <div className="exprts_profileinfo">
                <h3>Martina Berasategui</h3>
                <h5>Sales Manager</h5>

                {/* -------- Star rating and rate section starts here -------- */}
                <div className="info_rating star_color">
                  <ul className="list_stye_none">
                    <li>
                      <i className="material-icons-outlined"> star </i>
                      <span>4.5</span>
                    </li>
                    <li>$15.00/Hr</li>
                  </ul>
                </div>
              </div>
              {/* ------ Expert name, position, star-rating & rate ends here ------ */}
            </div>

            {/* ------ 'Connect' & 'Details' button starts here ------ */}
            <div className="expert_btnOuter">
              <div className="connect_btn">
                <Link
                  to="#"
                  className="basic_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#contact_owner"
                >
                  Connect
                </Link>
              </div>
              <div className="exprt_detailsBtn">
                <Link to="#" className="border_btn jbdetails_btn">
                  Details
                </Link>
              </div>
            </div>
            {/* ------ 'Connect' & 'Details' button ends here ------ */}
          </div>
          {/* ---------- Similar profile card ends here ---------- */}
        </div>
      </div>
    </div>
  );
};

export default ExpertDetailsRightPanel;
