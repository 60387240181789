/* eslint-disable */
import React from "react";

// importing custom components here
import Header from "../../components/common/Header";
import Banner from "../../components/profilecomponents/Banner";
import ProfileArea from "../../components/profilecomponents/ProfileArea";
import Footer from "../../components/common/Footer";

const userProfile = () => {
  return (
    <>
      <Header activePage={"experts"} />
      <Banner />
      <ProfileArea />
      <Footer />
    </>
  );
};

export default userProfile;
