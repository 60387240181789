/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images
import { assetImages } from "../../../../../constants";

const PortfolioTab = () => {
  return (
    <div className="tab-pane fade show active" id="portfolio">
      <div className="job_detailspnl">
        <div className="contnt_hdng">
          <h4>Portfolio</h4>
        </div>
        <div className="portfolio_outer">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio_pic">
                <img src={assetImages.portfolio1} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio_pic">
                <img src={assetImages.portfolio2} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio_pic">
                <img src={assetImages.portfolio3} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio_pic">
                <img src={assetImages.portfolio4} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio_pic">
                <img src={assetImages.portfolio5} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioTab;
