/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

// importing images here
// import { assetImages } from "../../../constants";

const QuestionModal = () => {
  return (
    <div className="job_modal question_mdl">
      <div
        className="modal fade"
        id="question_1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* -------------- Modal Header starts here -------------- */}
            <div className="modal-header">
              <div className="modal_hdng">
                <h4>How can you put question here?</h4>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>

            {/* -------------- Modal Body starts here -------------- */}
            <div className="modal-body">
              <div className="ranking_tableinnr">
                <table>
                  {/* -------------- Table Head starts here -------------- */}
                  <thead>
                    <tr>
                      <th>
                        <span>
                          User Name
                          <Link to="#">
                            <i className="material-icons-outlined">
                              {" "}
                              unfold_more{" "}
                            </i>
                          </Link>
                        </span>
                      </th>

                      <th>
                        <span>
                          Ranking
                          <Link to="#">
                            <i className="material-icons-outlined">
                              {" "}
                              unfold_more{" "}
                            </i>
                          </Link>
                        </span>
                      </th>
                    </tr>
                  </thead>

                  {/* -------------- Table Body starts here -------------- */}
                  <tbody>
                    {/* -------------- Row 1 starts here -------------- */}
                    <tr>
                      <td>
                        <div className="ranking_usr">
                          <i className="material-icons-outlined"> person </i>
                          <span>Helga Vass</span>
                        </div>
                      </td>

                      <td>
                        <div className="modal_progres">
                          <label>84%</label>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "84%" }}
                            ></span>
                          </div>
                        </div>
                      </td>
                    </tr>

                    {/* -------------- Row 1 starts here -------------- */}
                    <tr>
                      <td>
                        <div className="ranking_usr">
                          <i className="material-icons-outlined"> person </i>
                          <span>Cristian Benabue</span>
                        </div>
                      </td>

                      <td>
                        <div className="modal_progres">
                          <label>83%</label>
                          <div className="progess_outer">
                            <span
                              className="progress_bar"
                              style={{ width: "83%" }}
                            ></span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionModal;
