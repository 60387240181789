/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

//import urls
import * as url from "../../urlhelpers/UrlHelper";
import { getData } from "../../utils/Gateway";

//import context file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const LoggedInUserMenu = () => {
  const { loadUserLoggedInMenu, setLoadUserLoggedInMenu } = useContext(
    GlobalFindelOiProvider
  );

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [userName, setUserName] = useState("");
  const [userProfilePic, setUserProfilePic] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function for get logged user details after refresh
  const getUserInfo = async () => {
    setIsLoading(true);
    let requestURL =
      url.API_BaseUrl + url.API_GET_USER_DETAILS + `?token=${token}`;

    let response = await getData(requestURL);

    // console.log("user profile response----->", response);

    if (response.status) {
      if (response.data) {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        localStorage.setItem("userID", JSON.stringify(response.data.id));

        setUserName(response.data.name);
        if (response.data.image) {
          setUserProfilePic(response.data.image);
        } else {
          setUserProfilePic(assetImages.defaultUser);
        }
      }
      setIsLoading(false);
    }
  };

  //use effect for load after profile update
  useEffect(() => {
    if (loadUserLoggedInMenu) {
      getUserInfo();
      setLoadUserLoggedInMenu(false);
    }
  }, [loadUserLoggedInMenu]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="loginuser_area">
      <ul className="list_stye_none">
        <li className="dropdown">
          {/* ------------ 'Hello' msg & Image here ------------ */}
          {isLoading ? null : (
            <Link to="#" data-bs-toggle="dropdown" className="usr_pnlBtn">
              <div className="usr_text home_user_text">
                Hello <span>{userName || userInfo.name}</span>
              </div>
              <div className="usr_pic">
                <img
                  src={
                    userProfilePic ? userProfilePic : assetImages.defaultUser
                  }
                  alt=""
                />
              </div>
            </Link>
          )}

          {/* ------------ Dropdown Menu Items ------------ */}
          <div className="menurightPnl dropdown-menu">
            <div className="account_topUsr">
              <div className="account_user_outer">
                <div className="accont_usrPic">
                  <img
                    src={
                      userProfilePic ? userProfilePic : assetImages.defaultUser
                    }
                    alt=""
                  />
                </div>
                <div className="accont_owner_name">
                  <h3>{userInfo.name}</h3>
                </div>
              </div>
              <div className="logout_btn">
                <Link to="/logout">
                  <span className="material-icons-outlined"> logout </span>
                </Link>
              </div>
            </div>

            <div className="afterlogin_menu">
              <ul className="list_stye_none">
                <li>
                  {/* <Link to="#">
                    <i className="material-icons-outlined">space_dashboard</i>
                    <span>Dashboard</span>
                  </Link> */}
                  <a
                    // to={{
                    //   pathname:
                    //     url.API_BACKEND_URL +
                    //     "?view=dashboard&token=" +
                    //     token,
                    // }}
                    // target="_blank"
                    href={
                      url.API_BACKEND_URL + "?view=dashboard&token=" + token
                    }
                  >
                    <i className="material-icons-outlined">space_dashboard</i>
                    <span>Dashboard</span>
                  </a>
                </li>

                {/* ----- profile link ----- */}
                <li>
                  <Link to="/profile">
                    <i className="material-icons-outlined">account_circle</i>
                    <span>My profile</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/events">
                    <i className="material-icons-outlined"> event_note </i>
                    <span>All Event</span>
                  </Link>
                </li> */}
                {/* ----- message section start ---- */}
                <li>
                  {/* <Link to="#">
                    <i className="material-icons-outlined"> email </i>
                    <span>Messages</span>
                  </Link> */}
                  <a
                    // to={{
                    //   pathname:
                    //     url.API_BACKEND_URL +
                    //     "?view=notification&token=" +
                    //     token,
                    // }}
                    // target="_blank"
                    href={
                      url.API_BACKEND_URL + "?view=notification&token=" + token
                    }
                  >
                    <i className="material-icons-outlined">mark_chat_unread</i>
                    <span>Notification</span>
                  </a>
                </li>
                {/* ----- message section end ---- */}

                {/* <li>
                  <Link to="#">
                    <i className="material-icons-outlined">
                      video_camera_front
                    </i>
                    <span>Video Meeting</span>
                  </Link>
                </li> */}

                {/* <li>
                  <Link to="#">
                    <i className="material-icons-outlined"> slideshow </i>
                    <span>Training</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/myinvitations">
                    <i className="material-icons-outlined">
                      connect_without_contact
                    </i>
                    <span>My Connect Invites</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default LoggedInUserMenu;
