/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

// import methods from gatway for API call
import { getData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../../constants";

const EventDetailsRightPanel = () => {
  const token = localStorage.getItem("token");

  const params = useParams();

  const glowCount = [1, 2, 3];

  const [eventsData, setEventsData] = useState([]); // initial useState for API data
  const [isLoading, setIsLoading] = useState(false); // initial for loading

  // function for all events lists
  const getEventsLists = async () => {
    // let eventCategoryList;

    setIsLoading(true);

    let requestURL;

    if (token != null) {
      requestURL =
        url.API_BaseUrl + url.API_EVENT_LIST + `?token=${token}` + `&page=1`;
    } else {
      requestURL = url.API_BaseUrl + url.API_EVENT_LIST + `?page=${page}`;
    }

    // console.log("request url for event list----->", requestURL);

    try {
      const response = await getData(requestURL);

      // console.log("response in events similar------>", response);

      if (response.status) {
        setIsLoading(false);
        if (response.data) {
          const eventFilteredList = response.data.filter((events) => {
            return events.id != params.id;
          });
          setEventsData(eventFilteredList);
        } else {
          setEventsData([]);
        }
      } else {
        setEventsData([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getEventsLists();
  }, [params.id]);

  return (
    <div className="col-lg-4">
      {isLoading ? (
        <div className="details_rightPnl">
          <div className="details_hdng">
            <h3>
              <span className="placeholder col-5"></span>
            </h3>
          </div>
          <div className="pnl_outer">
            {glowCount.map((count) => {
              return (
                <div className="job_bxItem event_bxItem" key={count}>
                  <div className="top_company_row">
                    <div className="company_logotxt">
                      <span>
                        {/* <img src="images/clogo6.png" alt="" /> */}
                      </span>
                      <span
                        className="placeholder"
                        style={{ width: "50px", height: "14px" }}
                      ></span>
                    </div>
                    <div className="rating_tags">
                      <span
                        className="placeholder rounded-2"
                        style={{ width: "60px", height: "32px" }}
                      ></span>
                      <div className="tags_icon">
                        <span
                          className="placeholder"
                          style={{ width: "18px", height: "18px" }}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div className="right_evnt_pic">
                    <span
                      className="placeholder rounded-2"
                      style={{ width: "320px", height: "180px" }}
                    ></span>
                  </div>
                  <div className="job_details">
                    <div className="job_hdng">
                      <h3>
                        <span className="placeholder col-10"></span>
                      </h3>
                      <h5>
                        <span className="placeholder col-4"></span>
                      </h5>
                    </div>
                    <div className="job_tags">
                      <ul className="list_stye_none">
                        <span
                          className="placeholder rounded-3 me-2"
                          style={{ width: "125px", height: "30px" }}
                        ></span>
                        <span
                          className="placeholder rounded-3"
                          style={{ width: "80px", height: "30px" }}
                        ></span>
                      </ul>
                    </div>
                    <div className="event_para">
                      <p>
                        <span className="placeholder col-10"></span>
                        <span className="placeholder col-6"></span>
                      </p>
                    </div>
                    <div className="details_connect">
                      <span
                        className="placeholder col-12 rounded-3"
                        style={{ height: "46px" }}
                      ></span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="details_rightPnl">
          <div className="details_hdng">
            <h3>Similar Events</h3>
          </div>

          {/* --------------- Similar Event Cards section starts here --------------- */}
          <div className="pnl_outer">
            {eventsData.slice(0, 3).map((event, index) => {
              return (
                <div className="job_bxItem event_bxItem" key={index}>
                  <div className="top_company_row">
                    <div className="company_logotxt">
                      <span>
                        <img src={assetImages.googleLogo} alt="" />
                      </span>
                      <span>Google</span>
                    </div>
                    <div className="rating_tags">
                      <div className="jb_rating star_color">
                        <i className="material-icons-outlined"> star </i>
                        <span>{event.rating}</span>
                      </div>
                      <div className="tags_icon">
                        <Link to="#">
                          <span className="material-icons-outlined">
                            bookmark_border
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="right_evnt_pic">
                    <img src={event.image ? event.image : ""} alt="" />
                  </div>
                  <div className="job_details">
                    <div className="job_hdng">
                      <h3>{event.title}</h3>
                      <h5>
                        <i className="material-icons-outlined"> event_note </i>
                        <span>
                          {event.startday} {event.startmonth} {event.startyear}
                        </span>
                      </h5>
                    </div>
                    <div className="job_tags">
                      <ul className="list_stye_none">
                        <li>
                          <i className="material-icons-outlined"> place </i>
                          <span>
                            {event.city ? event.city : null} ,{" "}
                            {event.country ? event.country : null}
                          </span>
                        </li>
                        {/* <li>
                          <span>Wedding</span>
                        </li> */}
                      </ul>
                    </div>
                    <div className="event_para">
                      <p>{event.summary}</p>
                    </div>
                    <div className="details_connect">
                      <Link
                        to={`/event/details/${event.id}`}
                        className="border_btn"
                        onClick={getEventsLists}
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetailsRightPanel;
