/* eslint-disable */
import { Link } from "react-router-dom";

//import react carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// importing images here
import { assetImages } from "../../constants";

const HomeReview = () => {
  const CardResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="review_area pd_80">
      <div className="container">
        <div className="page_heading text-center">
          <h5>¿Qué opinan los líderes?</h5>
          <h2>Innovación abierta</h2>
        </div>
        <div className="review_slideouter">
          <div className="review_slider">
            <Carousel ssr responsive={CardResponsive}>
              <div className="review_slideItem">
                <div className="review_slideInnr">
                  <div className="rvw_user_outer">
                    <div className="rvw_usr">
                      <img src={assetImages.expert1} alt="" />
                    </div>
                    <div className="rvw_usr_name">
                      <h4>José Luis Ramírez</h4>
                      <h5>Jefe de ventas</h5>
                    </div>
                  </div>
                  <div className="rvw_para">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipis cing elit.
                      Donec iaculis, diam euismod tincidunt elementum, nulla
                      felis.
                    </p>
                  </div>
                  <div className="usr_rvw_star">
                    <ul className="list_stye_none">
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="review_slideItem">
                <div className="review_slideInnr">
                  <div className="rvw_user_outer">
                    <div className="rvw_usr">
                      <img src={assetImages.expert2} alt="" />
                    </div>
                    <div className="rvw_usr_name">
                      <h4>José Luis Ramírez</h4>
                      <h5>Jefe de ventas</h5>
                    </div>
                  </div>
                  <div className="rvw_para">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipis cing elit.
                      Donec iaculis, diam euismod tincidunt elementum, nulla
                      felis.
                    </p>
                  </div>
                  <div className="usr_rvw_star">
                    <ul className="list_stye_none">
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="review_slideItem">
                <div className="review_slideInnr">
                  <div className="rvw_user_outer">
                    <div className="rvw_usr">
                      <img src={assetImages.expert2} alt="" />
                    </div>
                    <div className="rvw_usr_name">
                      <h4>José Luis Ramírez</h4>
                      <h5>Jefe de ventas</h5>
                    </div>
                  </div>
                  <div className="rvw_para">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipis cing elit.
                      Donec iaculis, diam euismod tincidunt elementum, nulla
                      felis.
                    </p>
                  </div>
                  <div className="usr_rvw_star">
                    <ul className="list_stye_none">
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="review_slideItem">
                <div className="review_slideInnr">
                  <div className="rvw_user_outer">
                    <div className="rvw_usr">
                      <img src={assetImages.expert2} alt="" />
                    </div>
                    <div className="rvw_usr_name">
                      <h4>José Luis Ramírez</h4>
                      <h5>Jefe de ventas</h5>
                    </div>
                  </div>
                  <div className="rvw_para">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipis cing elit.
                      Donec iaculis, diam euismod tincidunt elementum, nulla
                      felis.
                    </p>
                  </div>
                  <div className="usr_rvw_star">
                    <ul className="list_stye_none">
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li className="active_star">
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <span className="material-icons-outlined">star</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeReview;
