/* eslint-disable */
import { Link } from "react-router-dom";

// importing images here
import { assetImages } from "../../constants";

const HomeTalent = () => {
  return (
    <section className="find_talent_area pd_80">
      <div className="container">
        <div className="talent_find_outer">
          <div className="talent_find_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="talent_find_pic">
                  <img src={assetImages.supportPic} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="talent_textpnl">
                  <div className="page_heading">
                    <h5>Encuentra apoyos</h5>
                    <h2>¿Eres emprendedor y/o tienes una startup?</h2>
                  </div>
                  <div className="icontext_outer">
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.findIcon1} alt="" />
                      </span>
                      <p>
                        Contacta con emprendedores, startups, Inversores,
                        mentores y advisors desde un solo lugar
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.findIcon2} alt="" />
                      </span>
                      <p>
                        Particpa en challenges, ofertas y proyectos
                        promocionados por startups, pymes y grandes
                        corporaciones
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.findIcon3} alt="" />
                      </span>
                      <p>
                        Lanza campañas de crowdfunding para testar tu modelo de
                        negocio y obtener financiación
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.findIcon4} alt="" />
                      </span>
                      <p>
                        Connect directly with founders at top startups - no
                        third party recruiters allowed
                      </p>
                    </div>
                  </div>
                  <div className="find_btns">
                    <Link to="#" className="btn outline">
                      Learn More
                    </Link>
                    <Link to="#" className="btn">
                      Sign Up Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="talent_find_row">
            <div className="row">
              <div className="col-lg-6">
                <div className="talent_find_pic">
                  <img src={assetImages.recruitPic} alt="" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="talent_textpnl">
                  <div className="page_heading">
                    <h5>CONTRATA EL MEJOR TALENTO</h5>
                    <h2>Te ayudamos a encontrar el mejor talento</h2>
                  </div>
                  <div className="icontext_outer">
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.recruitIcon1} alt="" />
                      </span>
                      <p>
                        Todos los profesionales de la plataforma ahn sido
                        referenciados y entrevistados por nuestro equipo
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.recruitIcon2} alt="" />
                      </span>
                      <p>
                        Hay más de 1.000 expertos en Cloud, Big Data, IA, QA,
                        ERPs, front end, backend, UX-UI
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.recruitIcon3} alt="" />
                      </span>
                      <p>
                        Gestiona tus procesos con un ATS (aplication tracking
                        system) para hacer un seguimiento en tiempo real y poder
                        chatear con los candidatos y los head hunters
                      </p>
                    </div>
                    <div className="icon_text_row">
                      <span>
                        <img src={assetImages.recruitIcon4} alt="" />
                      </span>
                      <p>
                        100% flexibles. Contratas de forma directa o contratas
                        nuestros servicios por proyecto o bolsas de horas
                      </p>
                    </div>
                  </div>
                  <div className="find_btns">
                    <Link to="#" className="btn outline">
                      Learn More
                    </Link>
                    <Link to="#" className="btn">
                      Sign Up Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTalent;
