/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/* -------------------------- required imports for API Call -------------------------- */
// import Gateway for API fetch call
import { getData, postData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";
/* ----------------------------------------------------------------------------------- */

const ForwardEmailModal = (props) => {
  // getting the forwarded message via props here
  let forwardedMailId = props.mailId;

  // accessing the user's token here
  const token = localStorage.getItem("token");

  // initial useState to hold all the selected users
  const [selectedPersons, setSelectedPersons] = useState([]);

  // initial state for all contact list
  const [allcontacts, setAllcontacts] = useState([]);

  // useState to hold value from search field
  const [searchName, setSearchName] = useState("");

  // any error message
  const [forwardNotification, setForwardNotification] = useState("");

  // function to search value from JSON
  function filterByName(name) {
    setSearchName(name);
  }

  // API call to fetch conversations list
  const getAllContacts = async () => {
    let requestURL =
      url.API_BaseUrl + url.API_ALL_CONTACTS + `?token=${token}&status=11`;

    try {
      const response = await getData(requestURL);

      if (response.status) {
        setAllcontacts(response.data);
      }
    } catch (error) {}
  };

  const selectedForwardingGroup = (memberid) => {
    let prevPersonIds = selectedPersons;

    if (prevPersonIds.includes(memberid)) {
      // exist in array, thus remove
      let key = prevPersonIds.indexOf(memberid);
      prevPersonIds.splice(key, 1);
    } else {
      // not exist in array, thus add
      prevPersonIds.push(memberid);
    }

    setSelectedPersons(prevPersonIds);

    // toggle class based on id
    var membersBlock = document.getElementById(
      "checkboxforwardmailmodal-" + memberid
    );

    membersBlock.classList.toggle("blue_bullet");

    // unset if any error previous
    setForwardNotification("");
  };

  // action to forward message -----------------------------------------------
  const forwardMailHandler = async () => {
    if (forwardedMailId != null || forwardedMailId != undefined) {
      let uid = "";

      selectedPersons.map((data, index) => {
        uid += `&uids[]=${data}`;
      });

      // check condition whether selected person more than two or not
      if (selectedPersons.length >= 1) {
        let requestURL =
          url.API_BaseUrl +
          url.API_FORWARD_MAIL +
          `?token=${token}` +
          `&mailid=${forwardedMailId}` +
          `${uid}`;

        try {
          setForwardNotification("Forwarding message to selected contact");

          const response = await getData(requestURL);

          if (response.status) {
          } else {
            setForwardNotification("sorry, the request could not be completed");
          }
        } catch (error) {}
        // reset all after the operation
        resetForward();
      } else {
        setForwardNotification("You forgot to select contact?");
      }
    } else {
      setForwardNotification("No Email selected for forwarding!");
      return;
    }
  };

  // Reset the forward activity ---------------------------------------------------
  const resetForward = () => {
    // select the modal to hide
    var forwardModal = document.querySelector("#forward_mdl");
    var modal = bootstrap.Modal.getInstance(forwardModal);

    // remove selected classes
    var resetBlock = document.getElementsByClassName("forwardToContact");
    for (var i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("blue_bullet");
    }
    // reset selected person useState
    setSelectedPersons([]);
    // reset notification if any
    setForwardNotification("");
    // hide modal
    modal.hide();
  };

  // --------- useEffect -----------
  useEffect(() => {
    getAllContacts();
  }, [props.mailId]);

  return (
    <>
      <div className="chat_modalbx">
        <div
          className="modal fade"
          id="forward_mdl"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              {/* ---------- Modal Header starts here ---------- */}
              <div className="modal-header mdl_hdng">
                <h5 className="modal-title">Forward Mail to</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="material-icons-round">close </span>
                </button>
              </div>
              {/* ---------- Modal Header ends here ---------- */}

              {/* ---------- Modal Body starts here ---------- */}
              <div className="modal-body">
                {/* ---------- Modal search bar ---------- */}
                <div className="group_srchfill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for people"
                    value={searchName}
                    onChange={(e) => filterByName(e.target.value)}
                  />
                </div>
                {/* ---------- Modal search bar ends here ---------- */}

                {/* ---------- Modal list section ---------- */}
                <div className="group_innrScroll">
                  <div className="text-center text-primary text-weight-bold">
                    {forwardNotification}
                  </div>
                  {allcontacts.map((data) => (
                    <div
                      className={`chat_usrRow ${
                        data.label
                          .toLowerCase()
                          .includes(searchName.toLowerCase())
                          ? ""
                          : "hide"
                      }`}
                      key={data.id}
                    >
                      <Link
                        to="#"
                        key={data.id}
                        onClick={() => {
                          selectedForwardingGroup(data.uid);
                        }}
                      >
                        <div className="usr_lftPnl">
                          {data.image != null ? (
                            <div className="chatting_user">
                              <img src={data.image} alt="UserProfile" />
                            </div>
                          ) : (
                            <div className="chatting_user">
                              <span className="material-icons-round">
                                person
                              </span>
                            </div>
                          )}

                          <div className="user_name">
                            <h3>
                              {data.name} {data.surname}
                            </h3>
                          </div>
                        </div>
                        <div
                          className="members_check forwardToContact"
                          id={"checkboxforwardmailmodal-" + data.uid}
                        >
                          <span></span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
                {/* ---------- Modal list section ends ---------- */}

                {/* ---------------- Modal button section from here ---------------- */}
                <div className="group_btnOuter">
                  <ul>
                    <li className="w_100">
                      <Link
                        to="#"
                        className="ornage_btn"
                        onClick={forwardMailHandler}
                      >
                        <i className="material-icons-round"> check_circle </i>
                        <span>Done</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* ---------- Modal Body ends here ---------- */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForwardEmailModal;
