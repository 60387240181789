/* eslint-disable */
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

// import Gateway for API fetch call
import { uploadFile, getData, postData } from "../../utils/Gateway.js";

// import all URLs from url_helper
import * as url from "../../urlhelpers/UrlHelper";

// importing images here
import { assetImages } from "../../constants";

const DocumentsModal = () => {
  const { userRegisterData, setUploadedImgUrl } = useContext(
    GlobalFindelOiProvider
  );

  const [uploadedImageId, setuploadedImageId] = useState("");
  const [uploadImageWarning, setUploadImageWarning] = useState(false);
  const [emptyImageWarning, setEmptyImageWarning] = useState(false);
  const [uploadedCVId, setUploadedCVId] = useState("");
  const [uploadedCVWarning, setUploadedCVWarning] = useState(false);
  const [emptyCVWarning, setEmptyCVWarning] = useState(false);

  //function for image upload
  const uploadImageHandler = async (e) => {
    let imgFile = e.target.files[0];

    if (imgFile.type == "image/jpeg" || imgFile.type == "image/png") {
      setUploadImageWarning(false);

      try {
        let requestURL =
          url.API_BaseUrl +
          url.API_FILE_UPLOAD +
          `?token=${userRegisterData.token}`;

        console.log(requestURL);

        const response = await uploadFile(requestURL, imgFile);

        console.log(response);

        if (response.status) {
          if (response.data && response.data.id) {
            setuploadedImageId(response.data.id);
            let previewImage = URL.createObjectURL(imgFile);
            setUploadedImgUrl(previewImage);
          }
        }
      } catch (error) {
        console.log("Profile image upload error ---->", error);
      }
    } else {
      setUploadImageWarning(true);
    }
  };

  //function for cv upload
  const uploadCVHandler = async (e) => {
    let docFile = e.target.files[0];

    if (
      docFile.type == "application/msword" ||
      docFile.type == "application/pdf"
    ) {
      setUploadedCVWarning(false);

      try {
        let requestURL =
          url.API_BaseUrl +
          url.API_FILE_UPLOAD +
          `?token=${userRegisterData.token}`;

        const response = await uploadFile(requestURL, docFile);

        console.log(response);

        if (response.status) {
          if (response.data && response.data.id) {
            setUploadedCVId(response.data.id);
          }
        }
      } catch (error) {
        console.log("Profile image upload error ---->", error);
      }
    } else {
      setUploadedCVWarning(true);
    }
  };

  //function for register handler
  const sendRegisterDataHandler = async () => {
    if (uploadedImageId == "" || uploadedCVId == "") {
      if (uploadedCVId == "") {
        setEmptyCVWarning(true);
      } else {
        setEmptyCVWarning(false);
      }
      if (uploadedImageId == "") {
        setEmptyImageWarning(true);
      } else {
        setEmptyImageWarning(false);
      }
    } else {
      setEmptyCVWarning(false);
      setEmptyImageWarning(false);

      let fileData = {
        image: uploadedImageId,
        cv: uploadedCVId,
      };

      let requestURL =
        url.API_BaseUrl + url.DEMO_POST + `?token=${userRegisterData.token}`;

      try {
        const response = await postData(requestURL, fileData);
        if (response.status) {
          console.log("File submit response ----->", response);

          let fileInfoModal = document.querySelector("#documets_mdl");
          let modal = bootstrap.Modal.getInstance(fileInfoModal);
          modal.hide();
          resetCV();
          resetImage();
          let myModal = new bootstrap.Modal(
            document.getElementById("profile_modal")
          );
          myModal.show();
        }
      } catch (error) {
        console.log("Profile form error ----->", error);
      }
    }
  };

  // function for clear file value
  const resetImage = () => {
    const file = document.getElementById("inputImage");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  // function for clear file value
  const resetCV = () => {
    const file = document.getElementById("inputCv");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  return (
    <div className="registration_modal">
      <div
        className="modal fade show"
        id="documets_mdl"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog medium_modal">
          <div className="modal-content">
            {/* ----------- Modal header starts here ----------- */}
            <div className="modal-header">
              {/* ---------- Modal heading starts here ---------- */}
              <div className="signin_hdng text-center">
                <span className="emoji">
                  <img src={assetImages.loginEmoji2} alt="" />
                </span>
                <h3>Okay, just two steps to go</h3>
                <h5>Surely you want to know with whom you meet and for what</h5>
              </div>

              {/* ---------- Modal Close button starts here ---------- */}
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined"> close </i>
              </button>
            </div>
            {/* ----------- Modal header ends here ----------- */}

            {/* ----------- Modal Body starts here ----------- */}
            <div className="modal-body">
              <div className="login_body">
                <div className="form_body">
                  <div className="documents_uploadOuter">
                    <div className="documents_uploaditem">
                      <h4>Your Profile</h4>
                      <div className="upload_bx">
                        {/* ---- image upload ---- */}
                        <input
                          type="file"
                          className="file_btn"
                          onChange={uploadImageHandler}
                        />
                        <div className="upload_innr">
                          <i className="material-icons-outlined">
                            cloud_upload
                          </i>
                          <span>Upload your profile picture</span>
                        </div>
                      </div>
                      {emptyImageWarning && (
                        <p className="error mt-2">* Upload image please</p>
                      )}

                      {uploadImageWarning && (
                        <p className="error mt-2">* Upload image only</p>
                      )}
                    </div>
                    <div className="documents_uploaditem">
                      <h4>Upload Documents</h4>
                      <div className="upload_bx">
                        {/* ---- cv upload ---- */}
                        <input
                          type="file"
                          className="file_btn"
                          onChange={uploadCVHandler}
                        />
                        <div className="upload_innr">
                          <i className="material-icons-outlined">
                            cloud_upload
                          </i>
                          <span>Upload your CV</span>
                        </div>
                      </div>
                    </div>
                    {uploadedCVWarning && (
                      <p className="error mt-2">* Upload Doc or pdf only</p>
                    )}
                    {emptyCVWarning && (
                      <p className="error mt-2">* Upload cv please</p>
                    )}
                  </div>
                  <div className="loginstep_btnsouter">
                    <div className="left_btn">
                      <Link to="#" className="btn gray_btn">
                        Skip
                      </Link>
                    </div>
                    <div className="step_bullet">
                      <ul className="list_stye_none">
                        <li className=""></li>
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    {/* ----- send button ---- */}
                    <div className="right_btn">
                      <Link
                        to="#"
                        className="btn"
                        onClick={sendRegisterDataHandler}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ----------- Modal Body ends here ----------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsModal;
