/* eslint-disable */
// importing common components here
import HomeHeader from "../../components/homecomponents/HomeHeader";
import Footer from "../../components/common/Footer";
import HomeBanner from "../../components/homecomponents/HomeBanner";
import HomeEcosystem from "../../components/homecomponents/HomeEcosystem";
import HomeEvent from "../../components/homecomponents/HomeEvent";
import HomeTalent from "../../components/homecomponents/HomeTalent";
import HomeOption from "../../components/homecomponents/HomeOption";
import HomeReview from "../../components/homecomponents/HomeReview";
import HomeBlog from "../../components/homecomponents/HomeBlog";
import LoginModal from "../../components/logincomponents/LoginModal";
import SignupModal from "../../components/RegisterComponents/SignupModal";
import OtpModal from "../../components/RegisterComponents/OtpModal";
import { useEffect } from "react";
import ValidateModal from "../../components/RegisterComponents/ValidateModal";
import InfoModal from "../../components/RegisterComponents/InfoModal";
import DocumentsModal from "../../components/RegisterComponents/DocumentsModal";
import ProfilePicModal from "../../components/RegisterComponents/ProfilePicModal";
import InterestsModal from "../../components/RegisterComponents/InterestsModal";

const Home = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.title = "Findeloi";
    if (!token) {
      let myModal = new bootstrap.Modal(document.getElementById("login_modal"));
      myModal.show();
    }
  }, []);

  return (
    <>
      <HomeHeader />
      <HomeBanner />
      <HomeEcosystem />
      <HomeEvent />
      <HomeTalent />
      <HomeOption />
      <HomeReview />
      <HomeBlog />
      <Footer />
      {/* ----- modals ---- */}
      <LoginModal />
      <SignupModal />
      <OtpModal />
      <ValidateModal />
      <InfoModal />
      <DocumentsModal />
      <ProfilePicModal />
      <InterestsModal />
    </>
  );
};

export default Home;
