/* eslint-disable */
import React, { useState, useContext } from "react";
import Select from "react-select";

// import Gateway for API fetch call
import { postData } from "../../../utils/Gateway";

// import all URLs from url_helper
import * as url from "../../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../../context/FindeloiContext";

const ContactModal = ({
  expertName,
  afterModalClose,
  token,
  selectedExpertId,
}) => {
  /* ------------- Single Select requirements starts here ------------- */

  const [message, setMessage] = useState("");
  const [selectedReason, setSelectedReason] = useState();
  const [reason, setReason] = useState("");
  const [selectedHistory, setSelectedHistory] = useState();
  const [champHistory, setChampHistory] = useState("");
  const [messageWarning, setMessageWarning] = useState(false);
  const [reasonWarning, setReasonWarning] = useState(false);
  const [realtionWarning, setRealtionWarning] = useState(false);
  const [isSending, setIsSending] = useState(false);

  // custom style for select
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #D7D7D7",
      color: "#495057",
      fontSize: "16px",
      fontFamily: "sans-serif",
    }),

    // Value style
    control: (styles) => ({
      ...styles,
      fontSize: "18px",
      padding: "0px",
      margin: "2px",
      fontFamily: "sans-serif",
      border: "none",
      backgroundColor: "#fff",
      height: 50,
    }),

    // placeholder style
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#495057",
        padding: "0px",
        fontSize: "16px",
        marginVertical: "4px",
        fontFamily: "sans-serif",
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: "#495057" };
      return Object.assign(base, changes);
    },
  };

  // contact options
  const contactOptions = [
    { label: "Business Networking", value: "Business Networking" },
    { label: "Relational Connect", value: "Relational Connect" },
    { label: "Promotional Connect", value: "Promotional" },
    { label: "None of the above", value: "None of the above" },
  ];

  // relation option
  const knowOptions = [
    { label: "Friends", value: "Friends" },
    { label: "Family", value: "Family" },
    { label: "Facebook", value: "Facebook" },
    { label: "Linkedin", value: "Linkedin" },
    { label: "Other Source", value: "Other Source" },
  ];

  //function for select contact
  const selectContactHandler = (val) => {
    setSelectedReason();
    setReason(val.value);
  };

  //function for select reason
  const selectHistoryHandler = (val) => {
    setSelectedHistory();
    setChampHistory(val.value);
  };

  //function for send champ connect modal
  const connectChampionHandler = async () => {
    if (reason == "" || champHistory == "") {
      if (reason == "") {
        setReasonWarning(true);
      } else {
        setReasonWarning(false);
      }
      if (champHistory == "") {
        setRealtionWarning(true);
      } else {
        setRealtionWarning(false);
      }
    } else {
      setReasonWarning(false);
      setRealtionWarning(false);
      setIsSending(true);
      let requestURL =
        url.API_BaseUrl +
        url.API_EXPERT_CONTACT +
        `?token=${token}` +
        `&uid=${selectedExpertId}`;

      let connectData = {
        reason: reason,
        relation: champHistory,
        comment: message,
      };

      try {
        const response = await postData(requestURL, connectData);

        if (response.status) {
          setIsSending(false);
          let contactModal = document.querySelector("#contact_owner");
          let modal = bootstrap.Modal.getInstance(contactModal);
          modal.hide();
          resetHandler();
          // let myModal = new bootstrap.Modal(
          //   document.getElementById("champion_connect_thanks_mdl")
          // );
          // myModal.show();
          afterModalClose();
        }
      } catch (error) {}
    }
  };

  const resetHandler = () => {
    setMessage("");
    setChampHistory("");
    setReason("");
    setSelectedReason(null);
    setSelectedHistory(null);
    setMessageWarning(false);
    setRealtionWarning(false);
    setReasonWarning(false);
  };

  /* ------------- Single Select requirements ends here ------------- */

  return (
    <div className="conatct_modal">
      <div
        className="modal fade"
        id="contact_owner"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-target="#staticBackdrop"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* ---------------- Modal Header starts here ---------------- */}
            <div className="modal-header">
              <div className="owner_hdngOuter">
                <h5>
                  Contact <b>{expertName}</b> <span className="online"></span>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={resetHandler}
                >
                  <i className="material-icons-outlined"> close </i>
                </button>
              </div>
            </div>

            {/* ---------------- Modal body starts here ---------------- */}
            <div className="modal-body">
              <div className="owner_detailsOuter">
                {/* ---------------- React Select 1 starts here ---------------- */}
                <div className="input_fill jobinput_fill">
                  <div className="model_selectBx">
                    <Select
                      placeholder="Reason of contact"
                      options={contactOptions}
                      classNamePrefix="select2-selection nosearch_select"
                      value={selectedReason}
                      onChange={(val) => selectContactHandler(val)}
                    />
                  </div>
                  {reasonWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * Please select reason
                    </p>
                  )}
                </div>

                {/* ---------------- React Select 2 starts here ---------------- */}
                <div className="input_fill jobinput_fill">
                  <div className="model_selectBx">
                    <Select
                      placeholder="How do you know me?"
                      options={knowOptions}
                      classNamePrefix="select2-selection nosearch_select"
                      value={selectedHistory}
                      onChange={(val) => selectHistoryHandler(val)}
                    />
                  </div>
                  {realtionWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * Please select relation
                    </p>
                  )}
                </div>

                {/* ---------------- 'TextArea' section starts here ---------------- */}
                <div className="input_fill jobinput_fill description_fill">
                  <textarea
                    className="form-control"
                    placeholder="Message of contact"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  {messageWarning && (
                    <p className="text-danger mt-2 mb-0">
                      * Please give message
                    </p>
                  )}
                </div>

                {/* ---------------- Button section starts here ---------------- */}
                <div className="input_fill jobinput_fill">
                  <button
                    className="basic_btn contact_btn"
                    onClick={connectChampionHandler}
                    disabled={isSending ? true : false}
                    style={{
                      cursor: isSending ? "not-allowed" : "pointer",
                    }}
                  >
                    Sent
                    {isSending && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
