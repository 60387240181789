/* eslint-disable */
import { useEffect } from "react";

// importing custom components here
import Header from "../../components/common/Header";
import Banner from "../../components/common/Banner";
import Footer from "../../components/common/Footer";
import BlogListArea from "../../components/bloglistcomponents/BlogListArea";
import BlogSearchTab from "../../components/bloglistcomponents/BlogSearchTab";

const BlogList = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header activePage={"blogs"} />
      <section className="checkout_area job_serchSec">
        <div className="container">
          <Banner
            heading1={"Latest Blogs"}
            heading2={"Over 5 million blogs listed"}
          />
          <BlogSearchTab />
          <BlogListArea />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogList;
