/* eslint-disable */
import { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { postData } from "../../utils/Gateway";
import * as url from "../../urlhelpers/UrlHelper";

//import usecontext file
import { GlobalFindelOiProvider } from "../../context/FindeloiContext";

const OtpModal = () => {
  const { userSignUpEmail, setUserRegisterData, confirmOtpCode } = useContext(
    GlobalFindelOiProvider
  );

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);

  // initial data for validate
  const [email, setEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [codeWarning, setCodeWarning] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [firstCode, setFirstCode] = useState("");
  const [secondCode, setSecondCode] = useState("");
  const [thirdCode, setThirdCode] = useState("");
  const [fourthCode, setFourthCode] = useState("");
  const [code, setCode] = useState("");

  //function for validate user
  const validateCodeHandler = async () => {
    if (
      firstCode == "" ||
      secondCode == "" ||
      thirdCode == "" ||
      fourthCode == ""
    ) {
      setCodeWarning(true);
    } else {
      setCodeWarning(false);
      let finalCode = firstCode + secondCode + thirdCode + fourthCode;

      try {
        setIsValidating(true);
        let userData = {
          email: userSignUpEmail,
          code: finalCode,
        };

        let requestUrl = url.API_BaseUrl + url.API_VALIDATE_USER;

        console.log("url for otp url---->", requestUrl);

        let result = await postData(requestUrl, userData);
        console.log("otp result---->", result);
        if (result.status) {
          setIsValidating(false);
          let userSignUpData = {
            id: result.authdata.id,
            email: result.authdata.email,
            token: result.authdata.token,
          };
          setUserRegisterData(userSignUpData);
          let validateModal = document.querySelector("#otp_mdl");
          let modal = bootstrap.Modal.getInstance(validateModal);
          modal.hide();
          resetAll();
          let myModal = new bootstrap.Modal(
            document.getElementById("validate_mdl")
          );
          myModal.show();
          if (result.authdata) {
            // assigning the received values to the browser's local storage.
            localStorage.setItem("token", result.authdata.token);

            localStorage.setItem("userInfo", JSON.stringify(result.authdata));

            // assigning the loggedin user's id to the browser's local storage.
            localStorage.setItem("userID", JSON.stringify(result.authdata.id));
          } else {
            // let myModal = new bootstrap.Modal(
            //   document.getElementById("contact_admin_modal")
            // );
            // myModal.show();
          }
        }
      } catch (error) {
        console.log("error in otp----->", error);
        // throw error;
      }
    }
  };

  //function for reset all date
  const resetAll = () => {
    // setEmail("user@indra.com");
    setCode(0);
    setFirstCode("");
    setSecondCode("");
    setThirdCode("");
    setFourthCode("");
  };

  // function for backspace reference fourth digit
  const fourthCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (fourthCode == "" && fourthCode.length == 0) {
        code3Ref.current?.focus();
      }
    }
  };

  // function for backspace reference third digit
  const thirdCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (thirdCode == "" && thirdCode.length == 0) {
        code2Ref.current?.focus();
      }
    }
  };

  // function for backspace reference second digit
  const secondCodeKey = (e) => {
    if (e.key == "Backspace") {
      if (secondCode == "" && secondCode.length == 0) {
        code1Ref.current?.focus();
      }
    }
  };

  // useEffect for cheking the login token available or not with refferel url
  // useEffect(() => {
  //   if (token) {
  //     if (document.referrer == "" || document.referrer == null) {
  //       history.push("/");
  //     } else {
  //       window.location.href = document.referrer;
  //     }
  //   }
  // }, [token]);

  // use effect for shift second input
  useEffect(() => {
    if (firstCode !== "" && firstCode.length == 1) {
      code2Ref.current?.focus();
    }
  }, [firstCode]);

  // use effect for shift third input
  useEffect(() => {
    if (secondCode !== "" && secondCode.length == 1) {
      code3Ref.current?.focus();
    }
  }, [secondCode]);

  // use effect for shift fourth input
  useEffect(() => {
    if (thirdCode !== "" && thirdCode.length == 1) {
      code4Ref.current?.focus();
    }
  }, [thirdCode]);

  return (
    <div className="registration_modal">
      <div className="modal fade show" id="otp_mdl" data-bs-backdrop="static">
        <div className="modal-dialog small_modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="signin_hdng text-center">
                <h3>Enter OTP</h3>
                <h5>A four digit code send to your email</h5>
                <span className="email_info">{userSignUpEmail}</span>
              </div>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="material-icons-outlined">close</i>
              </button>
            </div>
            <div className="modal-body">
              <div className="login_body">
                <div className="validate_fill">
                  <input
                    type="number"
                    ref={code1Ref}
                    value={firstCode}
                    onChange={(e) => {
                      setFirstCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    className="form-control"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code2Ref}
                    value={secondCode}
                    onChange={(e) => {
                      setSecondCode(
                        e.target.value.slice(0, e.target.maxLength)
                      );
                    }}
                    onKeyDown={secondCodeKey}
                    className="form-control"
                    placeholder="-"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code3Ref}
                    value={thirdCode}
                    onChange={(e) => {
                      setThirdCode(e.target.value.slice(0, e.target.maxLength));
                    }}
                    onKeyDown={thirdCodeKey}
                    className="form-control"
                    placeholder="--"
                    maxLength="1"
                  />
                  <input
                    type="number"
                    ref={code4Ref}
                    value={fourthCode}
                    onChange={(e) =>
                      setFourthCode(e.target.value.slice(0, e.target.maxLength))
                    }
                    onKeyDown={fourthCodeKey}
                    className="form-control"
                    placeholder="--"
                    maxLength="1"
                  />
                </div>
                {codeWarning && (
                  <div className="validate_error">
                    <p className="error">
                      Oops! the OTP verification failed. Please try again.
                    </p>
                  </div>
                )}
                <div className="resend_btn">
                  <Link to="#">
                    <i className="material-icons-outlined">cached</i>
                    <span>{confirmOtpCode}</span>
                  </Link>
                </div>
                <div className="loginstep_btnsouter">
                  <div className="left_btn">
                    <Link
                      to="#"
                      className="btn gray_btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Back
                    </Link>
                  </div>

                  {/* ----- Validate button ---- */}
                  <div className="right_btn">
                    <button
                      // data-bs-toggle="modal"
                      // data-bs-target="#validate_mdl"
                      // data-bs-dismiss="modal"
                      onClick={validateCodeHandler}
                      disabled={isValidating ? true : false}
                      style={{
                        cursor: isValidating ? "not-allowed" : "pointer",
                      }}
                      className="btn"
                    >
                      {isValidating && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      Validate
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
